import React, { useEffect, useState, forwardRef} from "react";

function AudioPlayer(props, ref){
   
    const [source, setSource] = useState();

    useEffect(() => {
        async function importFile() {
          const file = await import(`../Audio/${props.source}.mp3`);
          setSource(file.default);
          props.updateAudio();
        }

        importFile();
    }, [props.source]);

    const audio = new Audio(source);

    function playAudio(event){
        event.preventDefault();
        audio.play()
    }

    return (

        <i ref={ref} onClick={playAudio} className={ props.place ? "bi bi-volume-up-fill icon-grey bi-card-item-pos" : "bi bi-volume-up-fill"}/>

    )
}

export default forwardRef(AudioPlayer);