import React, {useState, useEffect} from "react";
import {Container,Button, Row, Col, Form, Alert, Nav} from "react-bootstrap";
import axios from "axios";
import LoadingScreen from "./LoadingScreen";
import { motion } from "framer-motion"
import { useHistory } from 'react-router-dom';
import {LinkContainer} from "react-router-bootstrap";


function ResetPasswordForm(props){
    const token = props.match.params.token;
    let history = useHistory();
    const [isLoading, setIsLoading] =  useState(true);
    const [values, setValues] = useState({});
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    // useEffect(() => {
    //     axios.get("/api/auth/check")
    //   .then(async res => {if (res.data){
    //       history.push('/dashboard')
    //     } else{props.handleNavBar(true);
    //       setIsLoading(false);}})
    //   .catch((err) => {console.log(err);});
    // },[history, props])

    useEffect(() => {
      if(props.isLoggedIn){
        history.push('/dashboard')
      } else {
        props.handleNavBar(true);
        setIsLoading(false);
      }
    }, [props.isLoggedIn]);

    function handleChange (event) {
        const value = event.target.value;
        const name = event.target.name;
        setValues({
          ...values,
          [name]: value
        });
    }

   async function handleSubmit (event) {
        event.preventDefault();
        setResponse();
        setError();
        const {newPassword, repeatPassword} = values
        try {
            await axios ({
            method: "PUT",
            url: "/api/confirm/reset/" + token,
            data: {
               newPassword,
               repeatPassword
            },
            headers: { 'content-type': 'application/json' }
            }).then(response => {
                const data = response.data;
                setResponse(data);
                setTimeout(()=> history.push("/login"), 5000)
            })
            } 
        catch(err) {
            setError(err.response.data);
        }

    }

    function handleKeyDown (event) {
        const enter = 13;
        if(event.keyCode === enter) {
          handleSubmit(event);
        }
    }

    return (
        !isLoading ?
        <section id="reset">
        <Container  fluid className="py-lg-5 py-3 container-custom">
        <motion.div animate={{ x: [-100, 0  ] }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
         <Row className="d-flex justify-content-center"><span className="text-brand font-weight-bold py-3" onClick={()=> history.push("/")}>Hanter</span></Row>
          <Row className="justify-content-md-center">
            <Col className="bg-white rounded shadow-sm  m-md-5 p-md-5 m-2 p-3">
              <h2 className="fc-primary">Reset Password</h2>
              <br/>
              <Form>                
                <Form.Group >
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type="password" placeholder="Enter new password" name="newPassword" value={values.newPassword} onChange={handleChange} onKeyDown={handleKeyDown} />
                </Form.Group>
                <Form.Group >
                  <Form.Label>Repeat New Password</Form.Label>
                  <Form.Control type="password" placeholder="Repeat new password" name="repeatPassword" value={values.repeatPassword} onChange={handleChange} onKeyDown={handleKeyDown} />
                </Form.Group>
                <Button block type="submit" className="mb-3" variant="success" onClick={handleSubmit}>Reset Password</Button>

                {error && <Alert variant="danger">{error.msg}</Alert>}
                {response && <Alert variant="success">{response.msg}</Alert>}
            </Form>
            <div className="d-flex flex-row justify-content-center">  
                  <span className="mr-2">Return to</span>
              
                  <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer>
               
                </div>
            </Col>
            </Row>
            </motion.div>
            </Container>
        
        </section> : 

        <LoadingScreen/>
    )
}

export default ResetPasswordForm;