import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";
import {Nav, Navbar, Button, Container,Col,Row } from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import studentStudy from "../images/student-studying1.svg"


function LandingPage(props){

    let history = useHistory();
    const [isLoading, setIsLoading] =  useState(true);
    
    useEffect(() => {      
      if(props.isLoggedIn){
        history.push('/dashboard')
      } else {
        props.handleNavBar(true);
        setIsLoading(false);

      }
    },[props.isLoggedIn])

    function signUp(){
      history.push("/register");
    }

    return(
      isLoading? <LoadingScreen/> :
    
      <section id="landing-page">
      <Container fluid className="container-custom" >
        <Navbar className="py-4" collapseOnSelect expand="md" variant="light">        
        <Navbar.Brand href="/" className="font-weight-bold">Hanter</Navbar.Brand>
            <Navbar.Toggle aria-controls="land-page-navbar" />
            <Navbar.Collapse id="land-page-navbar" >
              <Nav className="ml-auto">
                <LinkContainer to="/login"><Nav.Link className="mr-5">Login</Nav.Link></LinkContainer>
                <LinkContainer to="/register"><Nav.Link>Register</Nav.Link></LinkContainer> 
              </Nav>
            </Navbar.Collapse>
        </Navbar>
      <Row className="py-5">
        <Col lg={6} id="landing-page-title" className="d-flex flex-column justify-content-center">
         <h1 className="font-weight-bolder">Learn mandarin the easy way</h1>
         <h3>Coming soon</h3>
         <div><Button onClick={signUp} size="lg" variant="outline-dark" className="mt-5">Sign up</Button></div>
        </Col>
        <Col lg={6}> <h1><img src={studentStudy} id="landing-page-img" alt="dashboard" className="img-fluid"/></h1></Col>
      </Row>      
      </Container>
      {/* <Container id="feature" fluid>
        <Row className="container-custom py-5"><Col><h3>Features</h3></Col></Row>
      </Container> */}
      </section>)
}

export default LandingPage;