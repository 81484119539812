import React from "react";
import BlockLoading from "./BlockLoading";
import { motion } from "framer-motion";
import {LinkContainer} from "react-router-bootstrap";
import {Nav} from "react-bootstrap"


function Block(props){
    const itemInfo = props.itemInfo;
    
    if (itemInfo.userInfo){
        var userInfo = itemInfo.userInfo[0];
    }

    return (
        props.itemInfo? 
        <LinkContainer to={`/item/${itemInfo._id}`}>
              <Nav.Link>   
        <motion.div whileHover={{ scale: 1.1 }}>
            
                    <div className={itemInfo.type === "Vocab" ? "block-vocab rounded-less" : itemInfo.type === "Hanzi" ? "block-hanzi rounded-less" : "block-radical rounded-less"}>
                    {userInfo?.memorized &&  <i className="bi bi-bookmark-check-fill block-mark-memorized"></i> }
                    {userInfo?.memorized === false && <i className="bi bi-bookmark-fill block-mark-learnt"></i>}
                    {props.itemInfo.answer === true && <i className="bi bi-caret-up-fill block-mark-correct"></i>}
                    {props.itemInfo.answer === false && <i className="bi bi-caret-down-fill block-mark-wrong"></i>}
                    <span className={itemInfo.type === "Vocab" ? "block-vocab-text" : itemInfo.type === "Hanzi" ? "block-hanzi-text" : "block-radical-text"}>{itemInfo.character} <span className="small block-pronun">{itemInfo.type !== "Radical" && `[${itemInfo.pronun[0]}]`  }</span></span> 
{                    itemInfo.type === "Vocab"  ? <br/> :<br className="d-none d-md-block"/> 
}                    <span className="h6">{itemInfo.name[0]}</span>
                    
                    </div>
                
        </motion.div>
        </Nav.Link> 
            </LinkContainer>
         : <BlockLoading/>

    )
}

export default Block;