import React, { useEffect, useState} from "react";
import instance from "../axiosConfig";
import { useHistory } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";
import {Container, Col, Row} from "react-bootstrap";
import ItemAccordion from "./ItemAccordion";
import { motion } from "framer-motion"

function Hanzi (props){
    const history = useHistory();
    const [isLoading, setIsLoading] =  useState(true);
    const [hanzi, setHanzi] = useState([]);
    

    useEffect(() => {
        if(props.isLoggedIn){
            instance.get("/api/items?type=Hanzi")
            .then(response => {
                const data = response.data;
                setHanzi(data);
                props.handleNavBar(false);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response.data === "Unauthorized"){
                    window.location.reload()
                } else {
                    console.log(err);
                };
            });
        } else if (props.isLoggedIn === false){
            history.push('/login');
        }
    }, [props.isLoggedIn])
    
    //Filter
    const batch1 = hanzi.filter(item => item.level === 1)
    const batch2 = hanzi.filter(item => item.level === 2)
    const batch3 = hanzi.filter(item => item.level === 3)

    return(
    
    !isLoading && props.userInfo ?
   
   <Container fluid className="py-lg-5 py-3 container-custom ">
   <motion.div animate={{ x: [-100, 0  ] }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
    <Row className="bg-white rounded shadow-sm p-md-5 pt-4 item-list">
        <Col>
        <h3 className="fc-blue pb-3">Hanzi</h3>
        <br/>

        <ItemAccordion level="1" userInfo={props.userInfo} items={batch1} type="Hanzi"/> 
        <ItemAccordion level="2" userInfo={props.userInfo} items={batch2} type="Hanzi"/>   
        <ItemAccordion level="3" userInfo={props.userInfo} items={batch3} type="Hanzi"/>  

        </Col>
    </Row>
    </motion.div>
</Container>
        : <LoadingScreen/>
    );
}

export default Hanzi;