import axios from 'axios'

const instance = axios.create();

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(
    function (config) {
        console.log("Request interceptor: Getting token")
        const getToken = localStorage.getItem("jwt");

        if (getToken) {
            const {token} = JSON.parse(getToken);
            config.headers.Authorization = getToken ? token : ""
        } 
		return config;
	},
	function (err) {
		console.log("Request interceptor error: "+ err);
		return err;
	}
);

instance.interceptors.response.use(response => {
    return response;
  }, async error => {
      if (error?.response?.data?.messages === "Unauthorized" || error?.response?.data === "Unauthorized") {
        console.log("Interceptor: Trying to generate refresh token and new access token");
        //Here we will try to call the refresh route
        try{
          const refreshToken = await axios.get("/api/auth/refresh");
          await localStorage.setItem("jwt",JSON.stringify(refreshToken.data));

          const config = error.config
          console.log("Interceptor: Trying to resend original request")
          return await axios({
            method: config.method,
            url: config.url,
            data: config.data,
            headers: {
                'Authorization': refreshToken.data.token,
                'content-type': 'application/json'
              }
          })
        } catch (e){
          console.log("Catching interceptor error: " + e.response.data);
          return Promise.reject(e);
        }    
      } else {
        return Promise.reject(error);
      }
    
  });
  


export default instance