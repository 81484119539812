import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import LoadingScreen from "./LoadingScreen";
import {Container, Row, Col, Button, Form, Alert, Nav} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap";
import { motion } from "framer-motion";
import instance from "../axiosConfig";

function Login(props){
    let history = useHistory();
    const [isLoading, setIsLoading] =  useState(true);
    // useEffect(() => {      
    //   axios.get("/api/auth/check")
    //   .then(async res => {if (res.data){

    //       // history.push('/dashboard')
    //       setIsLoading(false);
    //       console.log(res);

    //     } else{props.handleNavBar(true);
    //       setIsLoading(false);}})
    //   .catch((err) => {console.log(err);});

    // },[history, props])

    useEffect(() => {
      if(props.isLoggedIn){
        history.push('/dashboard')
      } else {
        setIsLoading(false); 
        props.handleNavBar(true);
      }       
    }, [props.isLoggedIn]);

    const [values, setValues] = useState({
        email: '',
        password: ''
     } || {});
    const [isChecked,setIsChecked] = useState(false);
    const [error, setError] = useState(null);

    function handleKeyDown (event) {
        const enter = 13;
        if(event.keyCode === enter) {
          handleSubmit(event);
        }
    }
    
    function handleSubmit(event){
        event.preventDefault();
        submitData({ values });
    }

    function handleChange(event){
        const value = event.target.value;
        const name = event.target.name;
        setValues({
          ...values,
          [name]: value
        });
     };

     function handleCheck(event){
      const value = event.target.checked;
      setIsChecked(value);
   };

     async function submitData (formValues) {
        const dataObject = formValues.values;
        const {email, password} = dataObject;
   try {
   await axios ({
   method: "POST",
   url: "/api/auth/login",
   data: {
      email: email,
      password: password,
      remember_me: isChecked
   },
   headers: { 'content-type': 'application/json' }
   }).then(res => {
     localStorage.setItem("jwt",JSON.stringify(res.data));
        props.handleLogin();
        history.push('/dashboard');
   })
   } catch(err) {
        setError(err.response.data);
      }
   };

    return(isLoading? <LoadingScreen/> :
       <section id="login">
         <Container  fluid className="py-lg-5 py-3 container-custom">
         <motion.div animate={{ x: [-100, 0  ] }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
         <Row className="d-flex justify-content-center"><span className="text-brand font-weight-bold py-3" onClick={()=> history.push("/")}>Hanter</span></Row>
          <Row className="justify-content-md-center">
            <Col className="bg-white rounded shadow-sm m-md-5 p-md-5 m-2 p-3">
              <h2 className="fc-primary">Welcome back</h2>
              <br/>
              <Form>
                <Form.Group >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" name="email" value={values.email} onChange={handleChange} onKeyDown={handleKeyDown}/>
                </Form.Group>
                <Form.Group >
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange} onKeyDown={handleKeyDown} />
                </Form.Group>
                <Form.Group >
                  <Form.Check type="checkbox" label="Remember me" name="remember_me" onChange={handleCheck} />
                </Form.Group>
                <Button block variant="success" type="submit" onClick={handleSubmit}>
                  Login
                </Button>
                <br/>
                             
                {error && <Alert variant="danger">{error.errors}</Alert>}
                <div className="d-flex flex-row justify-content-center">  
                  <span className="mr-2">Forgot your password?</span>
              
                  <LinkContainer to="/reset"><Nav.Link>Reset</Nav.Link></LinkContainer>
               
                </div>
                <div className="d-flex flex-row justify-content-center">  
                  <span className="mr-2">Not a member?</span>
              
                  <LinkContainer to="/register"><Nav.Link>Sign up</Nav.Link></LinkContainer>
               
                </div>

              </Form>      
            </Col>
          </Row>
          </motion.div>
        </Container>
      </section>
    )        
}

export default Login;