import React, {useState, useEffect} from "react";
import {Container, Navbar, Row, Col, Nav} from "react-bootstrap";

function Terms (props){
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
       props.handleNavBar(true);
       setIsLoading(false)
    },[]);

    return ( <section id="terms">

<Container fluid className="container-custom" >
            <Navbar className="py-4" collapseOnSelect expand="sm" variant="light">        
                <Navbar.Brand href="/" className="font-weight-bold"><i class="bi bi-arrow-left"></i> Hanter</Navbar.Brand>
            </Navbar>
            <Row className="py-5">
                <Col className="d-flex flex-column justify-content-center">
                    <h1>Terms of Use</h1>
                    <p>Coming soon</p>

            </Col>
        </Row>      
        </Container>
  
    </section>
    )
}

export default Terms;