import React, {useEffect, useState} from "react";
import instance from "../axiosConfig";
import { useHistory } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";
import { motion } from "framer-motion"
import {Container, Col, Row, Form} from "react-bootstrap";
import SearchAccordion from "./SearchAccordion";

function Search ({match,...props}){
    const query = match.params.query;
    const [result, setResult] = useState();
    const [isLoading, setIsLoading] =  useState(true);
    const history = useHistory();
    const [radical, setRadical] = useState();
    const [hanzi, setHanzi] = useState();
    const [vocab, setVocab] = useState();
    const [search,setSearch] = useState();
    const [delayQuery, setDelayQuery] = useState();
    

    useEffect(() => {     
        if(props.userInfo && props.isLoggedIn){
            instance.get("/api/items?search=" + query )
            .then(response => {
                const data = response.data;
                setResult(data); 
                    setRadical(response.data.filter(item => item.type === "Radical"));           
             
                    setHanzi(response.data.filter(item => item.type === "Hanzi"));            
               
                    setVocab(response.data.filter(item => item.type === "Vocab"));
                    setDelayQuery(query);        
            }).then(response => {            
                props.handleNavBar(false);
                setIsLoading(false);                 
            })
            .catch((err) => {
                
                if (err.response.data === "Unauthorized"){
                    window.location.reload()
                } else {
                    console.log(err);
                }

            });  
                        
        } else if (props.isLoggedIn === false){
            history.push('/login');
        }       

    }, [query, props.userInfo, props.isLoggedIn])

    function linkOut(event){
        event.preventDefault();
        setIsLoading(true);
        history.push('/search/'+ search);
    }

    function handleSearch(event){
        setSearch(event.target.value);
    }

    function handleKeyDown (event) {
        const enter = 13;
        if(event.keyCode === enter) {
          linkOut(event);
        }
    }

    return (
        isLoading ? <LoadingScreen/> :
        result.length < 1 ? 
        
        <Container fluid className="py-lg-5 py-3 container-custom search d-flex justify-content-center">
            <motion.div className="pt-5" animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}>            
                    <i className="bi bi-search mb-3"></i>
                    <p>No items found for "{delayQuery}"</p>
                    <Row className="justify-content-md-center align-items-center py-3">
                        <Col>
                            <Form>
                                <Form.Row className="align-items-center">
                                    <Col className="px-3" >
                                        <Form.Control
                                        className="mb-2"
                                        id="inlineFormInput"
                                        placeholder="Hit enter to search"
                                        onChange={handleSearch}
                                        onKeyDown={handleKeyDown}
                                        autoComplete="off"
                                    />
                                    </Col>
                                    
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
             
            </motion.div>
        </Container>

        
        
         :
       <>
        <Container fluid className="py-lg-5 py-3 container-custom ">
            <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}>
            
                <Row className="bg-white rounded shadow-sm p-md-5 pt-4  item-list">
              
                    <Col className="px-3">
                        <h4>Search results for " {isLoading === false && <span className="fc-primary pb-3">{delayQuery}</span>} "</h4><br/>
                        
                        {radical.length > 0 &&                        
                         
                            <SearchAccordion items={radical} type="Radical"/>  
                        }

                        {hanzi.length > 0 &&
                        
                            <SearchAccordion items={hanzi} type="Hanzi"/>  
                        }

                        {vocab.length > 0 &&
                        
                            <SearchAccordion items={vocab} type="Vocab"/>  
                        }

                    </Col>
                </Row>
            </motion.div>
        </Container>
       </>
    )
}

export default Search;