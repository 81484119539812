import React, {useState, useEffect} from "react";
import {Container,Button, Row, Col, Form, Alert, Nav} from "react-bootstrap";
import axios from "axios";
import LoadingScreen from "./LoadingScreen";
import { motion } from "framer-motion"
import { useHistory } from 'react-router-dom';
import {LinkContainer} from "react-router-bootstrap";



function ResetPasswordRequest(props) {
    let history = useHistory();
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] =  useState(true);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    // useEffect(() => {
    //     axios.get("/api/auth/check")
    //   .then(async res => {if (res.data){
    //       history.push('/dashboard')
    //     } else{props.handleNavBar(true);
    //       setIsLoading(false);}})
    //   .catch((err) => {console.log(err);});
    // },[history, props]);

    useEffect(() => {
      if(props.isLoggedIn){
        history.push('/dashboard')
      } else {
        props.handleNavBar(true);
        setIsLoading(false);
      }
    }, [props.isLoggedIn]);

    async function handleSubmit(event){
        event.preventDefault();
        setResponse();
        setError();
        try {
            await axios ({
            method: "PUT",
            url: "/api/confirm/reset",
            data: {
               email
            },
            headers: { 'content-type': 'application/json' }
            }).then(response => {
                const data = response.data;
                setResponse(data);
            })
            } 
        catch(err) {
            setError(err.response.data);
        }
    }

    function handleChange(event) {
        const value = event.target.value
        setEmail(value);
    }

    function handleKeyDown (event) {
        const enter = 13;
        if(event.keyCode === enter) {
          handleSubmit(event);
        }
    }

    return (
        !isLoading ?
        <section id="reset">
        <Container  fluid className="py-lg-5 py-3 container-custom">
        <motion.div animate={{ x: [-100, 0  ] }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
         <Row className="d-flex justify-content-center"><span className="text-brand font-weight-bold py-3" onClick={()=> history.push("/")}>Hanter</span></Row>
          <Row className="justify-content-md-center">
            <Col className="bg-white rounded shadow-sm  m-md-5 p-md-5 m-2 p-3">
              <h2 className="fc-primary">Reset Password</h2>
              <br/>
              <Form>                
                <Form.Group >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="text" placeholder="Enter email" name="email" value={email} onChange={handleChange} onKeyDown={handleKeyDown} />
                </Form.Group>
                <Button block type="submit" className="mb-3" variant="success" onClick={handleSubmit}>Submit</Button>

                {error && <Alert variant="danger">{error.msg}</Alert>}
                {response && <Alert variant="success">{response.msg}</Alert>}
            </Form>
            <div className="d-flex flex-row justify-content-center">  
                  <span className="mr-2">Not a member?</span>
              
                  <LinkContainer to="/register"><Nav.Link>Sign up</Nav.Link></LinkContainer>
               
                </div>
            </Col>
            </Row>
            </motion.div>
            </Container>
        
        </section> : 

        <LoadingScreen/>
    )
}

export default ResetPasswordRequest;