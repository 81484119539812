import React, { useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import {Nav} from "react-bootstrap";
import Navbar from "./components/Navbar";
import Hanzi from "./components/Hanzi";
import Radicals from "./components/Radicals";
import Vocab from "./components/Vocab";
import ItemPage from "./components/ItemPage";
import LearningQueue from "./components/learn";
import Review from "./components/Review";
import Register from "./components/Register";
import Login from "./components/Login"
import MyAccount from "./components/MyAccount";
import Dashboard from "./components/Dashboard";
import LandingPage from "./components/LandingPage";
import Search from "./components/Search";
import VerifyEmail from "./components/VerifyEmail";
import ResetPasswordRequest from "./components/ResetPasswordRequest";
import ResetPasswordForm from "./components/ResetPasswordForm";
import DeleteSuccess from "./components/DeleteSuccess";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import MyProgress from "./components/MyProgress";
import GettingStarted from "./components/GettingStarted";
import Faq from "./components/Faq";
import instance from "./axiosConfig";

import OverlayLoad from "./components/OverlayLoad";
import { ProSidebar, SidebarHeader, SidebarContent, SidebarFooter, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [userInfo, setUserInfo] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(0);
  const [hideNavBar, setHideNavBar] = useState(true);
  const [updating, setUpdating] = useState(false);
  // const [secondEffect, setSecondEffect] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const year = new Date().getFullYear();

  //Use effect to update the userInfo when there are changes

  useEffect(() => {
    instance.get("/api/auth/user")
    .then(res => {
      console.log("Call at App");
      setUserInfo(res);
      setIsLoggedIn(true);
    }).catch(err => {
      if (err?.response?.data === "Unauthorized"){
        setIsLoggedIn(false);
      } else {
        console.log("Something went wrong");
      }
    });     
  }, [hasUpdated])

  async function handleLogout (){
    try {

      await instance ({
        method: "GET",
        url: "/api/auth/logout"
        }).then(res => {
            localStorage.removeItem("jwt");
            window.location.reload();
        })

    } catch (err){console.log(err.response.data);}
  }

  function handleLogin(){
    instance.get("/api/auth/user").then(res =>{setUserInfo(res)}).catch((err) => {console.log(err);});
    setIsLoggedIn(true);
  }

  function updateUserInfo(){
    setHasUpdated(hasUpdated + 1);
  }

  function handleNavBar(response){
    setHideNavBar(response);
  }

  function overlay(value){
    setUpdating(value);
  }

  return (<>
    
    <OverlayLoad show={updating}/>
    <Router>
    <div id="app">

    {!hideNavBar && <ProSidebar breakPoint="lg" onToggle={handleToggleSidebar} toggled={toggled} collapsed={collapsed}>
    <SidebarHeader>
      
        <div className="d-flex justify-content-between">

          {!collapsed && <div className="pro-sidebar-brand">Hanter</div>}
          <div className="d-none d-lg-block">        
            {collapsed ? 
              <div className="pro-sidebar-circle ml-4">
                <i onClick={() => setCollapsed(false)} className="pro-sidebar-toggle-icon bi bi-chevron-bar-right"/> 
              </div>
            :
              <div className="pro-sidebar-circle">
                <i onClick={() => setCollapsed(true)} className="pro-sidebar-toggle-icon bi bi-chevron-bar-left"/>
              </div>}
        </div>
        </div>
    </SidebarHeader>

    <SidebarContent>
      <Menu iconShape="circle">
        <LinkContainer to="/dashboard">
          <MenuItem icon={<i className="bi bi-house-door"/>}>Dashboard</MenuItem>
        </LinkContainer>
        
        <LinkContainer to="/learn">
          <MenuItem icon={"学"}>Learn</MenuItem>
        </LinkContainer>
        
        <LinkContainer to="/review">
          <MenuItem icon={"习"}>Review</MenuItem>
        </LinkContainer>
        
        <SubMenu title="Items" icon={<i className="bi bi-columns-gap"/>}>
          <LinkContainer to="/radicals">
            <MenuItem >Radical</MenuItem>
          </LinkContainer>
          <LinkContainer to="/hanzi">
            <MenuItem >Hanzi</MenuItem>
          </LinkContainer>
          <LinkContainer to="/vocab">
            <MenuItem >Vocabulary</MenuItem>
          </LinkContainer>
        </SubMenu>
        <SubMenu title="Account" icon={<i className="bi bi-person"/>}>
          <LinkContainer to="/my-account">
            <MenuItem>My Account</MenuItem>
          </LinkContainer>
          <LinkContainer to="/my-progress">
            <MenuItem>My Progress</MenuItem>
          </LinkContainer>
        </SubMenu>
        <SubMenu title="Support" icon={<i className="bi bi-patch-question"/>}>
        <LinkContainer to="/getting-started">
        <MenuItem >Getting Started</MenuItem>
        </LinkContainer>
        <LinkContainer to="/faq">
        <MenuItem >FAQ</MenuItem>
        </LinkContainer>
        <MenuItem >Contact Us</MenuItem>
        </SubMenu>

  

      </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu iconShape="circle">
           <MenuItem onClick={handleLogout} icon={<i className="bi bi-box-arrow-in-right"></i>}>Logout</MenuItem>
        </Menu>
      </SidebarFooter>
{      !collapsed &&
      <SidebarFooter>
        <div className="d-flex flex-column"><small className="text-center">Hanter ⓒ {year} </small>
        <small className="text-center">
        <LinkContainer to="/privacy-policy"><Nav.Link className="d-inline">Privacy Policy</Nav.Link></LinkContainer> | <LinkContainer to="/terms-of-use"><Nav.Link className="d-inline">Terms of Use</Nav.Link></LinkContainer>
        </small></div>
      </SidebarFooter>}
    </ProSidebar>}

    <div id="main">
            {!hideNavBar && <Navbar toggled={toggled} handleToggleSidebar={handleToggleSidebar} handleCollapsedChange={handleCollapsedChange} collapsed={collapsed} overlay={overlay} isLoggedIn={isLoggedIn} handleLogout={handleLogout} updateUserInfo={updateUserInfo} userInfo={userInfo}/>}   
        <Switch>     
          <Route path="/radicals" render={()=> <Radicals isLoggedIn={isLoggedIn} userInfo={userInfo} handleNavBar={handleNavBar} />} />
          <Route path="/hanzi" render={()=> <Hanzi isLoggedIn={isLoggedIn} userInfo={userInfo} handleNavBar={handleNavBar}/>} />
          <Route path="/vocab" render={()=> <Vocab isLoggedIn={isLoggedIn} userInfo={userInfo} handleNavBar={handleNavBar}/>} />    
          <Route path="/learn" render={()=> <LearningQueue isLoggedIn={isLoggedIn}  userInfo={userInfo} updateUserInfo={updateUserInfo} handleNavBar={handleNavBar} hideNavBar={hideNavBar}/>}  />
          <Route path="/review" render={()=> <Review isLoggedIn={isLoggedIn}  userInfo={userInfo} updateUserInfo={updateUserInfo} handleNavBar={handleNavBar} hideNavBar={hideNavBar}/>} />
          <Route path="/dashboard" render={()=> <Dashboard isLoggedIn={isLoggedIn} userInfo={userInfo} handleNavBar={handleNavBar}/>} />
          <Route path="/my-account" render={()=> <MyAccount isLoggedIn={isLoggedIn} overlay={overlay} updateUserInfo={updateUserInfo} userInfo={userInfo} handleNavBar={handleNavBar}/>} />
          <Route path="/my-progress" render={()=> <MyProgress isLoggedIn={isLoggedIn} overlay={overlay} updateUserInfo={updateUserInfo} userInfo={userInfo} handleNavBar={handleNavBar}/>} />
          <Route path="/item/:indiv" render={(props) => <ItemPage {...props} overlay={overlay} isLoggedIn={isLoggedIn} userInfo={userInfo} updateUserInfo={updateUserInfo} handleNavBar={handleNavBar} />} />  
          <Route path="/confirm/:id" render={(props) => <VerifyEmail {...props} handleNavBar={handleNavBar} />} />  
          <Route path="/search/:query" render={(props) => <Search {...props} isLoggedIn={isLoggedIn} userInfo={userInfo} handleNavBar={handleNavBar} />} />  
          {/* <Route path="/quiz/:level" render={(props) => <Quiz {...props} key={Math.random()} handleNavBar={handleNavBar} />} />   */}
          <Route path="/register" render={()=> <Register isLoggedIn={isLoggedIn} handleLogin={handleLogin} handleNavBar={handleNavBar} />} />
          <Route path="/login" render={()=> <Login isLoggedIn={isLoggedIn} handleLogin={handleLogin} handleNavBar={handleNavBar} />} />
          <Route path="/account-deleted" render={()=> <DeleteSuccess isLoggedIn={isLoggedIn} handleNavBar={handleNavBar} />} />
          <Route path="/reset" render={()=> <ResetPasswordRequest isLoggedIn={isLoggedIn} handleNavBar={handleNavBar} />} />
          <Route path="/reset-form/:token" render={(props) => <ResetPasswordForm {...props} isLoggedIn={isLoggedIn} handleNavBar={handleNavBar} />} />  
          {/* <Route path="/admin" render={()=> <Admin isLoggedIn={isLoggedIn} handleNavBar={handleNavBar} />} />
          <Route path="/edit" render={()=> <EditItem isLoggedIn={isLoggedIn} handleNavBar={handleNavBar} />} /> */}
          <Route path="/privacy-policy" render={()=> <PrivacyPolicy handleNavBar={handleNavBar} />} />
          <Route path="/terms-of-use" render={()=> <Terms handleNavBar={handleNavBar} />} />
          <Route path="/getting-started" render={()=> <GettingStarted isLoggedIn={isLoggedIn} handleNavBar={handleNavBar} />} />
          <Route path="/faq" render={()=> <Faq isLoggedIn={isLoggedIn} handleNavBar={handleNavBar} />} />




          <Route path="/" render={()=> <LandingPage isLoggedIn={isLoggedIn} handleNavBar={handleNavBar}/>} />
          
        </Switch>
        </div>
      </div>       
    </Router>
   
    </>
  );
}

export default App;
