import React, { useEffect, useState} from "react";
import instance from "../axiosConfig";
import { useHistory } from 'react-router-dom';
import {Container, Col, Row, Badge, Breadcrumb, Table, ProgressBar, OverlayTrigger, Tooltip, Button} from "react-bootstrap";
import LoadingScreen from "./LoadingScreen";
import Block from "./Block";
import { motion, AnimatePresence } from "framer-motion"
import EditNotes from "./EditNotes";
import CreateNotes from "./CreateNotes";
import CustomMean from "./CustomMean";
import CustomMeanEdit from "./CustomMeanEdit";
import parse from 'html-react-parser';
import AudioPlayer from "./AudioPlayer";
import {LinkContainer} from "react-router-bootstrap";
const { DateTime } = require("luxon");


function ItemPage (props){
    const history = useHistory();
    const [isLoading, setIsLoading] =  useState(true);
    const [userInfo, setUserInfo] = useState();
    const [item, setItem] = useState(null);
    const [prereq, setPrereq] = useState(null);
    const [usedIn,setUsedIn] = useState(null);
    const indiv = props.match.params.indiv;
    const [showImg, setShowImg] = useState(false);
    
    const [editNote, setEditNote] = useState(false);
    const [update, setUpdate] = useState(false);
    const [noteLoad, setNoteLoad] = useState(false);

    const [editMean, setEditMean] = useState(false);
    const [meanLoad, setMeanLoad] = useState(false);
    
    const [memorizeResponse, setMemorizeResponse] = useState(null);

    useEffect(() => {

        if(props.userInfo && props.isLoggedIn){
            setUserInfo(props.userInfo);
            instance.get("/api/items?indiv=" + indiv)
            .then(response => {
            const data = response.data[0];
            setItem(data);

            setEditNote(false);
            setNoteLoad(false);
            setMeanLoad(false);
            setEditMean(false);

            setPrereq(data.prerequisite);
            setUsedIn(data.usedIn);
            })
            .then(response => {
            props.handleNavBar(false);
            setIsLoading(false);
            })
            .catch((err) => { 
                if (err.response.data === "Unauthorized"){
                window.location.reload()
                } else {
                console.log(err);
                };
            });

        } else if (props.isLoggedIn === false){
            history.push('/login');
        }  

    }, [indiv, update, props.userInfo, props.isLoggedIn])
   
   

    function linkOut(){
        var type = item.type
        switch (type) {
            case "Radical":
                history.push("/radicals")
                break;

            case "Hanzi":
                history.push("/hanzi")
                break;

            case "Vocab":
                history.push("/vocab")
                break;
            default: console.log("Error");  
        }
    }

    function updateAudio(){
        //nothing
    }

    function handleImg(){
        setShowImg(!showImg);
    }

    function updateState(){
        setUpdate(!update);
    }

    function handleNoteLoad(res){
        setNoteLoad(res)
    }

    function handleNote(res){
        setEditNote(res);
    }
    
    function handleMeanLoad(res){
        setMeanLoad(res);
        
    }

    function handleMean(res){
        setEditMean(res);
    }

    async function changeMemorized(event){
        event.preventDefault();

        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updateMemorize: "true",
               itemID: item._id
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
                setTimeout(() => {
                    props.updateUserInfo();
                    console.log("Success")
                  }, 1000);         
            })
            } catch(err) {
                if (err.response.data === "Unauthorized"){
                    window.location.reload()
                } else {
                    setMemorizeResponse(err.response.data)
                };                
            }
    }

    function updateLoading(){
        setIsLoading(true);
    }

    return(
    
    !isLoading ?
       
            <Container fluid className="py-lg-5 py-3 container-custom ">
                <motion.div animate={{ x: [-100, 0  ] }}
        transition={{ ease: "easeOut", duration: 0.3 }}>

               
                <Row className="bg-white rounded shadow-sm p-md-5 pt-4 py-1 text-center">
                    <Col>
                    <Breadcrumb>
                    <LinkContainer to="/dashboard">
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item></LinkContainer>
                    <Breadcrumb.Item onClick={linkOut}>
                        {item.type}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{item.character}</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col className="mb-5">
                    {item.img && <i onClick={handleImg} className="bi bi-card-image icon-grey bi-card-item-pos"/>}
             
                    
                    {item.img && 
                    
                    <AnimatePresence >
                    {showImg &&
                    <motion.div key={1} initial="collapsed" variants={{
                    open: { opacity: 1, height: 300 },
                    collapsed: { opacity: 0, height: 0 }}} 
                    animate="open"
                    exit="collapsed">
                    <img src={`/assets/images/radicals/${item.string_ID}.svg`} alt={item.character} className="radical-img"/> </motion.div>} 
                    </AnimatePresence>}                    
                
                    {!showImg && <div style={{fontSize: "80px"}}  className={item.type === "Radical" ? "fc-primary" : item.type === "Hanzi" ? "fc-blue" : "fc-pink"}>{item.character}</div>}
         
                    
                    
                           
                    
                    
                    {item.pronun && <h3>[{item.pronun[0]}]</h3>}
                    <h3>{item.name[0]}</h3>
                    <Badge pill variant="light">{item.type}</Badge>   <Badge pill variant="light">Lvl: {item.level}</Badge>   {item.userInfo[0] && <Badge pill variant="light">S{item.userInfo[0].review_score}</Badge>}
                    </Col>
                </Row>
                
                
                <Row>
                     <Col  className="text-left mb-4">
                        <h6 className="text-muted small">Meaning</h6>
                        <hr/>
                        <Table  borderless size="sm">
                            
                            <tbody className="text-center">                              
                                <tr>
                                <td className="quiz-table"><small>Meaning</small></td>
                                <td ><p>{item.name.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })}</p> </td>
                                </tr>

                                <tr>
                                <td className="quiz-table"><small>Custom meaning</small></td>
                                <td >
                                {item.notes.length > 0 ? 
                        
                                editMean ? 
                                <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                                <CustomMeanEdit handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={item.string_ID} notes={item.notes[0].notes}/></motion.div> : (item.notes[0].notes.custom_mean && item.notes[0].notes.custom_mean.trim()) ? <div className="text-center"><p>{parse(item.notes[0].notes.custom_mean)}</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                                
                                : 
                                
                                editMean ? 
                                <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                                <CustomMean handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={item.string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                                
                                }
                                </td>
                                </tr>                                       

                                <tr>
                                <td className="quiz-table"><small>Mnemonic</small></td>
                                <td >{parse(item.mnemonicName)}</td>
                                </tr>                                          

                            </tbody>
                        </Table>

                    </Col>
                    
                  {item.mnemonicPronun &&
                    <Col sm={12} lg={6} className="text-left mb-4">
                        <h6 className="text-muted small">Reading        {item.audio && <AudioPlayer updateAudio={updateAudio} place={true} source={item.string_ID}/>}</h6>
                        <hr/>
                        <Table  borderless size="sm">
                        
                        <tbody className="text-center">                              
                            <tr>
                            <td className="quiz-table"><small>Reading</small></td>
                            <td ><p>{item.pronun.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })}</p> </td>
                            </tr>                                       

                            <tr>
                            <td className="quiz-table"><small>Mnemonic</small></td>
                            <td >{parse(item.mnemonicPronun)}</td>
                            </tr>                                          

                        </tbody>
                    </Table>

                    </Col>}
                </Row>
                {item.exampleSentence &&
                <Row>
                    <Col className="text-left mb-4">
                        <h6 className="text-muted small">Example Sentences</h6>
                        <hr/>
                        {item.exampleSentence.map((example, index) => {
                            return (
                                <div key={index} className="text-center">{parse(example.CN)}<br/><small>{parse(example.PY)}</small><p>{parse(example.EN)}</p></div>
                            )})}
                    </Col>
                </Row>}

                <Row><Col className="text-left mb-4">
                    <h6 className="text-muted small">Notes</h6>
                    <hr/>
                    {item.notes.length > 0 ? 
                    
                    editNote ? 
                    <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                    <EditNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={item.string_ID} notes={item.notes[0].notes}/></motion.div> :  (item.notes[0].notes.content && item.notes[0].notes.content.trim() ) ? <div className="text-center"><p>{parse(item.notes[0].notes.content)}</p><i onClick={()=> {setEditNote(true); setEditMean(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                    
                    : 
                    
                    editNote ? 
                    <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                    <CreateNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={item.string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                    
                    }                                
                </Col></Row>
               
                {item.type === "Hanzi" ? 
               
                <Row>
                {item.prerequisite.length > 0 &&
                    <Col  sm={12} lg={6} className="text-left mb-4">
                        <h6 className="text-muted small">Prerequisite</h6>
                        <hr/>
                        <div className="progress-items-col">
                        {prereq.map((item, index) => (                
                            <div key={index} onClick={updateLoading}>
                        <Block itemInfo={item} />
                        </div>
                         ))} 
                        </div>

                    </Col>}
                    
                {item.usedIn.length > 0 &&   
                    <Col  sm={12} lg={6} className="text-left mb-4">
                        <h6 className="text-muted small">Used in</h6>
                        <hr/>
                        <div className="progress-items-col">
                        {usedIn.map((item, index) => (     
                        <div key={index} onClick={updateLoading}>           
                        <Block itemInfo={item} />
                         </div>
                         ))} 
                        </div>
                    </Col>}
                </Row> 
               
               :
                
                <Row>
                {item.prerequisite.length > 0 &&
                    <Col  className="text-left mb-4">
                        <h6 className="text-muted small">Prerequisite</h6>
                        <hr/>
                        <div className="progress-items-col">
                        {prereq.map((item, index) => (                
                        <div key={index} onClick={updateLoading}>
                        <Block itemInfo={item} />
                        </div>
                         ))} 
                        </div>
                    </Col>}

               { item.usedIn.length > 0 && 
                    <Col  className="text-left mb-4">
                        <h6 className="text-muted small">Used in</h6>
                        <hr/>
                        <div className="progress-items-col">
                        {usedIn.map((item, index) => (                
                        <div key={index} onClick={updateLoading}>
                        <Block itemInfo={item} />
                        </div>
                         ))} 
                        </div>
                    </Col>}
                </Row> }


                <Row>
                    <Col className="text-left mb-4">
                        <h6 className="text-muted small">Statistics</h6>
                        <hr/>
                        
                        {item.userInfo.length > 0 ? <React.Fragment>

                           { (item.userInfo[0].name_times_correct || item.userInfo[0].name_times_wrong) ? <React.Fragment>
                            <div className="d-flex justify-content-between"><span>Meaning</span> <span><b>{item.userInfo[0].name_times_correct + item.userInfo[0].name_times_wrong}</b></span></div>
                            <ProgressBar>
                                <OverlayTrigger overlay={<Tooltip>Correct: {item.userInfo[0].name_times_correct}</Tooltip>}>
                                <ProgressBar now={item.userInfo[0].name_times_correct} max={item.userInfo[0].name_times_correct + item.userInfo[0].name_times_wrong} label={`${Math.round((item.userInfo[0].name_times_correct/(item.userInfo[0].name_times_correct + item.userInfo[0].name_times_wrong))*100)}%`} isChild={true} className={item.type === "Radical" ? "fcb-primary" : item.type === "Hanzi" ? "fcb-blue" : "fcb-pink"} key={1} />
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip>Wrong: {item.userInfo[0].name_times_wrong}</Tooltip>}> 
                                <ProgressBar now={item.userInfo[0].name_times_wrong}  max={item.userInfo[0].name_times_correct + item.userInfo[0].name_times_wrong} label={`${Math.round((item.userInfo[0].name_times_wrong/(item.userInfo[0].name_times_correct + item.userInfo[0].name_times_wrong))*100)}%`} isChild={true} className="bg-secondary" key={2} />
                                </OverlayTrigger>
                            </ProgressBar></React.Fragment> : null}

                            {(item.userInfo[0].read_times_correct || item.userInfo[0].read_times_wrong) ? <React.Fragment>
                            <br/>
                            <div className="d-flex justify-content-between"><span>Reading</span> <span><b>{item.userInfo[0].read_times_correct + item.userInfo[0].read_times_wrong}</b></span></div>
                            <ProgressBar>
                                <OverlayTrigger overlay={<Tooltip>Correct: {item.userInfo[0].read_times_correct}</Tooltip>}>
                                <ProgressBar now={item.userInfo[0].read_times_correct} max={item.userInfo[0].read_times_correct + item.userInfo[0].read_times_wrong} label={`${Math.round((item.userInfo[0].read_times_correct/(item.userInfo[0].read_times_correct + item.userInfo[0].read_times_wrong))*100)}%`} isChild={true} className={item.type === "Radical" ? "fcb-primary" : item.type === "Hanzi" ? "fcb-blue" : "fcb-pink"} key={1} />
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip>Wrong: {item.userInfo[0].read_times_wrong}</Tooltip>}> 
                                <ProgressBar now={item.userInfo[0].read_times_wrong}  max={item.userInfo[0].read_times_correct + item.userInfo[0].read_times_wrong} label={`${Math.round((item.userInfo[0].read_times_wrong/(item.userInfo[0].read_times_correct + item.userInfo[0].read_times_wrong))*100)}%`} isChild={true} className="bg-secondary" key={2} />
                                </OverlayTrigger>
                            </ProgressBar>
                            </React.Fragment> : null                       
                        } 
                        <br/>
                        <Row className="text-center">
                            <Col><small><b>Date learnt: </b><br/></small> {DateTime.fromISO(item.userInfo[0].date_learnt).toLocaleString(DateTime.DATETIME_SHORT)}</Col>                       
                            
                            <Col><small><b>Next review: </b><br/></small> {  userInfo.data.pause ? <p>Queue paused</p> : item.userInfo[0].memorized? <>Item memorized<br/><Button size="sm" variant="secondary" className="mt-2" onClick={changeMemorized}>Restart item</Button> <OverlayTrigger overlay={<Tooltip>This adds the item back into the review queue and reset the review score back to 1</Tooltip>}>
                    <i className="pl-1 bi bi-question-circle-fill"/>
                    </OverlayTrigger> {memorizeResponse && memorizeResponse.errors.message} </>  : 
                    
                    DateTime.fromISO(item.userInfo[0].next_review) < DateTime.local() ? <p>Now</p> :
                    DateTime.fromISO(item.userInfo[0].next_review).toLocaleString(DateTime.DATETIME_SHORT)}</Col></Row>
                        
                        
                        </React.Fragment> : <h6 className="text-center pt-5">Not learnt yet</h6>}


                    </Col>
                </Row>              
                
                    </Col>
                </Row>
               
            </motion.div>
            </Container>

        
        : <LoadingScreen/>
    );
}

export default ItemPage;