import React, { useEffect, useState, useRef} from "react";
import instance from "../axiosConfig";
import LoadingScreen from "./LoadingScreen";
import {Container, Navbar, Row, Col, ProgressBar, Form, Table, Button, InputGroup, FormControl, Jumbotron, Modal} from "react-bootstrap"
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion"
import parse from 'html-react-parser';
import Block from "./Block";
import EditNotes from "./EditNotes";
import CreateNotes from "./CreateNotes";
import CustomMean from "./CustomMean";
import CustomMeanEdit from "./CustomMeanEdit";
import spring from "../images/flower.svg"
import summer from "../images/sun.svg"
import autumn from "../images/leaf.svg"
import winter from "../images/snow.svg"
import AudioPlayer from "./AudioPlayer";

var _ = require('lodash');
const toneConvert = require('pinyin-tone-convert');
var levenshtein = require('fast-levenshtein');


function Review (props) {
    const history = useHistory();
    const [userInfo, setUserInfo] = useState();
    const [editNote, setEditNote] = useState(false);
    const [noteLoad, setNoteLoad] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [mute, setMute] = useState(false);
    const [audioLoad, setAudioLoad] = useState(false);
    const handleClose = () => setShowKeyboard(false);
    const handleShow = () => setShowKeyboard(true);


    useEffect(() => {

        if(props.userInfo && props.isLoggedIn){
            setUserInfo(props.userInfo);
            if (props.userInfo.data.pause === true){
                setIsLoading(false);
            } else {
                instance.get("/api/users/user?review=true")
            .then(response => {

                if (response.data.length > 0){
                    let userReview = response.data[0].learntItem;
                    let hasLearnt = userReview.map(item => {return item.itemID});               
                    instance.get("/api/items?reviewqueue=" + hasLearnt)  
                        .then(response => { 
                            const data = response.data;
                            setQuizQueue(                
                                data.map((item) => {return (item.type === "Radical" ? {...item, meanCorrect: false, wronged: "false"}: {...item, meanCorrect: false, readCorrect: false, wronged: "false"})})
                            ); 
                            
                            const img1 = new Image();
                            const img2 = new Image();
                            const img3 = new Image();
                            const img4 = new Image();
                            img1.src = spring;
                            img2.src = summer;
                            img3.src = autumn;
                            img4.src = winter;
                        })
                        .then(response => {
                            props.handleNavBar(true);
                            setIsLoading(false);
                            setEditNote(false);
                            setNoteLoad(false);
                            setMeanLoad(false);
                            setEditMean(false);
                            divFocus.current.focus();
                        })
                        .catch((err) => {
                            if (err.response.data === "Unauthorized"){
                                window.location.reload()
                            } else {
                                console.log(err);
                            };
                        });
                    } else {props.handleNavBar(true); setIsLoading(false);}
            }).catch((err) => {
                if (err.response.data === "Unauthorized"){
                    window.location.reload()
                } else {
                    console.log(err);
                };
            });        
            }
            

        } else if (props.isLoggedIn === false){
            history.push('/login');
        }
          

    },[props.userInfo, props.isLoggedIn]);

    useEffect(()=> {
        if (!mute){
            musicFocus?.current?.click();
        }    
    },[audioLoad])

    function updateAudio(){
        setAudioLoad(!audioLoad);
    }

    function updateMute(){
        setMute(!mute);
    }

    const [answer, setAnswer] = useState("");
    const [quizQueue, setQuizQueue] = useState([]);
    const [quizIndex, setQuizIndex] = useState(0);
    const [hasStarted, setHasStarted] = useState(false);
    const [fastMode, setFastMode] = useState(false);
    const [aniState, setAniState] = useState(0); // 0 is neutral, 1 is correct, 2 is wrong
    const [clickIndex, setClickIndex] = useState(0);
    const [answerState, setAnswerState] = useState(0); // 0 is neutral, 1 is correct, 2 is wrong
    const [isLoading, setIsLoading] =  useState(true);
    const [editMean, setEditMean] = useState(false);
    const [meanLoad, setMeanLoad] = useState(false);
    const [showImg, setShowImg] = useState(false);
    const divFocus = useRef(null);
    const musicFocus = useRef(null);
    
    function random (event){
        event.preventDefault();
        let array = quizQueue
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        setQuizQueue(array);
        setHasStarted(true);
    }
    
    function goHome(event){
        event.preventDefault();
        history.push("/");
    }

    function handleChange(event){
        setAnswer(event.target.value.slice(0,100));
    } 

    function handleFastMode(){
        setFastMode(!fastMode);
    }

    function handleMeanDown(event){
        if (event.key === "Enter"){
            submitMean(event);
        } 
    }

    function handleReadDown(event){
        if (event.key === "Enter"){
            submitRead(event);
        } 
    }

    function aniCorrect(){
        setAniState(1);
        setTimeout(function(){
            setAniState(0);
        },100);
    }

    function aniWrong(){
        setAniState(2);
        setTimeout(function(){
            setAniState(0);
        },100);
    }

    function handleNoteLoad(res){
        setNoteLoad(res)
    }

    function handleNote(res){
        setEditNote(res);
    }

    function handleMeanLoad(res){
        setMeanLoad(res);
        
    }

    function handleMean(res){
        setEditMean(res);
    }

    function handleImg(){
        setShowImg(!showImg);
    }

    function handleKeyDown(event){
        var keyboardPressed = event.key;

        if (hasStarted === true){

            if ( event.target.nodeName.toLowerCase() !== 'input' && event.target.nodeName.toLowerCase() !== 'textarea' && (keyboardPressed === "s" || keyboardPressed ==="S") && answerState !== 0 && quizQueue[quizIndex].img){
                setShowImg(!showImg);
            } else if ( event.target.nodeName.toLowerCase() !== 'input' && event.target.nodeName.toLowerCase() !== 'textarea' && quizQueue[quizIndex].meanCorrect === true && (keyboardPressed === "d" || keyboardPressed ==="D") && answerState !== 0 && quizQueue[quizIndex].audio){
                musicFocus.current.click();
            }          

        } 
    }

   function submitMean(event){
    event.preventDefault();
    let levenScore = quizQueue[quizIndex].name.map(item =>{return levenshtein.get(answer, item)});
    let answerLC = quizQueue[quizIndex].name.map(item =>{return _.toLower(item)}); 
    var answerCustom = null;

    if (quizQueue[quizIndex].notes.length > 0 && quizQueue[quizIndex].notes[0].notes.custom_mean.trim()){
        var split =  parse(quizQueue[quizIndex].notes[0].notes.custom_mean).split(",");
        answerCustom = split.map(item =>{return _.toLower(item.replace(/\s/g, ""))});
    }

        if ( (answer.length >3 && levenScore.some(item => item <= 2)) || answerLC.includes(_.toLower(answer.trim())) || (answer.trim() && answerCustom?.includes(_.toLower(answer.replace(/\s/g, ""))))) {
            setClickIndex(clickIndex+1);
            if (clickIndex === 1 || fastMode){
                setAnswerState(0);
                setAnswer("");
                let copy = quizQueue;
                copy[quizIndex].meanCorrect = true;
                setQuizQueue(copy);
                setClickIndex(0);
                    if (quizQueue[quizIndex].type === "Radical" && quizQueue[quizIndex].wronged === "false"){
                        instance({
                            method: "put",
                            withCredentials: true,
                            url: "/api/users/user",
                            data: {increase: "true", itemID: quizQueue[quizIndex]._id, readOrName: "name"},
                            headers:{ 'content-type': 'application/json' }
                        }).catch((err) => {
                            if (err.response.data === "Unauthorized"){
                                window.location.reload()
                            } else {
                                console.log(err);
                            };
                        });
                        
                    } else if(quizQueue[quizIndex].wronged === "false"){
                        instance({
                            method: "put",
                            withCredentials: true,
                            url: "/api/users/user",
                            data: {handleCorrectOnly: "true", itemID: quizQueue[quizIndex]._id, readOrName: "name"},
                            headers:{ 'content-type': 'application/json' }
                        }).catch((err) => {
                            if (err.response.data === "Unauthorized"){
                                window.location.reload()
                            } else {
                                console.log(err);
                            };
                        });
                    }
            }  else {
                 setAnswerState(1);
                 aniCorrect();
            }

        } else{            
            aniWrong();
            setAnswer("");   
            setAnswerState(2); 
              
            if (quizQueue[quizIndex].wronged === "false") {                                
                                    
                let copy = quizQueue;
                copy[quizIndex].wronged = "true";
                setQuizQueue(copy);
                instance({
                        method: "put",
                        withCredentials: true,
                        url: "/api/users/user",
                        data: {decrease: "true", itemID: quizQueue[quizIndex]._id, readOrName: "name"},
                        headers:{ 'content-type': 'application/json' }
                }).catch((err) => {
                    if (err.response.data === "Unauthorized"){
                        window.location.reload()
                    } else {
                        console.log(err);
                    };
                });                                                             
            }     
        }
    
    }

    function submitRead(event){
        event.preventDefault();
        if ( quizQueue[quizIndex].pronun.includes(toneConvert(_.toLower(answer).replace(/\s/g, "")))) {
            setClickIndex(clickIndex+1);                            
            if (clickIndex === 1 || fastMode){
                setAnswerState(0);
                setAnswer("");
                let copy = quizQueue;
                copy[quizIndex].readCorrect = true;
                setQuizQueue(copy);
                setClickIndex(0);
                if(quizQueue[quizIndex].wronged === "false"){
                    instance({
                        method: "put",
                        withCredentials: true,
                        url: "/api/users/user",
                        data: {increase: "true", itemID: quizQueue[quizIndex]._id, readOrName: "read"},
                        headers:{ 'content-type': 'application/json' }
                    }).catch((err) => {
                        if (err.response.data === "Unauthorized"){
                            window.location.reload()
                        } else {
                            console.log(err);
                        };
                    });
                } else if(quizQueue[quizIndex].wronged === "true") {
                    instance({
                            method: "put",
                            withCredentials: true,
                            url: "/api/users/user",
                            data: {handleCorrectOnly: "true", itemID: quizQueue[quizIndex]._id, readOrName: "read"},
                            headers:{ 'content-type': 'application/json' }
                        }).catch((err) => {
                            if (err.response.data === "Unauthorized"){
                                window.location.reload()
                            } else {
                                console.log(err);
                            };
                        });                               
                }
            }   else {
                setAnswerState(1);
                aniCorrect();
            }                  

        } else { 
            aniWrong();
            setAnswer("");                             
            setAnswerState(2);
            if (quizQueue[quizIndex].wronged === "false"){
                let copy = quizQueue;
                copy[quizIndex].wronged = "half";
                setQuizQueue(copy);
                instance({
                        method: "put",
                        withCredentials: true,
                        url: "/api/users/user",
                        data: {decrease: "true", itemID: quizQueue[quizIndex]._id, readOrName: "read"},
                        headers:{ 'content-type': 'application/json' }
                        }).catch((err) => {
                            if (err.response.data === "Unauthorized"){
                                window.location.reload()
                            } else {
                                console.log(err);
                            };
                        });

            } else {
                let copy = quizQueue;
                copy[quizIndex].wronged = "half";
                setQuizQueue(copy);
                instance({
                        method: "put",
                        withCredentials: true,
                        url: "/api/users/user",
                        data: {handleWrongOnly: "true", itemID: quizQueue[quizIndex]._id, readOrName: "read"},
                        headers:{ 'content-type': 'application/json' }
                        }).catch((err) => {
                            if (err.response.data === "Unauthorized"){
                                window.location.reload()
                            } else {
                                console.log(err);
                            };
                        }); 
            }
        }        
    }

    const jumboVariants = {
        correct: { 
            backgroundColor: "#28a745",
            transition: { duration: 0.1 }
        },
        wrong: { 
            backgroundColor: "#FFB325",
            transition: { duration: 0.1 }
        },
        neutral: {
            backgroundColor: "#e9ecef",
            transition: { duration: 0.5 }
        }
    }

    function delayedLoad(obj){
        if (obj === "notes"){
            setTimeout(function() {
                setNoteLoad(false);
                setEditNote(false);
              }, 1000);
        } else if (obj === "mean"){
            setTimeout(function() {
                setMeanLoad(false);
                setEditMean(false);
              }, 1000);
        }        
    }

    const updateState=(data)=>{                 
        if (data.type === "notes"){
            //Notes edit
            if (data.new) {
                let replace = quizQueue[quizIndex];
                replace.notes[0] = {"notes": {"content": data.content, "custom_mean": ""}}
                let newQueue=[...quizQueue];
                newQueue.splice(quizIndex,1,replace)
                setQuizQueue(newQueue)   
            } else {
                let replace = quizQueue[quizIndex];
                replace.notes[0].notes.content = data.content;
                let newQueue=[...quizQueue];
                newQueue.splice(quizIndex,1,replace)
                setQuizQueue(newQueue)   
            } 
            delayedLoad("notes");
                
        } else if (data.type === "mean"){
            //Mean edit
            if (data.new){
                let replace = quizQueue[quizIndex];
                replace.notes[0] = {"notes": {"content": "", "custom_mean": data.customMean}}
                let newQueue=[...quizQueue];
                newQueue.splice(quizIndex,1,replace)
                setQuizQueue(newQueue)   
            } else {
                let replace = quizQueue[quizIndex];
                replace.notes[0].notes.custom_mean = data.customMean
                let newQueue=[...quizQueue];
                newQueue.splice(quizIndex,1,replace)
                setQuizQueue(newQueue)   
            }    
            delayedLoad("mean");
        } 
    }


    return (
    
        (!isLoading && userInfo?.data && props.hideNavBar)? 
        <div style={{marginBottom: "5em"}}>{
        userInfo.data.pause ? 
        
        <div>
        
        <Container fluid className="container-custom py-lg-5 py-3 h-100" >
        <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}>  
        
        <Jumbotron className="text-center">                    

        <i className="h1 bi bi-exclamation-circle-fill"> Your review queue is paused</i><br/><br/><br/>
        <p>Resume your queue to continue learning</p>
        </Jumbotron>
        
        </motion.div></Container></div>
        
        : 
        <div>
         <Modal show={showKeyboard} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title><i className="h4 bi bi-question-circle"/> Tips</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <h5 className="font-weight-bold">Keyboard Shortcut</h5>
                            <br/>
                
                            <Row  className="pb-3">
                                <Col md={9}>
                                    Toggle image 
                                    <br/><small>Only available for certain Radicals</small>
                                </Col>
                                <Col>
                                S
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col md={9}> 
                                Play audio 
                                <br/><small>Only available for certain Hanzi and Vocab</small>
                                </Col>
                                <Col >
                                D
                                </Col>
                            </Row>
                            <hr/>
                            <h5 className="font-weight-bold">Fast Mode</h5>
                            <br/>
                            <p className="pb-3"> Activating fast mode will allow you to go directly to the next item when you answer correctly</p>
                            <hr/>
                            <h5 className="font-weight-bold">Tone Input</h5>
                            <br/>
                            <p className="pb-3">For Hanzi or Vocab that has a tone, add the tone number after each pin yin without spaces 
                            <br/>
                            (e.g. 你好 nǐhǎo ni3hao3)</p>

                        </Container>
                    </Modal.Body>
                </Modal>
        
        <Navbar expand="sm" className="bg-white py-2" variant="light">
            <Container fluid className="container-custom">      
                <Navbar.Brand href="/dashboard"><i className="bi bi-house-door-fill"/></Navbar.Brand>
                
                {(quizQueue.length > quizIndex && hasStarted === true) &&  <React.Fragment>  <Navbar.Text className="mr-auto">  </Navbar.Text>  
                {quizIndex + 1}/<b className="fc-primary pr-3">{quizQueue.length}</b><Navbar.Text className="mr-auto d-none d-sm-block"> <ProgressBar variant="success" now={quizIndex + 1} max={quizQueue.length} /> </Navbar.Text>
                {mute ? <i onClick={updateMute} className="h2 pt-2 pr-3 icon-grey bi bi-volume-mute"/> :<i onClick={updateMute} className="h2 pt-2 pr-3 icon-grey bi bi-volume-up"/>} <i className="h2 pt-2 pr-3 icon-grey bi bi-question-circle" onClick={handleShow}/> <Navbar.Text > <Form inline>  <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label="Fast mode"
                        className="small"
                        onChange={handleFastMode}
                    /></Form></Navbar.Text></React.Fragment> }

            </Container>  
        </Navbar>
        
        <Container ref={divFocus} onKeyDown={handleKeyDown} tabIndex={0} fluid className="container-custom py-lg-5 py-3" >
        <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}> 
        
        {quizQueue.length > quizIndex  ?

        (hasStarted === false)? 

        <div className="text-center pt-5"><h1 style={{fontSize: "5em"}}>{quizQueue.length}</h1><p>Review items</p><Button className="pr-3" onClick={random} variant="success">Start review</Button></div>
        
        : quizQueue[quizIndex].meanCorrect === false ? 

        <React.Fragment>

            <motion.div initial="visible" variants={jumboVariants} animate={aniState === 0 ? "neutral" : aniState === 1 ? "correct" : "wrong"} className="jumbotron text-center">
                <div className="fixed-height d-flex flex-column justify-content-center">
                <Row><Col >
                
                <AnimatePresence initial={false}>
                    {showImg &&
                    <motion.div key={0} initial="collapsed" variants={{
                    open: { opacity: 1, height: 300 },
                    collapsed: { opacity: 0, height: 0 }}} 
                    animate="open"
                    exit="collapsed">
                    <img src={`/assets/images/radicals/${quizQueue[quizIndex].string_ID}.svg`} alt={quizQueue[quizIndex].character} className="radical-img"/> </motion.div>}</AnimatePresence> 
                    
                     
                    
                    {!showImg && <div className={quizQueue[quizIndex].type === "Radical" ? "fc-primary" : quizQueue[quizIndex].type === "Hanzi" ? "fc-blue" : "fc-pink"}
                    style={{fontSize: "3.5rem"}}>               
                    {quizQueue[quizIndex].character}</div>}
                                   
                
                <small>{quizQueue[quizIndex].type} <span>{(quizQueue[quizIndex].img && answerState !== 0) && <i onClick={handleImg} className="bi bi-card-image bi-card-quiz-pos"/>}</span></small> 
                
                </Col></Row>
                <br/>
                {answerState === 2 && <div><p>Answer is wrong</p></div>}
                {answerState === 1 && <div><p>Answer is correct</p></div>}

                </div>
            </motion.div>                    
      
            <InputGroup className="mb-5">
                    <InputGroup.Prepend>
                    <InputGroup.Text className="color-dark-green">Meaning</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl placeholder="Meaning Answer" className="text-center" onChange={handleChange} onKeyDown={handleMeanDown} name="answer" value={answer} readOnly={answerState===1? true : false} autoComplete="off" />
                    <InputGroup.Append>
                    <Button variant="outline-secondary" onClick={submitMean} >Submit</Button>
                    </InputGroup.Append>
            </InputGroup>

            {(answerState === 1 || answerState === 2) && <>
                <Row><Col className="text-left mb-4">
                    <h6 className="text-muted small">Meaning</h6>
                    <hr/>                       
                    <Table  borderless size="sm">
                        
                        <tbody className="text-center">
                                                                
                            <tr>
                            <td className="quiz-table"><small>Meaning</small></td>
                            <td ><p>{quizQueue[quizIndex].name.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })} </p></td>
                            </tr>  

                            <tr>
                            <td className="quiz-table"><small>Custom meaning</small></td>
                            <td >
                            {quizQueue[quizIndex].notes.length > 0 ?                                         
                            editMean ? 
                            <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                            <CustomMeanEdit handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID} notes={quizQueue[quizIndex].notes[0].notes}/></motion.div> : (quizQueue[quizIndex].notes[0].notes.custom_mean && quizQueue[quizIndex].notes[0].notes.custom_mean.trim()) ? <div className="text-center"><p>{parse(quizQueue[quizIndex].notes[0].notes.custom_mean)}</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                            : 
                            editMean ? 
                            <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                            <CustomMean handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                            }
                            </td>
                            </tr>                                     

                            <tr>
                            <td className="quiz-table"><small>Mnemonic</small></td>
                            <td >{parse(quizQueue[quizIndex].mnemonicName)}</td>
                            </tr>                                        

                        </tbody>
                    </Table>
                </Col></Row>

                <Row><Col className="mb-5">
                    <h6 className="text-muted small">Notes</h6>
                    <hr/>
                    {quizQueue[quizIndex].notes.length > 0 ? 
                    
                    editNote ? 
                    <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                    <EditNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID} notes={quizQueue[quizIndex].notes[0].notes}/></motion.div> : (quizQueue[quizIndex].notes[0].notes.content && quizQueue[quizIndex].notes[0].notes.content.trim()) ? <div className="text-center"><p>{parse(quizQueue[quizIndex].notes[0].notes.content)}</p><i onClick={()=> setEditNote(true)}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> setEditNote(true)}className="pencil-button bi bi-pencil-fill"></i></div>
                    
                    : 
                    
                    editNote ? 
                    <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                    <CreateNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> setEditNote(true)}className="pencil-button bi bi-pencil-fill"></i></div>
                    
                    }                                
                </Col></Row>

                {quizQueue[quizIndex].type === "Radical" && 
                    
                    <Row>
                    
                    {quizQueue[quizIndex].usedIn.length > 0 && 
                        <Col  className="text-left mb-4">
                            <h6 className="text-muted small">Used in</h6>
                            <hr/>
                            <div className="d-flex flex-wrap flex-wrap justify-content-center justify-content-sm-start">
                            {quizQueue[quizIndex].usedIn.map((item, index) => (                
                            <Block itemInfo={item} key={index} />
                                ))} 
                            </div>
                        </Col>}
                    </Row> 
                }
            </>}

        </React.Fragment>  : (quizQueue[quizIndex].hasOwnProperty("readCorrect") && quizQueue[quizIndex].readCorrect === false) ? <React.Fragment>

                                        
                <motion.div style={ (answerState === 1 || answerState === 2) ? quizQueue[quizIndex].tone === 1 ? {backgroundImage: `url(${spring})`} : quizQueue[quizIndex].tone === 2 ? {backgroundImage: `url(${summer})`} : quizQueue[quizIndex].tone === 3 ? {backgroundImage: `url(${autumn})`} : quizQueue[quizIndex].tone === 4 ? {backgroundImage: `url(${winter})`} : {backgroundColor: "#e9ecef"} : {backgroundColor: "#e9ecef"} } initial="visible" variants={jumboVariants} animate={aniState === 0 ? "neutral" : aniState === 1 ? "correct" : "wrong"} className="jumbotron text-center">

                <div className="fixed-height d-flex flex-column justify-content-center">
                <Row><Col >
                <h1 className={quizQueue[quizIndex].type === "Radical" ? "fc-primary" : quizQueue[quizIndex].type === "Hanzi" ? "fc-blue" : "fc-pink"} style={{fontSize: "3.5rem"}}>{quizQueue[quizIndex].character}</h1>
                <small>{quizQueue[quizIndex].type}</small>
                </Col></Row>
                <br/>
                {answerState === 2 && <div><p>Answer is wrong</p></div>}
                {answerState === 1 && <div><p>Answer is correct</p></div>}

                </div>
                </motion.div>  
                <InputGroup className="mb-5">
                    <InputGroup.Prepend>
                    <InputGroup.Text className="color-dark-blue">Reading</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl placeholder="Reading Answer" className="text-center" onChange={handleChange} onKeyDown={handleReadDown} name="answer" value={answer} readOnly={answerState===1? true : false} autoComplete="off" />
                    <InputGroup.Append>
                    <Button variant="outline-secondary" onClick={submitRead} >Submit</Button>
                    </InputGroup.Append>
                </InputGroup>
                {(answerState === 1 || answerState === 2) && <React.Fragment>
                    <Row><Col className="text-left mb-4">
                    <h6 className="text-muted small">Meaning</h6>                    
                    <hr/>
                        <Table  borderless size="sm">
                        
                        <tbody className="text-center">                                       
                                                            
                            <tr>
                            <td className="quiz-table"><small>Meaning</small></td>
                            <td ><p>{quizQueue[quizIndex].name.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })} </p></td>
                            </tr>

                            <tr>
                            <td className="quiz-table"><small>Custom meaning</small></td>
                            <td >
                            {quizQueue[quizIndex].notes.length > 0 ?                                         
                            editMean ? 
                            <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                            <CustomMeanEdit handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID} notes={quizQueue[quizIndex].notes[0].notes}/></motion.div> : (quizQueue[quizIndex].notes[0].notes.custom_mean && quizQueue[quizIndex].notes[0].notes.custom_mean.trim()) ? <div className="text-center"><p>{parse(quizQueue[quizIndex].notes[0].notes.custom_mean)}</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                            : 
                            editMean ? 
                            <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                            <CustomMean handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                            }
                            </td>
                            </tr>                                 

                            <tr>
                            <td className="quiz-table"><small>Mnemonic</small></td>
                            <td >{parse(quizQueue[quizIndex].mnemonicName)}</td>
                            </tr>                                          

                        </tbody>
                    </Table></Col>
                    
                    {quizQueue[quizIndex].mnemonicPronun &&
                <Col sm={12} lg={6} className="text-left mb-4">
                    <h6 className="text-muted small">Reading {quizQueue[quizIndex].audio && <AudioPlayer updateAudio={updateAudio} ref={musicFocus} place={true} source={quizQueue[quizIndex].string_ID}/>}</h6>
                    <hr/>
                    <Table  borderless size="sm">
                    
                        <tbody className="text-center">                              
                        <tr>
                            <td className="quiz-table"><small>Reading</small></td>
                            <td ><p>{quizQueue[quizIndex].pronun.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })} </p></td>
                            </tr>

                            <tr>
                            <td className="quiz-table"><small>Mnemonic</small></td>
                            <td>{parse(quizQueue[quizIndex].mnemonicPronun)}</td>
                            </tr>                                         

                        </tbody>
                    </Table>

                </Col>}         

                    </Row>

                    {quizQueue[quizIndex].exampleSentence && 
                    <Row>
                        <Col className="text-left mb-4">
                        <h6 className="text-muted small">Example Sentences</h6>
                        <hr/>                 
                    
                        {quizQueue[quizIndex].exampleSentence.map((example, index) => {
                            return (
                                <div key={index} className="text-center">{parse(example.CN)}<br/><small>{parse(example.PY)}</small><p>{parse(example.EN)}</p></div>
                            )})}

                    </Col></Row>}
                    
                

                    <Row><Col className="mb-5">
                    <h6 className="text-muted small">Notes</h6>
                    <hr/>
                    {quizQueue[quizIndex].notes.length > 0 ? 
                    
                    editNote ? 
                    <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                    <EditNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID} notes={quizQueue[quizIndex].notes[0].notes}/></motion.div> : (quizQueue[quizIndex].notes[0].notes.content && quizQueue[quizIndex].notes[0].notes.content.trim()) ? <div className="text-center"><p>{parse(quizQueue[quizIndex].notes[0].notes.content)}</p><i onClick={()=> {setEditNote(true); setEditMean(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}}className="pencil-button bi bi-pencil-fill"></i></div>                    
                    : 
                    
                    editNote ? 
                    <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                    <CreateNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}} className="pencil-button bi bi-pencil-fill"></i></div>                    
                    }                                
                    </Col></Row>

                    {quizQueue[quizIndex].type === "Hanzi" ? 
               
                        <Row>
                        {quizQueue[quizIndex].prerequisite.length > 0 &&
                            
                            <Col  sm={12} lg={6} className="text-left mb-4">
                                <h6 className="text-muted small">Prerequisite</h6>
                                <hr/>
                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start">
                                {quizQueue[quizIndex].prerequisite.map((item, index) => (                
                                <Block itemInfo={item} key={index} />
                                    ))} 
                                </div>

                            </Col>}
                            
                        {quizQueue[quizIndex].usedIn.length > 0 &&   
                            <Col  sm={12} lg={6} className="text-left mb-4">
                                <h6 className="text-muted small">Used in</h6>
                                <hr/>
                                <div className="d-flex flex-wrap flex-wrap justify-content-center justify-content-sm-start">
                                {quizQueue[quizIndex].usedIn.map((item, index) => (                
                                <Block itemInfo={item} key={index} />
                                    ))} 
                                </div>
                            </Col>}
                        </Row> 
                        
                        :
                        
                        <Row>
                        {quizQueue[quizIndex].prerequisite.length > 0 &&
                            <Col  className="text-left mb-4">
                                <h6 className="text-muted small">Prerequisite</h6>
                                <hr/>
                                <div className="d-flex flex-wrap flex-wrap justify-content-center justify-content-sm-start">
                                {quizQueue[quizIndex].prerequisite.map((item, index) => (                
                                <Block itemInfo={item} key={index} />
                                    ))} 
                                </div>
                            </Col>}

                        {quizQueue[quizIndex].usedIn.length > 0 && 
                            <Col  className="text-left mb-4">
                                <h6 className="text-muted small">Used in</h6>
                                <hr/>
                                <div className="d-flex flex-wrap flex-wrap justify-content-center justify-content-sm-start">
                                {quizQueue[quizIndex].usedIn.map((item, index) => (                
                                <Block itemInfo={item} key={index} />
                                    ))} 
                                </div>
                            </Col>}
                        </Row> 
                    }


                </React.Fragment>}   
                    
                </React.Fragment>        
                    : setQuizIndex(quizIndex + 1)
        
    : <div className="text-center pt-5"><i className="bi-large-1 bi bi-check-circle"></i><p>You have no items in your review queue</p><Button onClick={goHome}  variant="success">Back to dashboard</Button></div>}  
    </motion.div>   
    </Container>
</div>}</div>
        
        
        : <LoadingScreen/>
        




   
        
        
        
    
    )}
export default Review;