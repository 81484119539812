import React, { useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import instance from "../axiosConfig";
import LoadingScreen from "./LoadingScreen";
import {Container, Row, Col, ProgressBar, OverlayTrigger, Tooltip, Badge} from "react-bootstrap"
import {motion} from "framer-motion";
import Block from "./Block";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as BToolTip,
    Legend,
    ResponsiveContainer
  } from "recharts";
  

const { DateTime } = require("luxon");
var _ = require('lodash');

function MyProgress (props){

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [breakdown, setBreakdown] = useState([]);    
    const [selection, setSelection] = useState("Learn")
    const [sort, setSort] = useState("Monthly")
    
    // Learn Records Variables
    const [progress, setProgress] = useState(null);

    const [learnProgress, setLearnProgress] = useState(null);
    const [learnProgressYear, setLearnProgressYear] = useState(null);
    const [learnIndex, setLearnIndex] = useState(0);
    const [learnBarIndex, setLearnBarIndex] = useState(0); 
    const [learnIndexYear, setLearnIndexYear] = useState(0);
    const [learnBarIndexYear, setLearnBarIndexYear] = useState(0); 


    // Review Records Variables
    const [progress3, setProgress3] = useState(null);

    const [reviewProgress, setReviewProgress] = useState(null);
    const [reviewProgressYear, setReviewProgressYear] = useState(null);
    const [reviewIndex, setReviewIndex] = useState(0);
    const [reviewBarIndex, setReviewBarIndex] = useState(0);
    const [reviewIndexYear, setReviewIndexYear] = useState(0);
    const [reviewBarIndexYear, setReviewBarIndexYear] = useState(0);


    // Overall Progress Variables
    const [progress2, setProgress2] = useState(null);
    const [progress4, setProgress4] = useState(null);
    const [progress5, setProgress5] = useState(null);
    const [learnCount, setLearnCount] = useState(null); //learnCount also contains total count
    const [memoCount, setMemoCount] = useState(null);
    const [reviewCount, setReviewCount] = useState(null);

    //Forecast Variables
    const [progress6, setProgress6] = useState(null);
    const [forecast, setForecast] = useState(null);
    const [forecastIndex, setForecastIndex] = useState(0);
    const [forecastBarIndex, setForecastBarIndex] = useState(0);


    let monthRad = 0
    let monthHan = 0
    let monthVoc = 0
    const getZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  
    useEffect(() => {
        //Check login & get Progress records
        if(props.userInfo && props.isLoggedIn){
            instance.get("/api/users/user?progress=true&timezone=" + Intl.DateTimeFormat().resolvedOptions().timeZone)
            .then(response => {
                const {learnRecords, totalCount, reviewRecords, totalCountMemo, totalCountReview, futureCount } = response.data;
                setProgress(learnRecords.slice(1));
                setProgress2(totalCount);
                setProgress3(reviewRecords);
                setProgress4(totalCountMemo);
                setProgress5(totalCountReview);
                setProgress6(futureCount);
    
            }).catch((err) => {
                if (err.response.data === "Unauthorized"){
                    window.location.reload();
                } else {
                    console.log(err);
                }
            });

        } else if (props.isLoggedIn === false){
            window.location.reload();
        }

    },[props.userInfo, props.isLoggedIn])

    useEffect(() => {
        //Progress for learn records
        if (progress != null && progress.length > 0){
            let startDate = progress[0].date;
            // let endDate = progress[progress.length-1].date;

            //convert date into format
            var map = {};
            for (var i in progress) {
                map[ DateTime.fromISO(progress[i].date).setZone(getZone).set({hour: 23, minute: 59, second: 59, millisecond:999}) ] = progress[i];
            }

            let totalDays = DateTime.local().set({hour: 23, minute: 59, second: 59, millisecond:999}).diff(DateTime.fromISO(startDate).setZone(getZone).set({hour: 23, minute: 59, second: 59, millisecond:999}), "days");  
            for (var ms = DateTime.local().set({hour: 23, minute: 59, second: 59, millisecond:999}), x = 1; x < totalDays.values.days; x++) {
                ms = ms.plus({day: -1})
                if ( ! ( ms in map ) ) {
                    map[ms] = {date : ms.toUTC().toISO(), results : []};
                }
            }

            function sortResult(x, y) {
                var t1 = x.date;
                var t2 = y.date;
                if (t1 < t2) {
                    return -1;
                } else if (t1 === t2) {
                    return 0;
                } else {
                    return 1;
                }
            }

            var finalResult = [];
            
            for (var z in map) {
                finalResult.push(map[z]);
            }

            finalResult.sort(sortResult);

            var data = [];
            //Convert data into bar graph data format 
            
            finalResult.forEach((i, index) =>{
                let current = {}
                current.name = DateTime.fromISO(i.date).toLocaleString(DateTime.DATE_SHORT);
                current.groupDate = DateTime.fromISO(i.date).toLocaleString({month: 'long', year: 'numeric' }).replace(/\s/g, "");
                current.year = DateTime.fromISO(i.date).toLocaleString({year: 'numeric' });
                current.month = DateTime.fromISO(i.date).toLocaleString({month: 'long'})
                if (i.results.length > 0){
                    current.radItem =[];
                    current.hanItem =[];
                    current.vocItem =[];

                    i.results.forEach((x) => {
                        if (x.bundle[0].type ==="Radical"){
                        current.Radical = x.bundle.length;
                        x.bundle.forEach((y)=>{
                            current.radItem.push(y);
                        })
                        } 

                        if (x.bundle[0].type ==="Hanzi"){
                            current.Hanzi = x.bundle.length;
                            x.bundle.forEach((y)=>{
                                current.hanItem.push(y);
                            })
                        } 

                        if (x.bundle[0].type ==="Vocab"){
                            current.Vocab = x.bundle.length;
                            x.bundle.forEach((y)=>{
                                current.vocItem.push(y);
                            })
                        }
                    
                    })
                } else {
                    current.empty = true;
                }  
                data.push(current);           
            });

            var monthData = [];
            monthData = _.groupBy(data, function(b){ return b.groupDate});

            //convert into array and reverse the order
            var arrayData = Object.values(monthData).reverse();
            setLearnProgress(arrayData);
    
        } else {setLearnProgress(false); setLearnProgressYear(false);}
      
    },[progress])

    useEffect(() => {
        
        //Get learn overall values       
        if (progress2 != null){
            let current2 = {};
            current2.total = progress2[0].itemCount;
            current2.radical = 0;
            current2.hanzi = 0;
            current2.vocab = 0;

            progress2.forEach((x)=>{
                if (x._id === "Radical"){
                    current2.radical = x.count;
                } else if (x._id === "Hanzi"){
                    current2.hanzi = x.count;
                } else if (x._id === "Vocab"){
                    current2.vocab = x.count;
                }
            })            
            setLearnCount(current2);
        }
      
    },[progress2])


    useEffect(() => {

        //Get review values
        if (progress3 != null && progress3.length > 0){
            let startDate = progress3[0].date;

             //convert date into format
             var map = {};
             for (var i in progress3) {
                 map[ DateTime.fromISO(progress3[i].date).setZone(getZone).set({hour: 23, minute: 59, second: 59, millisecond:999}) ] = progress3[i];
             }
 
             let totalDays = DateTime.local().set({hour: 23, minute: 59, second: 59, millisecond:999}).diff(DateTime.fromISO(startDate).setZone(getZone).set({hour: 23, minute: 59, second: 59, millisecond:999}), "days");  
             for (var ms = DateTime.local().set({hour: 23, minute: 59, second: 59, millisecond:999}), x = 1; x < totalDays.values.days; x++) {
                 ms = ms.plus({day: -1})
                 if ( ! ( ms in map ) ) {
                     map[ms] = {date : ms.toUTC().toISO(), results : []};
                 }
             }
 
             function sortResult(x, y) {
                 var t1 = x.date;
                 var t2 = y.date;
                 if (t1 < t2) {
                     return -1;
                 } else if (t1 === t2) {
                     return 0;
                 } else {
                     return 1;
                 }
             }
 
             var finalResult = [];
             
             for (var z in map) {
                 finalResult.push(map[z]);
             }
 
             finalResult.sort(sortResult);
 
             var data2 = [];
             //Convert data into bar graph data format
             finalResult.forEach((i, index) =>{
             let current = {}
             current.name = DateTime.fromISO(i.date).toLocaleString(DateTime.DATE_SHORT);
             current.groupDate = DateTime.fromISO(i.date).toLocaleString({month: 'long', year: 'numeric' }).replace(/\s/g, "");
             current.year = DateTime.fromISO(i.date).toLocaleString({year: 'numeric' });
             current.month = DateTime.fromISO(i.date).toLocaleString({month: 'long'})

             
             
             if (i.results.length > 0){
                 current.radItem =[];
                 current.hanItem =[];
                 current.vocItem =[];
                 current.correct = 0;
                 current.wrong = 0;
 
                 i.results.forEach((x) => {
                     if (x.bundle[0].type ==="Radical"){
                     current.Radical = x.bundle.length;
                     x.bundle.forEach((y)=>{
                         current.radItem.push(y);
                         if (y.answer === true){
                            current.correct += 1
                         } else {
                            current.wrong += 1
                         }
                     })
                     } 
 
                     if (x.bundle[0].type ==="Hanzi"){
                         current.Hanzi = x.bundle.length;
                         x.bundle.forEach((y)=>{
                             current.hanItem.push(y);
                             if (y.answer === true){
                                current.correct += 1
                             } else {
                                current.wrong += 1
                             }
                         })
                     } 
 
                     if (x.bundle[0].type ==="Vocab"){
                         current.Vocab = x.bundle.length;
                         x.bundle.forEach((y)=>{
                             current.vocItem.push(y);
                             if (y.answer === true){
                                current.correct += 1
                             } else {
                                current.wrong += 1
                             }
                         })
                     }
                 
                 })
             } else {
                 current.empty = true;
             }
             data2.push(current);            

            })

            var monthData2 = [];
             monthData2 = _.groupBy(data2, function(b){ return b.groupDate});
             let arrayData2 = Object.values(monthData2).reverse();
             setReviewProgress(arrayData2);


        } else {
            setReviewProgress(false); setReviewProgressYear(false);
        }
      
    },[progress3])

    useEffect(() => {
    
        if (progress4 != null){
            let current2 = {};
            current2.radical = 0;
            current2.hanzi = 0;
            current2.vocab = 0;

            progress4.forEach((x)=>{
                if (x._id === "Radical"){
                    current2.radical = x.count;
                } else if (x._id === "Hanzi"){
                    current2.hanzi = x.count;
                } else if (x._id === "Vocab"){
                    current2.vocab = x.count;
                }
            })
            
            setMemoCount(current2);
        }

      
    },[progress4])

    useEffect(() => {
    
        if (progress5 != null){
            let current2 = {};
            current2.radical = 0;
            current2.hanzi = 0;
            current2.vocab = 0;

            progress5.forEach((x)=>{
                if (x._id === "Radical"){
                    current2.radical = x.count;
                } else if (x._id === "Hanzi"){
                    current2.hanzi = x.count;
                } else if (x._id === "Vocab"){
                    current2.vocab = x.count;
                }
            })
            
            setReviewCount(current2);
        }

      
    },[progress5])

    //Forecast Values

    useEffect(()=>{
        if (progress6 != null && progress6.length > 0){
            let startDate = progress6[0].date;
            // let endDate = progress[progress.length-1].date;

            //convert date into format
            var map = {};
            for (var i in progress6) {
                map[ DateTime.fromISO(progress6[i].date).setZone(getZone).set({hour: 23, minute: 59, second: 59, millisecond:999}) ] = progress6[i];
            }

            let totalDays = DateTime.fromISO(startDate).setZone(getZone).set({hour: 23, minute: 59, second: 59, millisecond:999}).diff(DateTime.local().set({hour: 23, minute: 59, second: 59, millisecond:999}), "days");  
            for (var ms = DateTime.local().set({hour: 23, minute: 59, second: 59, millisecond:999}), x = 1; x < totalDays.values.days; x++) {
                ms = ms.plus({day: 1})
                if ( ! ( ms in map ) ) {
                    map[ms] = {date : ms.toUTC().toISO(), results : []};
                }
            }

            function sortResult(x, y) {
                var t1 = x.date;
                var t2 = y.date;
                if (t1 < t2) {
                    return -1;
                } else if (t1 === t2) {
                    return 0;
                } else {
                    return 1;
                }
            }

            var finalResult = [];
            
            for (var z in map) {
                finalResult.push(map[z]);
            }

            finalResult.sort(sortResult);

            var data = [];
            //Convert data into bar graph data format 
            
            finalResult.forEach((i, index) =>{
                let current = {}
                current.name = DateTime.fromISO(i.date).toLocaleString(DateTime.DATE_SHORT);
                current.groupDate = DateTime.fromISO(i.date).toLocaleString({month: 'long', year: 'numeric' }).replace(/\s/g, "");
                current.year = DateTime.fromISO(i.date).toLocaleString({year: 'numeric' });
                current.month = DateTime.fromISO(i.date).toLocaleString({month: 'long'});
                if (i.results.length > 0){
                    current.radItem =[];
                    current.hanItem =[];
                    current.vocItem =[];
                    current.time = DateTime.fromISO(i.date).toLocaleString(DateTime.TIME_SIMPLE);


                    i.results.forEach((x) => {
                        if (x.bundle[0].type ==="Radical"){
                        current.Radical = x.bundle.length;
                        x.bundle.forEach((y)=>{
                            current.radItem.push(y);
                        })
                        } 

                        if (x.bundle[0].type ==="Hanzi"){
                            current.Hanzi = x.bundle.length;
                            x.bundle.forEach((y)=>{
                                current.hanItem.push(y);
                            })
                        } 

                        if (x.bundle[0].type ==="Vocab"){
                            current.Vocab = x.bundle.length;
                            x.bundle.forEach((y)=>{
                                current.vocItem.push(y);
                            })
                        }
                    
                    })
                } else {
                    current.empty = true;
                }  
                data.push(current);           
            });

            var monthData = [];
            monthData = _.groupBy(data, function(b){ return b.groupDate});

            //convert into array and reverse the order
            var arrayData = Object.values(monthData);
            setForecast(arrayData);
    
        } else {setForecast(false)}
        
    },[progress6])


    //Get Yearly Learn Values
    useEffect(()=>{

        if (learnProgress != null && learnProgress.length > 0){
            let arrayData3 = learnProgress.map((i)=>{                
                let current = {}
                current.Radical = 0
                current.Hanzi = 0
                current.Voacb = 0
                
                current.month = i[0].month;
                current.year = i[0].year;
                current.Radical = i.filter(x => x.Radical).reduce((x,y)=>{return x + y.Radical}, current.Radical);
                current.Hanzi = i.filter(x => x.Hanzi).reduce((x,y)=>{return x + y.Hanzi}, current.Hanzi);
                current.Vocab = i.filter(x => x.Vocab).reduce((x,y)=>{return x + y.Vocab}, current.Voacb);
    
    
                return current;
                                 
            })
    
            let yearData = [];
            yearData = _.groupBy(arrayData3, function(b){ return b.year});
            yearData = Object.values(yearData);
            setLearnProgressYear(yearData.reverse());

        }
        
        
    },[learnProgress])

    
    //Get Yearly Review Values
    useEffect(()=>{

        if (reviewProgress != null && reviewProgress.length > 0){
            let arrayData4 = reviewProgress.map((i)=>{                
                let current = {}
                current.Radical = 0
                current.Hanzi = 0
                current.Voacb = 0
                
                current.month = i[0].month;
                current.year = i[0].year;
                current.Radical = i.filter(x => x.Radical).reduce((x,y)=>{return x + y.Radical}, current.Radical);
                current.Hanzi = i.filter(x => x.Hanzi).reduce((x,y)=>{return x + y.Hanzi}, current.Hanzi);
                current.Vocab = i.filter(x => x.Vocab).reduce((x,y)=>{return x + y.Vocab}, current.Voacb);
    
    
                return current;
                                 
            })
    
            let yearData = [];
            yearData = _.groupBy(arrayData4, function(b){ return b.year});
            yearData = Object.values(yearData);
            setReviewProgressYear(yearData.reverse());

        }
        
        
    },[reviewProgress])
    

    //Control loading
    useEffect(()=>{
        if (learnCount != null && memoCount !=null && learnProgress != null && reviewProgress !=null && learnProgressYear !=null && reviewProgressYear != null){
            setIsLoading(false);
            props.handleNavBar(false);
        }        
        
    },[learnProgress, learnCount, reviewProgress, reviewCount, memoCount, forecast])





    function handleLearnIndex(value){
        setLearnIndex(learnIndex => learnIndex + value);
        setLearnBarIndex(learnBarIndex => learnBarIndex - value)
    }

    function handleLearnIndexYear(value){
        setLearnIndexYear(learnIndexYear => learnIndexYear + value);
        setLearnBarIndexYear(learnBarIndexYear => learnBarIndexYear - value)
    }

    function handleReviewIndex(value){
        setReviewIndex(reviewIndex => reviewIndex + value);
        setReviewBarIndex(reviewBarIndex => reviewBarIndex - value);
    }

    function handleReviewIndexYear(value){
        setReviewIndexYear(reviewIndexYear => reviewIndexYear + value);
        setReviewBarIndexYear(reviewBarIndexYear => reviewBarIndexYear - value);
    }

    
    function handleForecastIndex(value){
        setForecastIndex(forecastIndex => forecastIndex + value);
        setForecastBarIndex(forecastBarIndex => forecastBarIndex + value)
    }
    

    function handleSelection(event){
        if (event.target.value === "Level"){
            setSort("Yearly")
        }
        if (event.target.value === "Forecast"){
            setSort("Monthly")
        }         
        setSelection(event.target.value);
        setBreakdown([]);
    }

    function handleSort(event){
        setSort(event.target.value);
        setBreakdown([]);
    }

    function handleBar(event){
        if (event && event.activePayload){            
            setBreakdown(event.activePayload[0].payload);
        }
      
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
                <div className="pb-2">{label}</div>
                {payload.map((x, index)=> {
                    let c = x.color;
                    return <div key={index} className="pb-2" style={{color: c}}>{`${x.name} : ${x.value}`}</div>
                })}

                <div className="pb-2">Earliest review: {payload[0].payload.time}</div>
            </div>
          );
        }
      
        return null;
      };


    return(
        isLoading ? <LoadingScreen/> :
        <div id="my-progress">
            <Container fluid className="py-lg-5 py-3 mb-3 container-custom fadeIn">
                <motion.div animate={{ x: [-100, 0  ] }}
                transition={{ ease: "easeOut", duration: 0.3 }}>
                    <Row className="bg-white rounded shadow-sm p-md-5 pt-4 pb-5"><Col>
                    <Row>
                        <Col className="progress-header">
                        {(selection === "Learn" || selection === "Review") && <span style={{fontSize: "1.8em", fontWeight:500}} className="fc-primary progress-header-title">{selection} Progress</span>}
                        {selection === "Forecast" && <span style={{fontSize: "1.8em", fontWeight:500}} className="fc-primary progress-header-title">Review {selection}</span> }
                        <div className="input-group progress-dropdown " >
                                <select className="custom-select" name="value" onChange={handleSelection}>
                                <option value="Learn">Learn</option>
                                <option value="Review">Review</option>
                                {/* <option value="Level">Level</option> */}
                                <option value="Forecast">Forecast</option>
                                </select>
                                <select disabled={(selection === "Level" || selection === "Forecast") ? true : false}  className="custom-select" value={sort} name="value" onChange={handleSort}>
                                <option value="Monthly">Sort Monthly</option>
                                <option value="Yearly">Sort Yearly</option>
                                </select>
                            </div>

                        </Col>
                    </Row>    

                    {/* ------------------Learn Records------------------- */}

                   {selection === "Learn" && <>
                   
                   
                    
                    {sort === "Monthly" && <>
                        <Row className="mt-5">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    {learnIndex > -learnProgress?.length+1 ? <i className="bi bi-chevron-left progress-button" onClick={()=> {handleLearnIndex(-1)}}>Back</i> : <i className="bi bi-chevron-left progress-button-disable" >Back</i>}
                                    <span className="px-5 font-weight-bold">{DateTime.now().plus({month: learnIndex}).toLocaleString({month: 'long', year: 'numeric'})}</span>
                                    {learnIndex < 0 ? <span className="progress-button" onClick={()=> {handleLearnIndex(1)}}><em>Next</em><i className="bi bi-chevron-right"/></span> : <span className="progress-button-disable"><em>Next</em><i className="bi bi-chevron-right"/></span>}
                                </div>
                                <br/>
                                {learnProgress.length > 0 ?  <div className="text-center"> 
                                <Badge className="mx-1" pill variant="light">
                                Radical: {learnProgress[learnBarIndex]?.filter(x => x.Radical).reduce((x,y)=>{return x + y.Radical}, monthRad)}
                                </Badge> 
                                <Badge className="mx-1" pill variant="light">
                                Hanzi: {learnProgress[learnBarIndex]?.filter(x => x.Hanzi).reduce((x,y)=>{return x + y.Hanzi}, monthHan)} 
                                </Badge>
                                <Badge className="mx-1" pill variant="light">
                                Vocab: {learnProgress[learnBarIndex]?.filter(x => x.Vocab).reduce((x,y)=>{return x + y.Vocab}, monthVoc)}
                                </Badge>
                                </div>: 
                                
                                <div className="text-center">You have no learn records</div>}                        

                                </Col>
                            </Row>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={learnProgress && learnProgress[learnBarIndex]}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: 0,
                                        bottom: 5
                                    }}
                                    onClick={handleBar}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis/>
                                    <Legend/>
                                    <BToolTip/>
                                    <Bar dataKey="Radical" stackId="a" fill="#46C793" />
                                    <Bar dataKey="Hanzi" stackId="a" fill="#20A2BA" />
                                    <Bar dataKey="Vocab" stackId="a" fill="#BA2969" />
                                </BarChart>
                            </ResponsiveContainer>
                            </>}
                            
                        {sort === "Yearly" && <>

                        <Row className="mt-5">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    {learnIndexYear > -learnProgressYear?.length+1 ? <i className="bi bi-chevron-left progress-button" onClick={()=> {handleLearnIndexYear(-1)}}>Back</i> : <i className="bi bi-chevron-left progress-button-disable" >Back</i>}
                                    <span className="px-5 font-weight-bold">{DateTime.now().plus({year: learnIndexYear}).toLocaleString({year: 'numeric'})}</span>
                                    {learnIndexYear < 0 ? <span className="progress-button" onClick={()=> {handleLearnIndexYear(1)}}><em>Next</em><i className="bi bi-chevron-right"/></span> : <span className="progress-button-disable"><em>Next</em><i className="bi bi-chevron-right"/></span>}
                                </div>
                                <br/>
                                {learnProgress.length > 0 ?  <div className="text-center"> 
                                <Badge className="mx-1" pill variant="light">
                                Radical: {learnProgressYear[learnBarIndexYear]?.filter(x => x.Radical).reduce((x,y)=>{return x + y.Radical}, monthRad)}
                                </Badge> 
                                <Badge className="mx-1" pill variant="light">
                                Hanzi: {learnProgressYear[learnBarIndexYear]?.filter(x => x.Hanzi).reduce((x,y)=>{return x + y.Hanzi}, monthHan)} 
                                </Badge>
                                <Badge className="mx-1" pill variant="light">
                                Vocab: {learnProgressYear[learnBarIndexYear]?.filter(x => x.Vocab).reduce((x,y)=>{return x + y.Vocab}, monthVoc)}
                                </Badge>
                                </div>: 
                                
                                <div className="text-center">You have no learn records</div>}                        

                                </Col>
                            </Row>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={learnProgressYear && learnProgressYear[learnBarIndexYear]}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: 0,
                                        bottom: 5
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" reversed={true} />
                                    <YAxis/>
                                    <Legend/>
                                    <BToolTip/>
                                    <Bar dataKey="Radical" stackId="a" fill="#46C793" />
                                    <Bar dataKey="Hanzi" stackId="a" fill="#20A2BA" />
                                    <Bar dataKey="Vocab" stackId="a" fill="#BA2969" />
                                </BarChart>
                            </ResponsiveContainer>

                        </>}
                    
                    </>}
                    
                    {/* ------------------Review Records------------------- */}

                    {selection === "Review" && <>
                    
                       {sort === "Monthly" && <>
                            <Row className="mt-5">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    {reviewIndex > -reviewProgress?.length+1 ? <i className="bi bi-chevron-left progress-button" onClick={()=> {handleReviewIndex(-1)}}>Back</i> : <i className="bi bi-chevron-left progress-button-disable" >Back</i>}
                                    <span className="px-5 font-weight-bold">{DateTime.now().plus({month: reviewIndex}).toLocaleString({month: 'long', year: 'numeric'})}</span>
                                    {reviewIndex < 0 ? <span className="progress-button" onClick={()=> {handleReviewIndex(1)}}><em>Next</em><i className="bi bi-chevron-right"/></span> : <span className="progress-button-disable"><em>Next</em><i className="bi bi-chevron-right"/></span>}
                                </div>
                                <br/>
                                {reviewProgress.length > 0 ?  <div className="text-center">
                                <Badge className="mx-1" pill variant="light">
                                Radical: {reviewProgress[reviewBarIndex]?.filter(x => x.Radical).reduce((x,y)=>{return x + y.Radical}, monthRad)}
                                </Badge>
                                <Badge className="mx-1" pill variant="light">
                                Hanzi: {reviewProgress[reviewBarIndex]?.filter(x => x.Hanzi).reduce((x,y)=>{return x + y.Hanzi}, monthHan)} 
                                </Badge>
                                <Badge className="mx-1" pill variant="light">
                                Vocab: {reviewProgress[reviewBarIndex]?.filter(x => x.Vocab).reduce((x,y)=>{return x + y.Vocab}, monthVoc)} 
                                </Badge>    
                                </div>: <div className="text-center">You have no review records</div>}                        

                                </Col>
                            </Row>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={reviewProgress && reviewProgress[reviewBarIndex]}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: 0,
                                        bottom: 5
                                    }}
                                    onClick={handleBar}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis/>
                                    <Legend/>
                                    <BToolTip/>
                                    <Bar dataKey="Radical" stackId="a" fill="#46C793" />
                                    <Bar dataKey="Hanzi" stackId="a" fill="#20A2BA" />
                                    <Bar dataKey="Vocab" stackId="a" fill="#BA2969" />
                                </BarChart>
                            </ResponsiveContainer>
                        </>}


                        {sort === "Yearly" && <>
                        <Row className="mt-5">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    {reviewIndexYear > -reviewProgressYear?.length+1 ? <i className="bi bi-chevron-left progress-button" onClick={()=> {handleReviewIndexYear(-1)}}>Back</i> : <i className="bi bi-chevron-left progress-button-disable" >Back</i>}
                                    <span className="px-5 font-weight-bold">{DateTime.now().plus({year: reviewIndexYear}).toLocaleString({year: 'numeric'})}</span>
                                    {reviewIndexYear < 0 ? <span className="progress-button" onClick={()=> {handleReviewIndexYear(1)}}><em>Next</em><i className="bi bi-chevron-right"/></span> : <span className="progress-button-disable"><em>Next</em><i className="bi bi-chevron-right"/></span>}
                                </div>
                                <br/>
                                {reviewProgress.length > 0 ?  <div className="text-center"> 
                                <Badge className="mx-1" pill variant="light">
                                Radical: {reviewProgressYear[reviewBarIndexYear]?.filter(x => x.Radical).reduce((x,y)=>{return x + y.Radical}, monthRad)}
                                </Badge> 
                                <Badge className="mx-1" pill variant="light">
                                Hanzi: {reviewProgressYear[reviewBarIndexYear]?.filter(x => x.Hanzi).reduce((x,y)=>{return x + y.Hanzi}, monthHan)} 
                                </Badge>
                                <Badge className="mx-1" pill variant="light">
                                Vocab: {reviewProgressYear[reviewBarIndexYear]?.filter(x => x.Vocab).reduce((x,y)=>{return x + y.Vocab}, monthVoc)}
                                </Badge>
                                </div>: 
                                
                                <div className="text-center">You have no learn records</div>}                        

                                </Col>
                            </Row>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={reviewProgressYear && reviewProgressYear[reviewBarIndexYear]}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: 0,
                                        bottom: 5
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" reversed={true} />
                                    <YAxis/>
                                    <Legend/>
                                    <BToolTip/>
                                    <Bar dataKey="Radical" stackId="a" fill="#46C793" />
                                    <Bar dataKey="Hanzi" stackId="a" fill="#20A2BA" />
                                    <Bar dataKey="Vocab" stackId="a" fill="#BA2969" />
                                </BarChart>
                            </ResponsiveContainer>
                            
                        </>}              
                    </>}

                    {/* ------------------Forecast------------------- */}
                    {selection === "Forecast" && <>

                    <Row className="mt-5">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    {forecastIndex > 0 ? <i onClick={()=> {handleForecastIndex(-1)}} className="bi bi-chevron-left progress-button">Back</i> : <i className="bi bi-chevron-left progress-button-disable" >Back</i>}
                                    <span className="px-5 font-weight-bold">{DateTime.now().plus({month: forecastIndex}).toLocaleString({month: 'long', year: 'numeric'})}</span>
                                    {forecastIndex < forecast?.length-1 ? <span className="progress-button" onClick={()=> {handleForecastIndex(1)}}><em>Next</em><i className="bi bi-chevron-right"/></span> : <span className="progress-button-disable"><em>Next</em><i className="bi bi-chevron-right"/></span>}

                                </div>
                                <br/>
                                {forecast.length > 0 ?  <div className="text-center"> 
                                <Badge className="mx-1" pill variant="light">
                                Radical: {forecast[forecastBarIndex]?.filter(x => x.Radical).reduce((x,y)=>{return x + y.Radical}, monthRad)}
                                </Badge> 
                                <Badge className="mx-1" pill variant="light">
                                Hanzi: {forecast[forecastBarIndex]?.filter(x => x.Hanzi).reduce((x,y)=>{return x + y.Hanzi}, monthHan)} 
                                </Badge>
                                <Badge className="mx-1" pill variant="light">
                                Vocab: {forecast[forecastBarIndex]?.filter(x => x.Vocab).reduce((x,y)=>{return x + y.Vocab}, monthVoc)}
                                </Badge>
                                </div>: 
                                
                                <div className="text-center">You have upcoming reviews</div>}                        

                                </Col>
                            </Row>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={forecast && forecast[forecastBarIndex]}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: 0,
                                        bottom: 5
                                    }}
                                    onClick={handleBar}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis/>
                                    <Legend/>
                                    <BToolTip content={<CustomTooltip />}/>
                                    <Bar dataKey="Radical" stackId="a" fill="#46C793" />
                                    <Bar dataKey="Hanzi" stackId="a" fill="#20A2BA" />
                                    <Bar dataKey="Vocab" stackId="a" fill="#BA2969" />
                                </BarChart>
                            </ResponsiveContainer>

                    </>}
                    
                </Col></Row>
                
                {/* ------------------Breakdown------------------- */}                                

                {(selection === "Learn" || selection === "Review" || selection === "Forecast") && 
                
                breakdown.name && 
                <motion.div initial={{height: 0, opacity:0}} animate={{height: "auto", opacity: 1}}>
                <Row className="bg-white rounded shadow-sm p-md-5 py-4 mt-4">
                    <Col>               
                            <div className="d-flex justify-content-between">
                                <h5>Breakdown {breakdown?.name}</h5>                            
                                {selection === "Review" && !breakdown?.empty && <div className="text-center d-flex flex-column"><span style={{fontSize: "1.5em", lineHeight: 1.2, fontWeight: 600}}>{Math.round((breakdown?.correct/(breakdown?.correct + breakdown?.wrong)*100))}%</span><small>Accuracy</small></div>}
                            </div>
                            
                            <div className="progress-breakdown">                               
                                <Row className="mt-4">

                                <div className="progress-items-col">
                                {breakdown?.radItem?.length > 0 &&                               
                                breakdown.radItem.map((item, index)=> {
                                    return <motion.div key={index} initial={{opacity: 0}} animate={{opacity: 1}}><Block key={index} itemInfo={item}/></motion.div>
                                })}

                                {breakdown?.hanItem?.length > 0 && 
                                breakdown.hanItem.map((item, index)=> {
                                    return <motion.div key={index} initial={{opacity: 0}} animate={{opacity: 1}}><Block key={index} itemInfo={item}/></motion.div>
                                })}

                                {breakdown?.vocItem?.length > 0 && 
                                breakdown.vocItem.map((item, index)=> {
                                    return <motion.div key={index} initial={{opacity: 0}} animate={{opacity: 1}}><Block key={index} itemInfo={item}/></motion.div>
                                })}



                                {breakdown?.empty && <div className="fc-grey w-100 py-3 text-center">No records</div>}
                                </div>
                                </Row>
                               

                            </div>
                        </Col>
                    </Row></motion.div>}

                {/* ------------------Progress Bar------------------- */}                                     

                    <Row  className="bg-white rounded shadow-sm p-md-5 py-4 mt-4">
                        <Col>
                        <h5>Overall Progress</h5>
                        <br/>
                        <div>

                     
                            <div className="d-flex justify-content-between">
                                <span>Radical</span> 
                                    <div>
                                    <Badge className="mx-1" pill variant="light">M: {memoCount?.radical}</Badge>
                                    <Badge className="mx-1" pill variant="light">R: {reviewCount?.radical}</Badge>
                                    <Badge className="mx-1" pill variant="light">L: {learnCount?.radical}</Badge>
                                    <Badge className="mx-1" pill variant="light">Total: {learnCount?.total[1].count}</Badge>
                                    </div>
                            </div>
                                <ProgressBar >            
                                    <OverlayTrigger overlay={<Tooltip>Memorized: {Math.round((memoCount?.radical/learnCount?.total[1].count)*100)}%</Tooltip>}>
                                        <ProgressBar max={learnCount?.total[1].count} now={memoCount.radical} isChild={true} className="color-light-dark-green" key={1} />
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip>Reviewed {Math.round((reviewCount?.radical/learnCount?.total[1].count)*100)}%</Tooltip>}> 
                                        <ProgressBar max={learnCount?.total[1].count} now={reviewCount.radical-memoCount.radical} isChild={true} className="color-medium-green" key={2} />
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip>Learnt {Math.round((learnCount?.radical/learnCount?.total[1].count)*100)}%</Tooltip>}> 
                                        <ProgressBar max={learnCount?.total[1].count} now={learnCount.radical-memoCount.radical-reviewCount.radical} isChild={true} className="color-light-green" key={3} />
                                    </OverlayTrigger>
                                </ProgressBar>
                             <br/>
                             <div className="d-flex justify-content-between">
                                <span>Hanzi</span> 
                                    <div>
                                    <Badge className="mx-1" pill variant="light">M: {memoCount?.hanzi}</Badge>
                                    <Badge className="mx-1" pill variant="light">R: {reviewCount?.hanzi}</Badge>
                                    <Badge className="mx-1" pill variant="light">L: {learnCount?.hanzi}</Badge>
                                    <Badge className="mx-1" pill variant="light">Total: {learnCount?.total[0].count}</Badge>
                                    </div>
                            </div>
                                <ProgressBar >            
                                    <OverlayTrigger overlay={<Tooltip>Memorized: {Math.round((memoCount?.hanzi/learnCount?.total[0].count)*100)}%</Tooltip>}>
                                        <ProgressBar max={learnCount?.total[0].count} now={memoCount.hanzi} isChild={true} className="color-light-dark-green" key={1} />
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip>Reviewed {Math.round((reviewCount?.hanzi/learnCount?.total[0].count)*100)}%</Tooltip>}> 
                                        <ProgressBar max={learnCount?.total[0].count} now={reviewCount.hanzi-memoCount.hanzi} isChild={true} className="color-medium-green" key={2} />
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip>Learnt {Math.round((learnCount?.hanzi/learnCount?.total[0].count)*100)}%</Tooltip>}> 
                                        <ProgressBar max={learnCount?.total[0].count} now={learnCount.hanzi-memoCount.hanzi-reviewCount.hanzi} isChild={true} className="color-light-green" key={3} />
                                    </OverlayTrigger>
                                </ProgressBar>
                             <br/>
                             <div className="d-flex justify-content-between">
                                <span>Vocabulary</span> 
                                    <div>
                                    <Badge className="mx-1" pill variant="light">M: {memoCount?.vocab}</Badge>
                                    <Badge className="mx-1" pill variant="light">R: {reviewCount?.vocab}</Badge>
                                    <Badge className="mx-1" pill variant="light">L: {learnCount?.vocab}</Badge>
                                    <Badge className="mx-1" pill variant="light">Total: {learnCount?.total[2].count}</Badge>
                                    </div>
                            </div>
                                <ProgressBar >            
                                    <OverlayTrigger overlay={<Tooltip>Memorized: {Math.round((memoCount?.vocab/learnCount?.total[2].count)*100)}%</Tooltip>}>
                                        <ProgressBar max={learnCount?.total[2].count} now={memoCount.vocab} isChild={true} className="color-light-dark-green" key={1} />
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip>Reviewed {Math.round((reviewCount?.vocab/learnCount?.total[2].count)*100)}%</Tooltip>}> 
                                        <ProgressBar max={learnCount?.total[2].count} now={reviewCount.vocab-memoCount.vocab} isChild={true} className="color-medium-green" key={2} />
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip>Learnt {Math.round((learnCount?.vocab/learnCount?.total[2].count)*100)}%</Tooltip>}> 
                                        <ProgressBar max={learnCount?.total[2].count} now={learnCount.vocab-memoCount.vocab-reviewCount.vocab} isChild={true} className="color-light-green" key={3} />
                                    </OverlayTrigger>
                                </ProgressBar>
                             <br/>

                             <div className="pt-3 d-flex justify-content-end align-items-center"><span className="square mx-1 color-light-dark-green"/> <small  className="mr-1"><b>M</b>emorized</small> <span className="square mx-1 color-medium-green"/> <small  className="mr-1"><b>R</b>eviewed</small><span className="square mr-1 color-light-green"/><small><b>L</b>earnt</small> </div>
                   
                            
                        </div>
                        </Col>
                    </Row>

                    {/* ------------------Level Records------------------- */}

                    





           
                </motion.div>
            </Container>
        </div>
    )

}

export default MyProgress;