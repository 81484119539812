import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";
import {Container, Row, Col, Button, ProgressBar, OverlayTrigger, Tooltip, Tab, Tabs} from "react-bootstrap"
import studentStudy from "../images/student-studying1.svg"
import { motion } from "framer-motion"
import BlockMinus from "./BlockMinus";
import WaveLearn from "../images/wave-learn.svg"
import WaveReview from "../images/wave-review.svg"
import instance from "../axiosConfig";

const { DateTime } = require("luxon");


function Dashboard(props){    
  const history = useHistory();
  const dt = DateTime.local()
  const [userInfo, setUserInfo] = useState();
  const [secondEffect,setSecondEffect] = useState(false);
  const [isLoading, setIsLoading] =  useState(true)
  const [learnItems, setLearnItems] = useState(0);
  const [reviewItems, setReviewItems] = useState(0);
  const [upComing, setUpComing] = useState();
  // const [learnProgress, setLearnProgress] = useState();
  const [upComingRev, setUpComingRev] = useState([
    {day: dt.weekday, count: 0, month: dt.toLocaleString({ month: 'short'}), date: dt.toLocaleString({day: 'numeric'})},
    {day: dt.plus({days: 1}).weekday, count: 0, date: dt.plus({days: 1}).toLocaleString({day: 'numeric' }), month: dt.plus({days: 1}).toLocaleString({month: 'short' })},
    {day: dt.plus({days: 2}).weekday, count: 0, date: dt.plus({days: 2}).toLocaleString({day: 'numeric' }), month: dt.plus({days: 2}).toLocaleString({month: 'short' })},
    {day: dt.plus({days: 3}).weekday, count: 0, date: dt.plus({days: 3}).toLocaleString({day: 'numeric' }), month: dt.plus({days: 3}).toLocaleString({month: 'short' })},
    {day: dt.plus({days: 4}).weekday, count: 0, date: dt.plus({days: 4}).toLocaleString({day: 'numeric' }), month: dt.plus({days: 4}).toLocaleString({month: 'short' })},
    {day: dt.plus({days: 5}).weekday, count: 0, date: dt.plus({days: 5}).toLocaleString({day: 'numeric' }), month: dt.plus({days: 5}).toLocaleString({month: 'short' })},
    {day: dt.plus({days: 6}).weekday, count: 0, date: dt.plus({days: 6}).toLocaleString({day: 'numeric' }), month: dt.plus({days: 5}).toLocaleString({month: 'short' })}])
  const [revWeekTotal, setRevWeekTotal] = useState();
  const [itemCount, setItemCount] = useState({
    radicalAll: 0,
    hanziAll: 0,
    vocabAll: 0,
    radicalLevel: 0,
    hanziLevel: 0,
    vocabLevel: 0
  });
  const [userCount, setUserCount] = useState({
    radicalAll: 0,
    hanziAll: 0,
    vocabAll: 0,
    radicalLevel: 0,
    hanziLevel: 0,
    vocabLevel: 0,
    radicalAllT: 0,
    hanziAllT: 0,
    vocabAllT: 0,
    radicalLevelT: 0,
    hanziLevelT: 0,
    vocabLevelT: 0,
    
  });
  const [chartData, setChartData] = useState({
    todayCount: 0,
    nextReviewTime: null
  });

  const currentRadical = Math.floor((userCount.radicalLevel/itemCount.radicalLevel) * 100)
  const currentHanzi = Math.floor((userCount.hanziLevel/itemCount.hanziLevel) * 100)
  const currentVocab = Math.floor((userCount.vocabLevel/itemCount.vocabLevel) * 100)
  const currentRadicalT = Math.floor((userCount.radicalLevelT/itemCount.radicalLevel) * 100)
  const currentHanziT = Math.floor((userCount.hanziLevelT/itemCount.hanziLevel) * 100)
  const currentVocabT = Math.floor((userCount.vocabLevelT/itemCount.vocabLevel) * 100)

  const overallRadical = Math.floor((userCount.radicalAll/itemCount.radicalAll) * 100)
  const overallHanzi = Math.floor((userCount.hanziAll/itemCount.hanziAll) * 100)
  const overallVocab = Math.floor((userCount.vocabAll/itemCount.vocabAll) * 100)
  const overallRadicalT = Math.floor((userCount.radicalAllT/itemCount.radicalAll) * 100)
  const overallHanziT = Math.floor((userCount.hanziAllT/itemCount.hanziAll) * 100)
  const overallVocabT = Math.floor((userCount.vocabAllT/itemCount.vocabAll) * 100)

 
  useEffect(() => {
    if(props.userInfo && props.isLoggedIn){
      setUserInfo(props.userInfo);   
      setSecondEffect(true);
    } else if (props.isLoggedIn === false){
      history.push('/login');
    }
  }, [props.userInfo, props.isLoggedIn]);
  
  useEffect(()=>{
    if (secondEffect){
      instance.get("/api/users/user?chart=true&timezone=" + Intl.DateTimeFormat().resolvedOptions().timeZone)
      .then(response => {
        const {currentCount, futureCount, todayCount} = response.data;

        if (currentCount.length > 0){
          setReviewItems(currentCount[0].count);
        }

        if (todayCount.length >0){
          setChartData((prevValue)=>{return {...prevValue,todayCount: todayCount[0].count, nextReviewTime: todayCount[0].nextReviewTime}})
          if (DateTime.fromISO(todayCount[0].isoDate) < dt){
            let replace = upComingRev[0];
          replace.count = todayCount[0].count
          replace.earliestReview = "Now"
          let newUpComing=[...upComingRev];
          newUpComing.splice(0,1,replace);
          setUpComingRev(newUpComing);
          } else { 
          let replace = upComingRev[0];
          replace.count = todayCount[0].count
          replace.earliestReview = todayCount[0].nextReviewTime.slice(13, 18)
          let newUpComing=[...upComingRev];
          newUpComing.splice(0,1,replace);
          setUpComingRev(newUpComing);}
        }
  
        if (futureCount.length > 0){
          if (todayCount.length === 0){
            setChartData((prevValue)=>{return {...prevValue, nextReviewTime: futureCount[0]._id + " " + futureCount[0].nextReviewTime}})
          }

          futureCount.forEach(item =>{
            let replace = upComingRev.find(({day}) => day === parseInt(item._id.slice(0,1)))
            let index = upComingRev.findIndex(({day})=> day === parseInt(item._id.slice(0,1)))
            replace.count = item.count
            replace.earliestReview = item.nextReviewTime.slice(0,5)
            let newUpComing=[...upComingRev];
            newUpComing.splice(index,1,replace);
            setUpComingRev(newUpComing);
          });
        }
      })
      .then(response => {
        instance.get("/api/items?learnqueuenolimit=true")
        .then(response => {
          const data = response.data;
          setLearnItems(data.length); 
          setUpComing(data);
          // let total = upComingRev.reduce((a,b) => ({count: a.count + b.count}))
          let total = upComingRev.map((item)=> item.count);
          let max = Math.max(...total);
          switch(true) {
            case max >= 6400:
              setRevWeekTotal(12800);   
              break;
            case max >= 3200:
              setRevWeekTotal(6400);   
              break;
            case max >= 1600:
              setRevWeekTotal(3200);   
              break;
            case max >= 800:
              setRevWeekTotal(1600);   
              break;
            case max >= 400:
              setRevWeekTotal(800);   
              break;
            case max >= 200:
              setRevWeekTotal(400);   
              break;
            case max >= 100:
              setRevWeekTotal(200);   
              break;
            case max >= 50:
              setRevWeekTotal(100);   
              break;
            case max >= 25:
              setRevWeekTotal(50);   
              break;
            default:
              setRevWeekTotal(25);
          }
                
        })
        .catch((err) => {
          if (err.response.data === "Unauthorized"){
            window.location.reload()
          } else {
              console.log(err);
          }
        });        
      })
      .then(response => {
        instance.get("/api/items?count=true")
        .then(response => {
     
          const {levelCount, totalCount} = response.data;
          const [hanziAll, radicalAll, vocabAll] = totalCount
          const [hanziLevel, radicalLevel, vocabLevel] = levelCount
  
          setItemCount({      
  
            radicalAll: radicalAll.count,
            hanziAll: hanziAll.count,
            vocabAll: vocabAll.count,
            radicalLevel: radicalLevel.count,
            hanziLevel: hanziLevel.count,
            vocabLevel: vocabLevel.count
            
          });        
        })
        .catch((err) => {
          if (err.response.data === "Unauthorized"){
            window.location.reload()
        } else {
            console.log(err);
        }
        }); 
      })
      .then(response => {
        instance.get("/api/users/user?count=true")
        .then(response => {
          const {levelCount, totalCount, thresholdCountLevel, thresholdCountTotal} = response.data;
          const radicalAll = totalCount.find(item => item._id === "Radical");
          const hanziAll = totalCount.find(item => item._id === "Hanzi");
          const vocabAll = totalCount.find(item => item._id === "Vocab");
          const radicalLevel = levelCount.find(item => item._id === "Radical")
          const hanziLevel = levelCount.find(item => item._id === "Hanzi")
          const vocabLevel = levelCount.find(item => item._id === "Vocab")
          const radicalAllT = thresholdCountTotal.find(item => item._id === "Radical");
          const hanziAllT = thresholdCountTotal.find(item => item._id === "Hanzi");
          const vocabAllT = thresholdCountTotal.find(item => item._id === "Vocab");
          const radicalLevelT = thresholdCountLevel.find(item => item._id === "Radical")
          const hanziLevelT = thresholdCountLevel.find(item => item._id === "Hanzi")
          const vocabLevelT = thresholdCountLevel.find(item => item._id === "Vocab")
          
          
          
          if (hanziAll){
            setUserCount((prevValue =>{
            return {
              ...prevValue,
              hanziAll: hanziAll.count
            };
          }));}
          if (vocabAll){setUserCount((prevValue =>{
            return {
              ...prevValue,
              vocabAll: vocabAll.count
            };
          }));}
          if (radicalAll){setUserCount((prevValue =>{
            return {
              ...prevValue,
              radicalAll: radicalAll.count
            };
          }));}
          if (hanziLevel){
            setUserCount((prevValue =>{
            return {
              ...prevValue,
              hanziLevel: hanziLevel.count
            };
          }));}
          if (vocabLevel){setUserCount((prevValue =>{
            return {
              ...prevValue,
              vocabLevel: vocabLevel.count
            };
          }));}
          if (radicalLevel){setUserCount((prevValue =>{
            return {
              ...prevValue,
              radicalLevel: radicalLevel.count
            };
          }));}
  
  
  
          if (hanziAllT){
            setUserCount((prevValue =>{
            return {
              ...prevValue,
              hanziAllT: hanziAllT.count
            };
          }));}
          if (vocabAllT){setUserCount((prevValue =>{
            return {
              ...prevValue,
              vocabAllT: vocabAllT.count
            };
          }));}
          if (radicalAllT){setUserCount((prevValue =>{
            return {
              ...prevValue,
              radicalAllT: radicalAllT.count
            };
          }));}
          if (hanziLevelT){
            setUserCount((prevValue =>{
            return {
              ...prevValue,
              hanziLevelT: hanziLevelT.count
            };
          }));}
          if (vocabLevelT){setUserCount((prevValue =>{
            return {
              ...prevValue,
              vocabLevelT: vocabLevelT.count
            };
          }));}
          if (radicalLevelT){setUserCount((prevValue =>{
            return {
              ...prevValue,
              radicalLevelT: radicalLevelT.count
            };
          }));}  
        }).then(response =>{
          props.handleNavBar(false);
          setIsLoading(false);
        })
        .catch((err) => { if (err.response.data === "Unauthorized"){
          window.location.reload()
      } else {
          console.log(err);
      };}); 
      })
      .catch((err) => { if (err.response.data === "Unauthorized"){
        window.location.reload()
    } else {
        console.log(err);
    };});
    }
  
  },[secondEffect]);

   //Functions
  function linkOutLearn(){
    history.push("/learn");   
  }
  
  function linkOutReview(){
    if(reviewItems > 0){history.push("/review");}
  }

  // function formatDate(){
  //   if (chartData.nextReviewTime){   
  //     var date1 = dt;
  //     var todayDate = date1.set({hour: 0, minute: 0, second: 0, millisecond:0});
  //     var reviewDate = chartData.nextReviewTime.slice(0,10);
  //     var compareDate = DateTime.fromISO(reviewDate);

  //     if (+todayDate === +compareDate){
  //       return("Today " + chartData.nextReviewTime.slice(13, 18))
  //     } else {
  //       return( compareDate.day+ " "+ compareDate.monthShort + " " + chartData.nextReviewTime.slice(13, 18))
  //     }
  //   } else {return "None"}
  // }

  return(
  
  !isLoading && userInfo ?  
  
  <section id="dashboard">
  
  <Container fluid className="pt-3 container-custom">
  <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}>

    {/* ----Dashboard Banner---- */}
    
    <Row id="dashboard-banner" className="rounded shadow-sm mx-1">
    <Col>
    <h2>Hello, <span className="fc-primary font-weight-bold">{userInfo.data.username}</span></h2>
    {/* <p className="h5  font-italic">大处着眼，小处着手</p> */}
    <p className="h5">Level {userInfo.data.level}</p>
    </Col>
    <Col className="d-none d-sm-block"><p><img src={studentStudy} alt="dashboard" id="dashboard-banner-img" className="float-right img-fluid"/></p>
    </Col>
    </Row>
    
    <Row className="mt-4">
      <Col xs={12} sm={6} className="mb-4 mb-sm-0">
{          userInfo.data.pause ? 
        <div className="dashboard-banner-inactive">
        <i style={{fontSize: "4em"}} className="bi bi-exclamation-circle"/>Learn queue is paused
        </div> :
        learnItems > 0 ?
        <div style={{backgroundImage: `url(${WaveLearn})`}} onClick={linkOutLearn} className="dashboard-banner-card shadow-sm">
        <span style={{fontSize: "5em"}}>学</span>
        <span className="dashboard-banner-text">{learnItems}</span>
        <span>in learn queue</span>          
        </div> :
        <div className="dashboard-banner-inactive">
        <i style={{fontSize: "4em"}} className="bi bi-check-circle"/>Your learn queue is cleared! Hooray!
        </div>}
      </Col>
      <Col xs={12} sm={6}>
{          userInfo.data.pause ? 
        <div className="dashboard-banner-inactive">
        <i style={{fontSize: "4em"}} className="bi bi-exclamation-circle"/>Review queue is paused
        </div> :
        reviewItems > 0 ? 
        <div style={{backgroundImage: `url(${WaveReview})`}}  onClick={linkOutReview} className="dashboard-banner-card shadow-sm">
        <span style={{fontSize: "5em"}}>习</span>    
        <span className="dashboard-banner-text">{reviewItems}</span>
        <span>in review queue</span>        
        </div> :
        <div className="dashboard-banner-inactive">
        <i style={{fontSize: "4em"}} className="bi bi-check-circle"/>Your review queue is cleared! Hooray!
        </div>}
      </Col>
    </Row>

    <Row>
      <Col>
        <div className="rounded mt-4 pt-4 shadow-sm" id="dashboard-current-progress">
          <Tabs defaultActiveKey="current-progress" id="uncontrolled-tab-example" className="ml-2 mb-3">
            <Tab eventKey="current-progress" title={<span className="dashboard-h5">Current Progress</span>}>
            <div className="px-4 pb-4">
              <div className="d-flex justify-content-between"><span>Radical</span> <span>{itemCount.radicalLevel}</span></div>
              <ProgressBar>            
                <OverlayTrigger overlay={<Tooltip>Reviewed: {userCount.radicalLevelT} ({currentRadicalT}%)</Tooltip>}>
                  <ProgressBar now={currentRadicalT} label={`${currentRadicalT }%`} isChild={true} className="color-medium-green" key={1} />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Learnt: {userCount.radicalLevel} ({currentRadical}%)</Tooltip>}> 
                  <ProgressBar now={currentRadical-currentRadicalT} label={`${currentRadical}%`} isChild={true} className="color-light-green" key={2} />
                </OverlayTrigger>
              </ProgressBar>
              <br/>
              <div className="d-flex justify-content-between"><span>Hanzi</span> <span>{itemCount.hanziLevel}</span></div>
              <ProgressBar>
                <OverlayTrigger overlay={<Tooltip>Reviewed: {userCount.hanziLevelT} ({currentHanziT}%)</Tooltip>}>
                  <ProgressBar now={currentHanziT} label={`${currentHanziT }%`}  key={1} isChild={true} className="color-medium-green" />
                </OverlayTrigger> 
                <OverlayTrigger overlay={<Tooltip>Learnt: {userCount.hanziLevel} ({currentHanzi}%)</Tooltip>}>
                  <ProgressBar now={currentHanzi-currentHanziT} label={`${currentHanzi}%`} isChild={true} className="color-light-green" key={2} />
                </OverlayTrigger> 
              </ProgressBar>
              <br/>
              {!userInfo.data.ignoreVocab && <><div className="d-flex justify-content-between"><span>Vocabulary</span> <span>{itemCount.vocabLevel}</span></div>
              <ProgressBar>
              <OverlayTrigger overlay={<Tooltip>Reviewed: {userCount.vocabLevelT} ({currentVocabT}%)</Tooltip>}>
                <ProgressBar now={currentVocabT} label={`${currentVocabT }%`}  key={1} isChild={true} className="color-medium-green" />
                </OverlayTrigger> 
              <OverlayTrigger overlay={<Tooltip>Learnt: {userCount.vocabLevel} ({currentVocab}%)</Tooltip>}>
                <ProgressBar now={currentVocab-currentVocabT} label={`${currentVocab}%`} isChild={true} className="color-light-green" key={2} />
              </OverlayTrigger> 
              </ProgressBar>
              </>
              }
              <div className="pt-3 d-flex justify-content-end align-items-center"> <span className="square mr-1 color-medium-green"/><small className="mr-1">Reviewed</small> <span className="square mx-1 color-light-green"/> <small>Learnt</small></div>
            </div>
            </Tab>
            <Tab eventKey="overall progress" title={<span className="dashboard-h5">Overall Progress</span>}>
          <div className="px-4 pb-4">
            <div className="d-flex justify-content-between"><span>Radical</span> <span>{itemCount.radicalAll}</span></div>
          <ProgressBar>
            <OverlayTrigger overlay={<Tooltip>Memorized: {userCount.radicalAllT} ({overallRadicalT}%)</Tooltip>}>
              <ProgressBar now={overallRadicalT} label={`${overallRadicalT }%`} isChild={true} className="color-medium-green" key={1} />
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Learnt: {userCount.radicalAll} ({overallRadical}%)</Tooltip>}> 
              <ProgressBar now={overallRadical-overallRadicalT} label={`${overallRadical}%`} isChild={true} className="color-light-green" key={2} />
            </OverlayTrigger>
          </ProgressBar>
          <br/>
          <div className="d-flex justify-content-between"><span>Hanzi</span> <span>{itemCount.hanziAll}</span></div>
          <ProgressBar>
            <OverlayTrigger overlay={<Tooltip>Memorized: {userCount.hanziAllT} ({overallHanziT}%)</Tooltip>}>
              <ProgressBar now={overallHanziT} label={`${overallHanziT }%`} isChild={true} className="color-medium-green" key={1} />
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Learnt: {userCount.hanziAll} ({overallHanzi}%)</Tooltip>}> 
              <ProgressBar now={overallHanzi-overallHanziT} label={`${overallHanzi}%`} isChild={true} className="color-light-green" key={2} />
            </OverlayTrigger>
          </ProgressBar>
          <br/>
          <div className="d-flex justify-content-between"><span>Vocabulary</span> <span>{itemCount.vocabAll}</span></div>
          <ProgressBar>
            <OverlayTrigger overlay={<Tooltip>Memorized: {userCount.vocabAllT} ({overallVocabT}%)</Tooltip>}>
              <ProgressBar now={overallVocabT} label={`${overallVocabT }%`} isChild={true} className="color-medium-green" key={1} />
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Learnt: {userCount.vocabAll} ({overallVocab}%)</Tooltip>}>
              <ProgressBar now={overallVocab-overallVocabT} label={`${overallVocab}%`} isChild={true} className="color-light-green" key={2} />
            </OverlayTrigger>
          </ProgressBar>
          <div className="pt-3 d-flex justify-content-end align-items-center"> <span className="square mr-1 color-medium-green"/><small className="mr-1">Memorized</small> <span className="square mx-1 color-light-green"/> <small>Learnt</small></div>
            </div>
            </Tab>
          </Tabs>
        </div>
      </Col>
    </Row> 

    
    <Row className="mb-5">

      <Col lg={12} xl={6} >
      <div style={{backgroundColor: "white"}} className="rounded mt-4 p-4 shadow-sm h-90 nav-link-pad">
            <h5>Upcoming Items</h5>
            {learnItems > 0 ? upComing?.slice(0,5).map((item, index) => (
              <BlockMinus key={index} itemInfo={item}/>
            )): 
            <>
            <Button disabled block className="px-5 dashboard-grey" variant="light">
              <i className="bi bi-check-circle"/>
              <p className="h6 pt-2">You have no items in your learn queue. <small>Why?</small></p>
            </Button>
            </>}              
      </div>
      </Col>

      <Col  lg={12} xl={6}>
        <div style={{backgroundColor: "white"}} className="rounded mt-4 p-4 shadow-sm h-90">
            <Row>
            {(upComingRev.filter(({count})=> count > 0).length > 0 && !userInfo.data.pause ) ?
            <>                
            <Col xs={4}>
            <h5>Upcoming Reviews</h5>
            </Col>
            <Col>
            <div className="d-flex justify-content-between align-items-end h-100"><span className="calendar-axis">0</span><span className="calendar-axis">{Math.round(revWeekTotal/2)}</span><span className="calendar-axis">{revWeekTotal}</span></div>
            </Col></>:
            <Col>
            <h5>Upcoming Reviews</h5>
            </Col>                
            }
            </Row>
            {/* Checks if there are upcoming items */}
            
            {userInfo.data.pause ?
            <Button disabled block className="px-5 dashboard-grey" variant="light">
              <i className="bi bi-exclamation-circle"/>
              <p className="h6 pt-2">Your review queue is paused.<br/><small>Unpause your queue to view upcoming items</small></p>
            </Button> :
            (upComingRev.filter(({count})=> count > 0).length > 0 ) ?
            upComingRev?.map((item, index) => 
            <div className="calendar" key={index}>
            <Row className="py-1">
              <Col xs={2}>
                <p style={{margin: 0}} className="font-weight-bold font-dark-green text-center">{item.day === 1 ? "MON" : item.day === 2 ? "TUE" : item.day === 3? "WED" : item.day === 4 ? "THU" : item.day === 5 ? "FRI" : item.day === 6 ? "SAT" : "SUN"}</p>
              </Col>
              <Col xs={2} className="d-flex flex-row"><span className="d-none d-sm-block pr-1">{item.month}</span><span>{item.date}</span></Col>
              <Col>
                {item.count > 0 ? <OverlayTrigger overlay={<Tooltip>{item.count} item(s){<br/>} Next review: {item.earliestReview}</Tooltip>}>
                    <ProgressBar className="calendar-bar" max={revWeekTotal} now={item.count} />
                </OverlayTrigger> : <ProgressBar/>}
                </Col>
            </Row></div>)
            :
            <Button disabled block className="px-5 dashboard-grey" variant="light">
              <i className="bi bi-calendar-check"/>
              <p className="h6 pt-2">You have no upcoming reviews in the next 7 days. <small>Why?</small></p>
            </Button>}
        </div>
      </Col>
    </Row>

    {/* <Row className="mt-3">
      <Col xs={6} lg={3}>
      <div style={{backgroundColor: "#466eb6"}} className="rounded mt-4 shadow-sm pod-1">
      <i className="pod-icon bi bi-clipboard-check"/>
      <div className="h-100 d-flex justify-content-center align-items-center"><span className="pod-1-text">Getting Started</span></div>
      </div>
      </Col>
      <Col>
      <div style={{backgroundColor: "#3d7c8e"}} className="rounded mt-4 shadow-sm pod-1">
      <i className="pod-icon bi bi-megaphone-fill"/>
      <div className="h-100 d-flex justify-content-center align-items-center"><span className="pod-1-text">Updates</span></div>
      </div>
      </Col>
      <Col xs={6} lg={3} >
      <div  style={{backgroundColor: "#c85f4c"}} className="rounded mt-4 shadow-sm pod-1">
      <i className="pod-icon bi bi-people-fill"/>
      <div className="h-100 d-flex justify-content-center align-items-center"><span className="pod-1-text">About Us</span></div>
      </div>
      </Col>
      <Col>
      <div style={{backgroundColor: "#99767a"}} className="rounded mt-4 shadow-sm pod-1">
      <i className="pod-icon bi bi-patch-question-fill"/>
      <div className="h-100 d-flex justify-content-center align-items-center"><span className="pod-1-text">Support</span></div>
      </div>
      </Col>
    </Row> */}


    </motion.div>
  </Container>
  </section>
   
  : <LoadingScreen/> )
}

export default Dashboard;