import React from "react";
import {Accordion, Card} from "react-bootstrap";
import Block from "./Block"

function SearchAccordion(props){

    const items = props.items;
    const type = props.type;
    let totalCount = items.length;


    
    return (
        <Accordion className="pb-3" defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle  style={{backgroundColor:  type === "Radical" ? "#46C793" : type === "Hanzi" ? "#20A2BA" : "#BA2969" }} className="accordion-header d-flex justify-content-between" as={Card.Header} eventKey="0">
                        <span>{type}</span>
                        <div>                           
                            <span className="pr-3"><i className="bi bi-search mb-3"/> {totalCount}</span>
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body className="item-accordion">                       
                        
                        {items.map((item, index) => (                
                            <Block itemInfo={item} key={index} />
                        ))}                            
                        
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>                    
                </Accordion>
    )
}


export default SearchAccordion;

