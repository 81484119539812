import React from "react";
import { motion, AnimatePresence } from "framer-motion"
import LoadingGif from "../images/Loading.svg"

function OverlayLoad (props){
    
    const variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },    }

    
    return(
    <AnimatePresence>
    {props.show && <motion.div key="overlayload"  initial="hidden"
    animate="visible" exit="hidden"
    variants={variants} style={{zIndex: 999, height: "100%", width: "100%", position: "fixed", backgroundColor: "rgba(255, 255, 255, 0.5)", overflow: "auto"}}>
    <img style={{position: "absolute",
                margin: "auto",
                maxWidth: "250px",
                top: 0, 
                left: 0,
                right:0,
                bottom:0,
                width: "100%"}} 
                src={LoadingGif} alt="Mandarin App"/>
 
    </motion.div>}
    </AnimatePresence>)
}

export default OverlayLoad;