import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";
import {Container, Row, Col, Nav} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap";
import { motion } from "framer-motion"

function DeleteSuccess(props){
    let history = useHistory();
    const [isLoading, setIsLoading] =  useState(true);
    
    useEffect(() => {
      if(props.isLoggedIn){
        history.push('/dashboard')
      } else {
        props.handleNavBar(true);
        setIsLoading(false);
      }       
    },[props.isLoggedIn])

    return(isLoading? <div><LoadingScreen/></div> :
       <section id="login">
         <Container  fluid className="py-lg-5 py-3 container-custom">
         <motion.div animate={{ x: [-100, 0  ] }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
         <Row className="d-flex justify-content-center"><span className="text-brand font-weight-bold py-3" onClick={()=> history.push("/")}>Hanter</span></Row>
          <Row className="justify-content-md-center">
            <Col className="bg-white rounded shadow-sm m-md-5 p-md-5 m-2 p-3">
            <h1 className="fc-primary text-center"><i class="bi bi-emoji-frown-fill"></i></h1>
              <h2 className="fc-primary text-center">It's goodbye for now</h2>
              <br/>
              <p className="text-center">Your account has been successfully deleted.</p>
                  <br/>                        
                <div className="d-flex flex-row justify-content-center">                
                  <LinkContainer to="/"><Nav.Link>Go back to home</Nav.Link></LinkContainer>
                </div>
 
            </Col>
          </Row>
          </motion.div>
        </Container>
      </section>
    )        
}

export default DeleteSuccess;