import React, {useEffect, useState} from "react";
import axios from "axios";
import {Container, Row, Col} from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";
import { motion } from "framer-motion"

function VerifyEmail(props){
    const id = props.match.params.id;
    const history = useHistory();
    const [response, setResponse] = useState({msg: ""});
    const [isLoading, setIsLoading] =  useState(true);

    useEffect(() => {

        axios.get("/api/confirm/user/" + id)
        .then(response => {
            const data = response.data;
            setResponse(data);
            props.handleNavBar(true);
            setIsLoading(false);
        }).then(response => {
            setTimeout(()=> history.push("/login"), 5000)
        })
        .catch((err) => {
            
            setResponse(err.response.data);
            props.handleNavBar(true);
            setIsLoading(false);
            setTimeout(()=> history.push("/login"), 5000)        
        });
       
    }, [id])

    return(
        !isLoading ?
        <Container fluid className="py-lg-5 py-3 container-custom ">
            <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}>
                <Row className="bg-white rounded shadow-sm p-md-5 pt-4 py-1 text-center">
                    <Col>
                    <h3 className="fc-primary">Email verification</h3>
                    <div className="pb-3 pt-4">
                        {response.msg}
                        <p>Redirecting...</p>
                    </div>  
                    </Col>                
                </Row>
            </motion.div>
        </Container> : <LoadingScreen/>
    )

}

export default VerifyEmail;
