import React, {useState} from "react";
import {Alert, Button, Row, Col} from "react-bootstrap";
import instance from "../axiosConfig";
import { useHistory } from 'react-router-dom';


function AlertPause(props) {

    const [resume, setResume] = useState(false);
    const [error, setError] = useState(false);

    const history = useHistory();

    async function changePause(event){
        event.preventDefault();
        props.overlay(true);       
        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updatePause: "true",
               value: !props.user.pause
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
              setTimeout(() => {
                setResume(true);
                props.overlay(false);  
                props.updateUserInfo();
                history.push('/')
              }, 2000); 
            })
            } catch(err) {
              if(err.response.data === "Unauthorized"){
                window.location.reload();
              } else {
                setError(true);
                console.log(err.response.data);
              }
            }   
    }


      return (
        
        <>
        {resume? 
        <Alert className="container-custom" variant="success" onClose={() => props.handleShowPause(false)} dismissible>
        Your review has resumed.
      </Alert>
      
      
      : <Alert className="container-custom" variant="warning">
       
        <Row>
          <Col xs={12} sm={9}>
          <Alert.Heading>Your review queue is paused</Alert.Heading>
          When paused, the duration until your next review will not reduce. Unpause your review queue to resume learning.
{          error && <p style={{color: "red"}}>Issue resuming queue. Please contact customer service.</p>
}          </Col>
          <Col className="d-flex justify-content-md-end">
          <span className="d-flex align-items-center pt-3 pt-sm-0"><Button variant="success" onClick={changePause}>Resume Queue</Button></span>
          </Col>

        </Row>
      </Alert>}
        
        </>


      );

  }
  
export default AlertPause;