import React, { useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import {Container, Col, Row, Badge, Breadcrumb, Table, ProgressBar, OverlayTrigger, Tooltip, Button} from "react-bootstrap";
import { motion} from "framer-motion"
import LoadingScreen from "./LoadingScreen";

function GettingStarted (props) {
    
    const [isLoading, setIsLoading] =  useState(true);
    const history = useHistory();

    useEffect(() => {
        if (props.isLoggedIn){
            setIsLoading(false);
        } else if (props.isLoggedIn === false) {
            history.push('/login')
        }
    },[props.isLoggedIn])


    return (
        
        !isLoading ? 
        <div id="getting-started">
            <Container fluid className="py-lg-5 py-3 container-custom ">
                <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}>
                    <Row className="bg-white rounded shadow-sm p-md-5 pt-4 py-1">
                        <Col>
                            <h3 className="fc-primary">Getting Started</h3>
                            <div className="pt-4">
                                Hey there! Welcome to Hanter. We are here to help you learn Mandarin the easy way. Check out our getting started guide below to learn more about how it works!
                            </div>
                            <div  className="pt-4">
                                <h5>What is Hanter?</h5>

                               <p> Hanter is a flash card learning tool to help you learn the essential Mandarin characters (Hanzi). There are over 20,000 Hanzi in the modern dictionary but do not worry, you just need to learn around 2,000 ~ 3,000 common Hanzi to be able to read a newspaper. Easy right? Well no actually, not when you have to memorize all characters by heart including the meaning and the different tones!</p>
                                
                                Well, this is where Hanter comes in. We do this by breaking down the learning process into 5 steps.
                            
                            </div>
                            <div className="pt-4">
                                <h5>Step 1: Breaking down Hanzi into Radicals and learning through Pictograms</h5>
                                
                                <p>The items you will learn are broken down into 3 groups. Radicals, Hanzi and Vocabulary.
                                Radicals are the building blocks of Chinese Characters. There are 214 official radicals and each Hanzi can be categorized by 1 official radical. 
                                In Hanter, we took this step further to break down all the Hanzi into radicals (using both official and custom radicals) and using creative pictograms to help you memorize them.
                                
                                By associating a meaning to each radical, it will help to make the connection to the Hanzi easier to memorize.

                                [Tian + Dirt = Village]
                                
                                </p>
                                

                            </div>
                            <div className="pt-4">
                                <h5>Step 2: Learning reading, meaning and tone through mnemonics</h5>
                                <p>Mnemonics is a memorization technique that utilize visual and acoustic cues to help you recall you have learnt. In Hanter, we have created meaning, reading and tone mnemonics for each Hanzi to help you visualize a scenario. 
                                The mnemonics are purposely created to be ridiculous and unrealistic as it has been shown that we tend to be able to recall scenarios that evoke strong emotions better. Just like how you probably won't be able to recall what you ate exactly one week ago for breakfast, but can definitely recall an embarrassing or shocking event from your past. 

                               [Example here]
                                In Hanter we associate each of the 4 tones to a season. Tone 1, 2, 3 and 4 is associated with Spring, Summer, Autumn and Winter respectively. 
                                
                                [Continuation of the example above]
                                
                                You are also free to create your own mnemonics that helps you recall the words best!</p>

                            </div>
                            <div className="pt-4">
                                <h5> Step 3: Solidifying your learning with Vocabulary</h5>
                                <p>A great way to solidify what you've learnt it by learning the associate vocabulary. After reviewing the Hanzi, you will unlock various associated vocabulary to increase your word bank!
                                *If you just wish to learn the Hanzi, there are options for you to skip the Hanzi as well</p>
                            </div>
                            <div className="pt-4">
                                <h5> Step 4: Helping you memorize with Spaced Repetition System</h5> 
                                <p>Spaced Repetition System(SRS) is a system that is used commonly when learning with flashcards. 
                                It determines how often each item will appear in your review queue. 
                                In Hanter, every new item you learn will appear in your review queue in 4 hours. 
                                For each item you answer correctly, the time until its' next review will increase. However, if you answer incorrectly, the time until the next review will decrease. This will make sure that you spend more time reviewing items that you are not familiar with to solidify your memory. 
                                
                                You can't customize the speed of the frequency as well.
                                </p>

                                [Image of the frequency]
                            </div>
                            <div className="pt-4">
                            <h5> Step 5: Taking it one step at a time</h5> 
                                <p>In Hanter we have broken up the items that you will learn into levels. Before you can move on to the next level, you will need to meet a certain review threshold. 
                                This will prevent you from burning out as reviews will start to pile up as you progress further.</p>

                                *Note: There are options for you to lower the threshold.
                            </div>
                            <div className="pt-4">
                                <p>There you have it! You are now on your way to master Mandarin! </p>
                            </div>
                            <div className="pt-4">
                                Have more questions? Do check our of FAQ section.
                            </div>
                        </Col>
                    </Row>
                </motion.div>
            </Container>
        </div> :
        <LoadingScreen/>

    )
}

export default GettingStarted;