import React, {useState} from "react";
import {Alert, Button, Row, Col} from "react-bootstrap";
import instance from "../axiosConfig";

function AlertVerifyEmail(props) {
  
   const [resend, setResend] = useState(false);
   const [error, setError] = useState(false);

   function handleClick(event){
    event.preventDefault();
    props.overlay(true)
    instance.get("/api/confirm/resend")
        .then(response => {
            setTimeout(() => {
            setResend(true);
            props.overlay(false); 
        }, 1000); 
        })
        .catch((err) => {
          if(err.response.data === "Unauthorized"){
            window.location.reload();
          } else {
            setError(true);
            props.overlay(false);   
          }                  
        });
   }

  return (
    <Alert className="container-custom" variant="warning" onClose={() => props.handleShow(false)} dismissible>
      
      <Row>
        <Col xs={12} sm={9}>
        <Alert.Heading>Verify your email</Alert.Heading>
        An verification email has been sent to {props.user.email}.
{           error && <p style={{color: "red"}}>Issue resending email. Please contact customer service.</p>
}            </Col>
        <Col className="d-flex justify-content-md-end">
        <span className="d-flex align-items-center pt-3 pt-sm-0">{!resend ? <Button variant="success" onClick={handleClick}>Resend email</Button> : <Button variant="secondary" disabled >Email sent</Button>}</span>
        </Col>
      </Row>
    </Alert>
  );

}
  
export default AlertVerifyEmail;