import React from "react";
import loadingGif from "../images/block-load.svg"

function BlockLoading(){

    return(
    
    <div className="block"><img src={loadingGif} alt="Loading"/></div>
    
    
    )
    
}

export default BlockLoading;