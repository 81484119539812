import React, {useState} from "react";
import instance from "../axiosConfig";
import {Form, Alert} from "react-bootstrap";
import rolling from "../images/Rolling.svg";
import { motion } from "framer-motion";


function CustomMean (props){

    const [input, setInput] = useState("");
    const [error, setError] = useState(false);
    const maxLength = 100;
    
    function handleChange(event){
        setInput(event.target.value.slice(0,100))
    }

    function handleSubmit(){
        props.handleMeanLoad(true);
        instance({
            method: "put",
            withCredentials: true,
            url: "/api/users/id",
            data: {newNotes: "true", item: props.item, customMean: input, content: ""},
            headers:{ 'content-type': 'application/json' }
        }).then((res)=> {
            let obj={customMean: input, type: "mean", new: true}
            props.updateState(obj)  
         }).catch((err) => {
            
            if (err.response.data === "Unauthorized"){
                window.location.reload()
            } else {
                console.log(err);
                setError(true);
                setTimeout(() => { 
                    setError(false);
                },5000);
            }

         });
    }

    function handleCancel(event){
        event.preventDefault();
        props.handleMean(false);
    }

    return (
        <>
        <Form className="pb-3 mean">
        <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control as="textarea" onChange={handleChange} placeholder="Use commas (,) for multiple meanings. E.g. One, First, Once" autoComplete="off" value={input} rows={2} />
            
            {props.meanLoad ?
            <img className="rolling-button" src={rolling} alt="Loading"></img>
            :<>
            <div className="note-count small"><span className="fc-primary font-weight-bold">Count:</span> {input.length}/{maxLength}</div>
            <div className="plus-button" onClick={handleSubmit}><motion.div whileHover={{scale: 1.2 }}><i className="bi bi-plus-circle-fill"/></motion.div></div>
            <div className="cancel-button" onClick={handleCancel}><motion.div whileHover={{scale: 1.2 }}><i className="bi bi-x-circle-fill"/></motion.div></div>
            </>}

        </Form.Group>
        </Form>
        <br/>
        {error && <Alert variant="danger">Something went wrong. Please try again later.</Alert>}

        </>
    )

}

export default CustomMean;