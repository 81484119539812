import React, { useEffect, useState, useRef} from "react";
import instance from "../axiosConfig";
import LoadingScreen from "./LoadingScreen";
import { useHistory } from 'react-router-dom';
import {Container, Navbar, Row, Col, ProgressBar, Form, Jumbotron, Table, Tooltip,OverlayTrigger, Button, InputGroup, FormControl, Modal} from "react-bootstrap"
import { useSwipeable} from 'react-swipeable';
import { motion, AnimatePresence } from "framer-motion";
import parse from 'html-react-parser';
import Block from "./Block";
import EditNotes from "./EditNotes";
import CreateNotes from "./CreateNotes";
import CustomMean from "./CustomMean";
import CustomMeanEdit from "./CustomMeanEdit";
import AudioPlayer from "./AudioPlayer";
import Tour from 'reactour';
import e from "connect-flash";

var _ = require('lodash');
const toneConvert = require('pinyin-tone-convert');
var levenshtein = require('fast-levenshtein');


function LearningQueue (props){
      
    const history = useHistory();
    const [userInfo, setUserInfo] = useState();
    const [learnTour, setLearnTour] = useState(false);
    const [quizQueue, setQuizQueue] = useState([]);
    const [learnIndex, setLearnIndex] = useState(0);
    const [quizIndex, setQuizIndex] = useState(0);
    const [queueLength, setQueueLength] = useState();
    const [hasStarted, setHasStarted] = useState(false);
    const [answer, setAnswer] = useState("");
    const [fastMode, setFastMode] = useState(false);
    const [clickIndex, setClickIndex] = useState(0);
    const [answerState, setAnswerState] = useState(0); // 0 is neutral, 1 is correct, 2 is wrong
    const [isLoading, setIsLoading] =  useState(true);
    const [aniState, setAniState] = useState(0); // 0 is neutral, 1 is correct, 2 is wrong
    const [editNote, setEditNote] = useState(false);
    const [update, setUpdate] = useState(false);
    const [noteLoad, setNoteLoad] = useState(false);
    const [editMean, setEditMean] = useState(false);
    const [meanLoad, setMeanLoad] = useState(false);
    const [showImg, setShowImg] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [mute, setMute] = useState(false);
    const [audioLoad, setAudioLoad] = useState(false);
    const handleClose = () => setShowKeyboard(false);
    const handleShow = () => setShowKeyboard(true);
    
    const scrollPoint = useRef(null);
    const divFocus = useRef(null);
    const musicFocus = useRef(null);
    const handlers = useSwipeable({
        onSwipedLeft: () => nextItem(),
        onSwipedRight: () => prevItem(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });
        
    const spring = "https://res.cloudinary.com/dypzm94tc/image/upload/v1616920131/flower.svg"
    const summer = "https://res.cloudinary.com/dypzm94tc/image/upload/v1616920131/sun.svg"
    const autumn = "https://res.cloudinary.com/dypzm94tc/image/upload/v1616920131/leaf.svg"
    const winter = "https://res.cloudinary.com/dypzm94tc/image/upload/v1616920131/snow.svg"

    useEffect(() => {
        if(props.userInfo && props.isLoggedIn){
            setUserInfo(props.userInfo);            
        } else if (props.isLoggedIn === false){
            history.push('/login');
        }
    },[props.userInfo, props.isLoggedIn]);
        
    //These two effects can be combined

    useEffect(() => {        

        if (userInfo?.data?.pause === false) {
            setLearnTour(!userInfo?.data?.clearTour);
            instance.get("/api/items?learnqueue=true")
            .then(response => {
                const data = response.data;
                setQuizQueue(data);
                setQueueLength(data.length);
                setQuizQueue(                    
                    data.map((item) =>(                    
                    item.type === "Radical" ? {...item, meanCorrect: false}: {...item, meanCorrect: false, readCorrect: false}                                                 
                    ))         
                );
            })
            .then(response => {
                props.handleNavBar(true);
                setIsLoading(false);
                divFocus.current.focus();          
            }).catch((err) => {
                if (err.response.data === "Unauthorized"){
                window.location.reload()
                } else {
                console.log(err);
                };
            });
        } else {
            setIsLoading(false);
        }

    }, [userInfo, update])

    useEffect(()=> {
        if (!mute){
            musicFocus?.current?.click();
        }    
    },[audioLoad])

    function updateAudio(){
        setAudioLoad(!audioLoad);
    }

    function updateMute(){
        setMute(!mute);
        console.log("update Mute")
    }

    function handleKeyDown(event){
        var keyboardPressed = event.key;

        if (hasStarted === false && !learnTour){

            if (event.target.nodeName.toLowerCase() !== 'textarea'){

                if (keyboardPressed === "ArrowRight" || keyboardPressed === "Enter" || keyboardPressed === " " ){
                    
                    if(learnIndex === queueLength-1){
                        startQuiz();
                    } else {nextItem();}                
                    
                } else if (keyboardPressed === "ArrowLeft"){
                    prevItem();
                } else if ((keyboardPressed === "s" || keyboardPressed ==="S") && quizQueue[learnIndex].img){
                    setShowImg(!showImg);
                } else if ((keyboardPressed === "d" || keyboardPressed ==="D") && quizQueue[learnIndex].audio){
                    musicFocus.current.click();
                }
            }

        } else {
            
            if ( event.target.nodeName.toLowerCase() !== 'input' && event.target.nodeName.toLowerCase() !== 'textarea' && (keyboardPressed === "s" || keyboardPressed ==="S") && answerState !== 0 && quizQueue[quizIndex].img ) {             
                setShowImg(!showImg);
            } else if ( event.target.nodeName.toLowerCase() !== 'input' && event.target.nodeName.toLowerCase() !== 'textarea' && quizQueue[quizIndex].meanCorrect === true && (keyboardPressed === "d" || keyboardPressed ==="D") && answerState !== 0 && quizQueue[quizIndex].audio ){
                musicFocus.current.click();
            }
        } 
    }

    function handleMeanDown(event){
        if (event.key === "Enter"){
            submitMean(event);
        } 
    }

    function handleReadDown(event){
        if (event.key === "Enter"){
            submitRead(event);
        } 
    }

    function nextItem(event){

     if ( learnIndex < queueLength-1){
    //   window.scrollTo({top: 0, behavior: 'smooth'})
      scrollPoint.current.scrollIntoView();
      setLearnIndex(learnIndex => learnIndex + 1);
        } else if (learnIndex === queueLength-1) {
            setHasStarted(true);
        }

        setEditNote(false);
        setEditMean(false);
        setShowImg(false);

    }

    function prevItem(event){
        if (learnIndex !== 0) {
            // window.scrollTo({top: 0, behavior: 'smooth'})
            scrollPoint.current.scrollIntoView();
            setLearnIndex(learnIndex => learnIndex - 1);
        } 
        setEditNote(false);
        setEditMean(false);   
        setShowImg(false);   
    }

    function startQuiz(event){
        setHasStarted(true);
        setShowImg(false);
    }

    function handleChange(event){
        setAnswer(event.target.value.slice(0,100));
    }  

    function handleFastMode(event){
        setFastMode(!fastMode);
    }

    function goHome(event){
        event.preventDefault();
        history.push("/");
    }
    
    //Called when user wants to learn more item

    function refresh(event){
        setIsLoading(true);
        event.preventDefault();
        setUpdate(!update);
        setHasStarted(false);
        setLearnIndex(0);
        setQuizIndex(0);
    }

    function aniCorrect(event){
        setAniState(1);
        setTimeout(function(){
            setAniState(0);
        },100);
    }

    function aniWrong(event){
        setAniState(2);
        setTimeout(function(){
            setAniState(0);
        },100);

    }

    function handleImg(){
        setShowImg(!showImg);
    }

    function handleNoteLoad(res){
        setNoteLoad(res)
    }

    function handleNote(res){
        setEditNote(res);
    }

    //Custom name//

    function handleMeanLoad(res){
        setMeanLoad(res);
        
    }

    function handleMean(res){
        setEditMean(res);
    }

    function delayedLoad(obj){
        if (obj === "notes"){
            setTimeout(function() {
                setNoteLoad(false);
                setEditNote(false);
              }, 1000);
        } else if (obj === "mean"){
            setTimeout(function() {
                setMeanLoad(false);
                setEditMean(false);
              }, 1000);
        }        
    }

    const updateState=(data)=>{      
            if (!hasStarted){
                if (data.type === "notes"){
                    //Notes edit
                    if (data.new) {
                        let replace = quizQueue[learnIndex];
                        replace.notes[0] = {"notes": {"content": data.content, "custom_mean": ""}}
                        let newQueue=[...quizQueue];
                        newQueue.splice(learnIndex,1,replace)
                        setQuizQueue(newQueue)   
                    } else {
                        let replace = quizQueue[learnIndex];
                        replace.notes[0].notes.content = data.content;
                        let newQueue=[...quizQueue];
                        newQueue.splice(learnIndex,1,replace)
                        setQuizQueue(newQueue)   
                    } 
                    delayedLoad("notes");
                        
                } else if (data.type === "mean"){
                    //Mean edit
                    if (data.new){
                        let replace = quizQueue[learnIndex];
                        replace.notes[0] = {"notes": {"content": "", "custom_mean": data.customMean}}
                        let newQueue=[...quizQueue];
                        newQueue.splice(learnIndex,1,replace)
                        setQuizQueue(newQueue)   
                    } else {
                        let replace = quizQueue[learnIndex];
                        replace.notes[0].notes.custom_mean = data.customMean
                        let newQueue=[...quizQueue];
                        newQueue.splice(learnIndex,1,replace)
                        setQuizQueue(newQueue)   
                    }    
                    delayedLoad("mean");
                } 
            } else {
                if (data.type === "notes"){
                    //Notes edit
                    if (data.new) {
                        let replace = quizQueue[quizIndex];
                        replace.notes[0] = {"notes": {"content": data.content, "custom_mean": ""}}
                        let newQueue=[...quizQueue];
                        newQueue.splice(quizIndex,1,replace)
                        setQuizQueue(newQueue)   
                    } else {
                        let replace = quizQueue[quizIndex];
                        replace.notes[0].notes.content = data.content;
                        let newQueue=[...quizQueue];
                        newQueue.splice(quizIndex,1,replace)
                        setQuizQueue(newQueue)   
                    } 
                    delayedLoad("notes");
                        
                } else if (data.type === "mean"){
                    //Mean edit
                    if (data.new){
                        let replace = quizQueue[quizIndex];
                        replace.notes[0] = {"notes": {"content": "", "custom_mean": data.customMean}}
                        let newQueue=[...quizQueue];
                        newQueue.splice(quizIndex,1,replace)
                        setQuizQueue(newQueue)   
                    } else {
                        let replace = quizQueue[quizIndex];
                        replace.notes[0].notes.custom_mean = data.customMean
                        let newQueue=[...quizQueue];
                        newQueue.splice(quizIndex,1,replace)
                        setQuizQueue(newQueue)   
                    }    
                    delayedLoad("mean");
                } 

            }       
        
    }

    function submitMean(event){
        event.preventDefault();
        let levenScore = quizQueue[quizIndex].name.map(item =>{return levenshtein.get(answer, item)});
        let answerLC = quizQueue[quizIndex].name.map(item =>{return _.toLower(item)}); 
        let answerCustom = null;

        if (quizQueue[quizIndex].notes.length > 0 && quizQueue[quizIndex].notes[0].notes.custom_mean.trim()){
            var split =  parse(quizQueue[quizIndex].notes[0].notes.custom_mean).split(",");
            answerCustom = split.map(item =>{return _.toLower(item.replace(/\s/g, ""))});
        }

        if ( (answer.length >3 && levenScore.some(item => item <= 2)) || answerLC.includes(_.toLower(answer.trim())) || (answer.trim() && answerCustom?.includes(_.toLower(answer.replace(/\s/g, "")))) ) {
            setClickIndex(clickIndex+1);
            if (clickIndex === 1 || fastMode){
                setAnswerState(0);
                setAnswer("");
                let copy = quizQueue;
                copy[quizIndex].meanCorrect = true;
                setQuizQueue(copy);
                setClickIndex(0);
                if (quizQueue[quizIndex].type === "Radical"){
                    instance({
                        method: "put",
                        withCredentials: true,
                        url: "/api/users/id",
                        data: {learn: "true", itemID: quizQueue[quizIndex]._id, character:quizQueue[quizIndex].character, type: quizQueue[quizIndex].type, level: quizQueue[quizIndex].level},
                        headers:{ 'content-type': 'application/json' }
                    }).catch((err) => {
                        if (err.response.data === "Unauthorized"){
                            window.location.reload()
                        } else {
                            console.log(err);
                        };
                    });
                }

            } else {
                setAnswerState(1);
                aniCorrect();
            }
            
        } else {
            aniWrong();
            setAnswerState(2); 
            setAnswer("");
        
        }     
    }
    
    function submitRead(event){
        event.preventDefault();
        if ( quizQueue[quizIndex].pronun.includes(toneConvert(_.toLower(answer),{allowAnyChar: true}).replace(/\s/g, ""))) {
            setClickIndex(clickIndex+1);
            if (clickIndex === 1 || fastMode){
                setAnswerState(0);
                setAnswer("");
                let copy = quizQueue;
                copy[quizIndex].readCorrect = true;
                setQuizQueue(copy);
                setClickIndex(0);
                instance({
                    method: "put",
                    withCredentials: true,
                    url: "/api/users/id",
                    data: {learn: "true", itemID: quizQueue[quizIndex]._id, character:quizQueue[quizIndex].character, type: quizQueue[quizIndex].type, level: quizQueue[quizIndex].level},
                    headers:{ 'content-type': 'application/json' }
                }).catch((err) => {
                    if (err.response.data === "Unauthorized"){
                        window.location.reload()
                    } else {
                        console.log(err);
                    };
                });
            } else {
                setAnswerState(1);
                aniCorrect();
            }

        } else {
            aniWrong();         
            setAnswerState(2);
            setAnswer("");
        }   
    }

    function updateTour(){
        setLearnTour(false); 
        instance({
            method: "put",
            withCredentials: true,
            url: "/api/users/id",
            data: {updateTour: "true"},
            headers:{ 'content-type': 'application/json' }
        }).catch((err) => {
            if (err.response.data === "Unauthorized"){
                window.location.reload()
            } else {
                console.log(err);
            };
        });

    }
      
    const jumboVariants = {
        correct: { 
            backgroundColor: "#28a745",
            transition: { duration: 0.1 }
        },
        wrong: { 
            backgroundColor: "#FFB325",
            transition: { duration: 0.1 }
        },
        neutral: {
            backgroundColor: "#e9ecef",
            transition: { duration: 0.5 }
        }
    }

    const steps = [
        {
            selector: '',
            content: () => (
                <div className="d-flex flex-column justify-content-center">
                <span className="d-flex justify-content-center"><i className="h1 bi bi-book"/></span>
                <p>Welcome to <b>Hanter</b>! Let us go through some basics to help get you started.</p>
                </div>
            )
           
        },
        {
            selector: '.step2',
            content: 'This is a radical, the building block of all Chinese characters. By learning radicals, we can break down complex Hanzi (Mandarin characers) into easy to remember pieces.  ',
        },
        {
            selector: '.step3',
            content: 'This is the meaning of the radical. Do remember it as you will be quizzed later on! ',
        },
        {
            selector: '.step4',
            content: 'You can find more information on the radical here.',
        },
        {
            selector: '.step5',
            content: 'You can add your own custom meaning by clicking on the pencil button at the top right hand corner.',
        },
        {
            selector: '.step6',
            content: 'This is the Mnemonic for this item. Mnemonics is a memory technic to help you memorize things better by association.',
        },
        {
            selector: '.step7',
            content: 'Thought of a better mnemonic? You can note it down here! Or feel free to note down anything you like.',
        },
        {
            selector: '.step8',
            content: 'Press the next arrow to go to the next item. You can also use the right arrow on your keyboard or swipe left. ',
        },
        {
            selector: '.step9',
            content: 'If you need help, click here to for more info.',
        },
        {
            selector: '',
            content: () => (
                <div className="d-flex flex-column justify-content-center">
                <span className="d-flex justify-content-center"><i className="h1 bi bi-hand-thumbs-up"/></span>
                  <p>You are now ready to start learning! Press the button below to begin. For more information, see How It Works or check out our FAQ page.</p>
                    <Button variant="success" onClick={updateTour}>Start Learning</Button>
                </div>
              )
        }
      ]

    return(

        (!isLoading && userInfo?.data && props.hideNavBar)? 
        <div style={{marginBottom: "5em"}}>{
        userInfo.data.pause ? 
        
        <div><Container fluid className="container-custom py-lg-5 py-3 h-100" >
        <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}>  
        
        <Jumbotron className="text-center">                    

        <i className="h1 bi bi-exclamation-circle-fill"> Your review queue is paused</i><br/><br/><br/>
        <p>Resume your queue to continue learning</p>
        </Jumbotron>
        
        </motion.div></Container>
        </div>
         : 
         <div id="learn" ref={divFocus} onKeyDown={handleKeyDown} tabIndex={0}>
            
         {learnTour &&
         <Tour
         steps={steps}
         isOpen={learnTour}
         onRequestClose={updateTour}
         accentColor="#46C793"
         closeWithMask={false}
         disableInteraction={true}
         showCloseButton={false} />
}
         <Modal show={showKeyboard} onHide={handleClose}>
                 <Modal.Header closeButton>
                 <Modal.Title><i className="h4 bi bi-question-circle"/> Tips</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>
                     <Container>
                         <h5 className="font-weight-bold">Keyboard Shortcut</h5>
                         <br/>
             
                         <Row  className="pb-3">
                             <Col md={9}>
                                 Toggle image 
                                 <br/><small>Only available for certain Radicals</small>
                             </Col>
                             <Col>
                             S
                             </Col>
                         </Row>

                         <Row className="pb-3">
                             <Col md={9}> 
                             Play audio 
                             <br/><small>Only available for certain Hanzi and Vocab</small>
                             </Col>
                             <Col >
                             D
                             </Col>
                         </Row>
                         <hr/>
                         <h5 className="font-weight-bold">Fast Mode</h5>
                         <br/>
                         <p className="pb-3"> Activating fast mode will allow you to go directly to the next item when you answer correctly</p>
                         <hr/>
                         <h5 className="font-weight-bold">Tone Input</h5>
                         <br/>
                         <p className="pb-3">For Hanzi or Vocab that has a tone, add the tone number after each pin yin without spaces 
                         <br/>
                         (e.g. 你好 nǐhǎo ni3hao3)</p>

                     </Container>
                 </Modal.Body>
             </Modal>
         {hasStarted === false ?
             <React.Fragment>
            
             <Navbar ref={scrollPoint} expand="sm" className="bg-white py-2" variant="light">
                 <Container fluid className="container-custom">      
                     <Navbar.Brand href="/dashboard"><i className="bi bi-house-door-fill"/></Navbar.Brand>
                     
                     {(queueLength > 0) &&  <React.Fragment>  <Navbar.Text className="mr-auto">  </Navbar.Text>  
                     {learnIndex + 1}/<b className="fc-primary pr-3">{quizQueue.length}</b><Navbar.Text className="mr-auto d-none d-sm-block" > <ProgressBar variant="success" now={learnIndex + 1} max={quizQueue.length} /> </Navbar.Text>
                     <Navbar.Text > {mute ? <i onClick={updateMute} className="h2 pt-2 pr-3 icon-grey bi bi-volume-mute"/> :<i onClick={updateMute} className="h2 pt-2 pr-3 icon-grey bi bi-volume-up"/>} <i className="step9 h2 pt-2 pr-3 icon-grey bi bi-question-circle 9" onClick={handleShow}/> </Navbar.Text></React.Fragment> }


                 </Container>  
             </Navbar>
             
             
             <Container {...handlers} fluid style={{height: "92%"}} className="container-custom py-lg-5 py-3" >
                 <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}> 
         
                 {(queueLength > 0)?              
                 
                 <React.Fragment>                        

                     <Jumbotron className={ quizQueue[learnIndex].tone === 1 ? "text-center jumbo-spring" : quizQueue[learnIndex].tone === 2 ? "text-center jumbo-summer" : quizQueue[learnIndex].tone === 3 ? "text-center jumbo-autumn" : quizQueue[learnIndex].tone === 4 ? "text-center jumbo-winter" : "text-center" }>                    
                         <Row>                        
                             <Col xs={1} className="d-flex align-items-center">{(learnIndex !== 0) && <i onClick={prevItem} className="bi bi-chevron-left d-none d-md-block"></i>}
                             
                             </Col>                                
                             <Col xs={10}>                   
                             <div className="step2 fixed-height d-flex flex-column justify-content-center fadeIn">
                             
                 
                             <AnimatePresence initial={false}>
                             {showImg &&
                             <motion.div key={0} initial="collapsed" variants={{
                             open: { opacity: 1, height: 300 },
                             collapsed: { opacity: 0, height: 0 }}} 
                             animate="open"
                             exit="collapsed">
                             <img src={`/assets/images/radicals/${quizQueue[learnIndex].string_ID}.svg`} alt={quizQueue[learnIndex].character} className="radical-img"/> </motion.div>}</AnimatePresence>     
                             
                             

                             {!showImg && <div className={quizQueue[learnIndex].type === "Radical" ? "fc-primary" : quizQueue[learnIndex].type === "Hanzi" ? "fc-blue" : "fc-pink"} style={{fontSize: "3.5rem"}}>                             
                             {quizQueue[learnIndex].character}</div>}
                                            
                             
                             <h3 className="step3">{quizQueue[learnIndex].name[0]}</h3>
                             <h3>{quizQueue[learnIndex].pronun && quizQueue[learnIndex].pronun[0]}</h3>
                             <small>{quizQueue[learnIndex].type}  <span>{quizQueue[learnIndex].img && <i onClick={handleImg} className="bi bi-card-image bi-card-quiz-pos"/>}</span></small> 
                            
                             </div>                       
                             
                             </Col>
                             <Col xs={1} className="d-flex flex-row-reverse align-items-center">
                             {(learnIndex < queueLength-1) ? <i onClick={nextItem} className="step8 bi bi-chevron-right d-none d-md-block"></i> : <OverlayTrigger overlay={<Tooltip>Start quiz</Tooltip>}>
                                 <i onClick={startQuiz} className="bi bi-play-fill d-none d-md-block"></i>
                             </OverlayTrigger> }
                             </Col>
                         </Row>
                     </Jumbotron>
                     <Row><Col className="text-left mb-4 step4">
                         <h6 className="text-muted small">Meaning</h6>
                         <hr/>

                         <Table  borderless size="sm">
                         
                                         <tbody className="text-center">                              
                                         {quizQueue[learnIndex].name.length > 1 &&                                    
                                             <tr>
                                             <td className="quiz-table"><small>Alternative meaning</small></td>
                                             <td ><p>{quizQueue[learnIndex].name.slice(1).map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })} </p></td>
                                             </tr>}

                                             <tr className="step5">
                                             <td className="quiz-table"><small>Custom meaning</small></td>
                                             <td >
                                             {quizQueue[learnIndex].notes.length > 0 ? 
                                     
                                             editMean ? <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                                             <CustomMeanEdit handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[learnIndex].string_ID} notes={quizQueue[learnIndex].notes[0].notes}/></motion.div> : (quizQueue[learnIndex].notes[0].notes.custom_mean && quizQueue[learnIndex].notes[0].notes.custom_mean.trim()) ? <div className="text-center"><p>{parse(quizQueue[learnIndex].notes[0].notes.custom_mean)}</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                                             
                                             : 
                                             
                                             editMean ? <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                                             <CustomMean handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[learnIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                                             
                                             }
                                             </td>
                                             </tr>                                       

                                             <tr  className="step6">
                                             <td className="quiz-table"><small>Mnemonic</small></td>
                                             <td >{parse(quizQueue[learnIndex].mnemonicName)}</td>
                                             </tr>                                          

                                         </tbody>
                                     </Table>                           

                             </Col>
                             
                             {quizQueue[learnIndex].mnemonicPronun &&
                             <Col sm={12} lg={6} className="text-left mb-4">
                                 <h6 className="text-muted small">Reading {quizQueue[learnIndex].audio && <AudioPlayer ref={musicFocus} place={true} source={quizQueue[learnIndex].string_ID} updateAudio={updateAudio}/>}</h6>
                                 <hr/>
                                 <Table  borderless size="sm">
                                 
                                 <tbody className="text-center">                              
                                     <tr>
                                     <td className="quiz-table"><small>Reading</small></td>
                                     <td ><p>{quizQueue[learnIndex].pronun.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })}</p> </td>
                                     </tr>                                       

                                     <tr>
                                     <td className="quiz-table"><small>Mnemonic</small></td>
                                     <td >{parse(quizQueue[learnIndex].mnemonicPronun)}</td>
                                     </tr>                                          

                                 </tbody>
                             </Table>

                             </Col>}
                             
                             </Row>

                             {quizQueue[learnIndex].exampleSentence &&
                             <Row>
                                 <Col className="text-left mb-4">
                                     <h6 className="text-muted small">Example Sentences</h6>
                                     <hr/>
                                     {quizQueue[learnIndex].exampleSentence.map((example, index) => {
                                         return (
                                             <div key={index} className="text-center">{parse(example.CN)}<br/><small>{parse(example.PY)}</small><p>{parse(example.EN)}</p></div>
                                         )})}
                                 </Col>
                             </Row>}

                             <Row><Col className="mb-5 step7">
                             <h6 className="text-muted small">Notes</h6>
                             <hr/>
                             {quizQueue[learnIndex].notes.length > 0 ? 
                             
                             editNote ? 
                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                             <EditNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[learnIndex].string_ID} notes={quizQueue[learnIndex].notes[0].notes}/></motion.div> : (quizQueue[learnIndex].notes[0].notes.content && quizQueue[learnIndex].notes[0].notes.content.trim()) ? <div className="text-center"><p>{parse(quizQueue[learnIndex].notes[0].notes.content)}</p><i onClick={()=> {setEditNote(true);setEditMean(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true);setEditMean(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                             
                             : 
                             
                             editNote ? 
                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                             <CreateNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[learnIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                             
                             }                                
                             </Col></Row>

                             {quizQueue[learnIndex].type === "Hanzi" ? 
            
                                 <Row>
                                 {quizQueue[learnIndex].prerequisite.length > 0 &&
                                    
                                     <Col  sm={12} lg={6} className="text-left mb-4">
                                         <h6 className="text-muted small">Prerequisite</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[learnIndex].prerequisite.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>

                                     </Col>}
                                     
                                 {quizQueue[learnIndex].usedIn.length > 0 &&   
                                     <Col  sm={12} lg={6} className="text-left mb-4">
                                         <h6 className="text-muted small">Used in</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[learnIndex].usedIn.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>
                                     </Col>}
                                 </Row> 
                                 
                                 :
                                 
                                 <Row>
                                 {quizQueue[learnIndex].prerequisite.length > 0 &&
                                     <Col  className="text-left mb-4">
                                         <h6 className="text-muted small">Prerequisite</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[learnIndex].prerequisite.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>
                                     </Col>}

                                 {quizQueue[learnIndex].usedIn.length > 0 && 
                                     <Col  className="text-left mb-4">
                                         <h6 className="text-muted small">Used in</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[learnIndex].usedIn.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>
                                     </Col>}
                                 </Row> 
                             }
                             
                         </React.Fragment>
                 : <div className="text-center pt-5"><i className="bi-large-1 bi bi-check-circle"></i><p>You have no items in your learning queue</p><Button onClick={goHome}  variant="success">Back to dashboard</Button></div> }       
             </motion.div>
             </Container></React.Fragment> : 

             //--------------------------------START QUIZ--------------------------------

             <div>
             <Navbar expand="sm" className="bg-white py-2" variant="light">
             <Container fluid className="container-custom">      
                 <Navbar.Brand href="/dashboard"><i className="bi bi-house-door-fill"/></Navbar.Brand>
                 
                 {(queueLength > quizIndex) &&  <React.Fragment>  <Navbar.Text className="mr-auto">  </Navbar.Text>  
                 {quizIndex + 1}/<b className="fc-primary pr-3">{quizQueue.length}</b><Navbar.Text className="mr-auto d-none d-sm-block"> <ProgressBar variant="success" now={quizIndex + 1} max={quizQueue.length} /> </Navbar.Text>
                 {mute ? <i onClick={updateMute} className="h2 pt-2 pr-3 icon-grey bi bi-volume-mute"/> :<i onClick={updateMute} className="h2 pt-2 pr-3 icon-grey bi bi-volume-up"/>} <i className="h2 pt-2 pr-3 icon-grey bi bi-question-circle" onClick={handleShow}/><Navbar.Text > <Form inline>  <Form.Check 
                     type="switch"
                     id="custom-switch"
                     label="Fast mode"
                     className="small"
                     onChange={handleFastMode}
                 /></Form></Navbar.Text></React.Fragment> }

             </Container>  
             </Navbar>
                      
             <Container fluid className="container-custom py-lg-5 py-3" >
             
                 {quizQueue.length > quizIndex  ?                  
                   
                     quizQueue[quizIndex].meanCorrect === false ? <React.Fragment>
                     
                     <motion.div initial="visible" variants={jumboVariants} animate={aniState === 0 ? "neutral" : aniState === 1 ? "correct" : "wrong"} className="jumbotron text-center">
                         <div className="fixed-height d-flex flex-column justify-content-center">
                         <Row><Col >
                         
                         <AnimatePresence initial={false}>
                             {showImg &&
                             <motion.div key={0} initial="collapsed" variants={{
                             open: { opacity: 1, height: 300 },
                             collapsed: { opacity: 0, height: 0 }}} 
                             animate="open"
                             exit="collapsed">
                             <img src={`/assets/images/radicals/${quizQueue[quizIndex].string_ID}.svg`} alt={quizQueue[quizIndex].character} className="radical-img"/> </motion.div>}</AnimatePresence>   
                             
                             
                             
                             {!showImg &&<div className={quizQueue[quizIndex].type === "Radical" ? "fc-primary" : quizQueue[quizIndex].type === "Hanzi" ? "fc-blue" : "fc-pink"} style={{fontSize: "3.5rem"}}>               
                             {quizQueue[quizIndex].character}</div>}
                                           

                         <small>{quizQueue[quizIndex].type} <span>{(quizQueue[quizIndex].img && answerState !== 0) && <i onClick={handleImg} className="bi bi-card-image bi-card-quiz-pos"/>}</span></small> 
                         
                         </Col></Row>
                         <br/>
                         {answerState === 2 && <div><p>Answer is wrong</p></div>}
                         {answerState === 1 && <div><p>Answer is correct</p></div>}

                         </div>
                                            
                     </motion.div>
                     <InputGroup className="mb-5">
                             <InputGroup.Prepend>
                             <InputGroup.Text className="color-dark-green">Meaning</InputGroup.Text>
                             </InputGroup.Prepend>
                             <FormControl placeholder="Meaning Answer" className="text-center" onChange={handleChange} onKeyDown={handleMeanDown} name="answer" value={answer} readOnly={answerState===1? true : false} autoComplete="off" />
                             <InputGroup.Append>
                             <Button variant="outline-secondary" onClick={submitMean} >Submit</Button>
                             </InputGroup.Append>
                         </InputGroup>
                         {(answerState === 1 || answerState === 2) &&
                             <>
                             <Row><Col className="text-left mb-4">
                                 <h6 className="text-muted small">Meaning</h6>
                                 <hr/>                       

                                     <Table  borderless size="sm">
                         
                                         <tbody className="text-center">                              
                                             <tr>
                                             <td className="quiz-table"><small>Meaning</small></td>
                                             <td ><p>{quizQueue[quizIndex].name.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })}</p> </td>
                                             </tr>

                                             <tr>
                                             <td className="quiz-table"><small>Custom meaning</small></td>
                                             <td >
                                             {quizQueue[quizIndex].notes.length > 0 ? 
                                     
                                             editMean ? 
                                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                                             <CustomMeanEdit handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID} notes={quizQueue[quizIndex].notes[0].notes}/></motion.div> : (quizQueue[quizIndex].notes[0].notes.custom_mean && quizQueue[quizIndex].notes[0].notes.custom_mean.trim()) ? <div className="text-center"><p>{parse(quizQueue[quizIndex].notes[0].notes.custom_mean)}</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                                             
                                             : 
                                             
                                             editMean ? 
                                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                                             <CustomMean handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                                             
                                             }
                                             </td>
                                             </tr>                                       

                                             <tr>
                                             <td className="quiz-table"><small>Mnemonic</small></td>
                                             <td >{parse(quizQueue[quizIndex].mnemonicName)}</td>
                                             </tr>                                          

                                         </tbody>
                                     </Table>

                             </Col></Row>                            

                             <Row><Col className="mb-5">
                             <h6 className="text-muted small">Notes</h6>
                             <hr/>
                             {quizQueue[quizIndex].notes.length > 0 ? 
                             
                             editNote ? 
                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                             <EditNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID} notes={quizQueue[quizIndex].notes[0].notes}/></motion.div> : (quizQueue[quizIndex].notes[0].notes.content && quizQueue[quizIndex].notes[0].notes.content.trim()) ? <div className="text-center"><p>{parse(quizQueue[quizIndex].notes[0].notes.content)}</p><i onClick={()=> {setEditNote(true); setEditMean(false)}} className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                             
                             : 
                             
                             editNote ? 
                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                             <CreateNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                             
                             }                                
                             </Col></Row>

                             {quizQueue[quizIndex].type === "Radical" && 
                 
                                 <Row>                                    
                                 {quizQueue[quizIndex].usedIn.length > 0 && 
                                     <Col  className="text-left mb-4">
                                         <h6 className="text-muted small">Used in</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[quizIndex].usedIn.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>
                                     </Col>}
                                 </Row> 
                             }
                         </>}

                     </React.Fragment> : (quizQueue[quizIndex].hasOwnProperty("readCorrect") && quizQueue[quizIndex].readCorrect === false) ? <React.Fragment>

                         
                     <motion.div initial="visible" style={ (answerState === 1 || answerState === 2) ? quizQueue[quizIndex].tone === 1 ? {backgroundImage: `url(${spring})`} : quizQueue[quizIndex].tone === 2 ? {backgroundImage: `url(${summer})`} : quizQueue[quizIndex].tone === 3 ? {backgroundImage: `url(${autumn})`} : quizQueue[quizIndex].tone === 4 ? {backgroundImage: `url(${winter})`} : {backgroundColor: "#e9ecef"} : {backgroundColor: "#e9ecef"} } variants={jumboVariants} animate={aniState === 0 ? "neutral" : aniState === 1 ? "correct" : "wrong"} className="jumbotron text-center">

                         <div className="fixed-height d-flex flex-column justify-content-center">
                         <Row><Col >
                         <h1 className={quizQueue[quizIndex].type === "Radical" ? "fc-primary" : quizQueue[quizIndex].type === "Hanzi" ? "fc-blue" : "fc-pink"} style={{fontSize: "3.5rem"}}>{quizQueue[quizIndex].character}</h1>
                         <small>{quizQueue[quizIndex].type}</small>
                         </Col></Row>
                         <br/>
                         {answerState === 2 && <div><p>Answer is wrong</p></div>}
                         {answerState === 1 && <div><p>Answer is correct</p></div>}

                        </div>
                     </motion.div> 
                     <InputGroup className="mb-5">
                             <InputGroup.Prepend>
                             <InputGroup.Text className="color-dark-blue">Reading</InputGroup.Text>
                             </InputGroup.Prepend>
                             <FormControl placeholder="Reading Answer" className="text-center" onChange={handleChange} onKeyDown={handleReadDown} name="answer" value={answer} readOnly={answerState===1? true : false} autoComplete="off" />
                             <InputGroup.Append>
                             <Button variant="outline-secondary" onClick={submitRead} >Submit</Button>
                             </InputGroup.Append>
                         </InputGroup>
                     {(answerState === 1 || answerState === 2) && <React.Fragment>
                                 
                         <Row><Col className="text-left mb-4">
                             <h6 className="text-muted small">Meaning</h6>
                             <hr/>
                             <Table  borderless size="sm">
                         
                         <tbody className="text-center">                              
                                                          
                             <tr>
                             <td className="quiz-table"><small>Meaning</small></td>
                             <td ><p>{quizQueue[quizIndex].name.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })}</p></td>
                             </tr>

                             <tr>
                             <td className="quiz-table"><small>Custom meaning</small></td>
                             <td >
                             {quizQueue[quizIndex].notes.length > 0 ? 
                     
                             editMean ? 
                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                             <CustomMeanEdit handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID} notes={quizQueue[quizIndex].notes[0].notes}/></motion.div> : (quizQueue[quizIndex].notes[0].notes.custom_mean && quizQueue[quizIndex].notes[0].notes.custom_mean.trim()) ? <div className="text-center"><p>{parse(quizQueue[quizIndex].notes[0].notes.custom_mean)}</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                             
                             : 
                             
                             editMean ? 
                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                             <CustomMean handleMean={handleMean} meanLoad={meanLoad} handleMeanLoad={handleMeanLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add custom meanings...</p><i onClick={()=> {setEditMean(true); setEditNote(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                             
                             }
                             </td>
                             </tr>                                       

                             <tr>
                             <td className="quiz-table"><small>Mnemonic</small></td>
                             <td >{parse(quizQueue[quizIndex].mnemonicName)}</td>
                             </tr>                                          

                         </tbody>
                     </Table>                           

             </Col>
             
             {quizQueue[quizIndex].mnemonicPronun &&
             <Col sm={12} lg={6} className="text-left mb-4">
                 <h6 className="text-muted small">Reading {quizQueue[quizIndex].audio && <AudioPlayer ref={musicFocus} place={true} source={quizQueue[quizIndex].string_ID} updateAudio={updateAudio} />}</h6>
                 <hr/>
                 <Table  borderless size="sm">
                 
                 <tbody className="text-center">                              
                     <tr>
                     <td className="quiz-table"><small>Reading</small></td>
                     <td ><p>{quizQueue[quizIndex].pronun.map((item, index) =>{ return (<span key={index}>{(index ? ', ' : '') + item }</span>) })}</p> </td>
                     </tr>                                       

                     <tr>
                     <td className="quiz-table"><small>Mnemonic</small></td>
                     <td >{parse(quizQueue[quizIndex].mnemonicPronun)}</td>
                     </tr>                                          

                 </tbody>
             </Table>

             </Col>}
             
             </Row>

             {quizQueue[quizIndex].exampleSentence &&
             <Row>
                 <Col className="text-left mb-4">
                     <h6 className="text-muted small">Example Sentences</h6>
                     <hr/>
                     {quizQueue[quizIndex].exampleSentence.map((example, index) => {
                         return (
                             <div key={index} className="text-center">{parse(example.CN)}<br/><small>{parse(example.PY)}</small><p>{parse(example.EN)}</p></div>
                         )})}
                 </Col>
             </Row>}

                         <Row><Col className="mb-5">
                             <h6 className="text-muted small">Notes</h6>
                             <hr/>
                             {quizQueue[quizIndex].notes.length > 0 ? 
                             
                             editNote ? 
                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                             <EditNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID} notes={quizQueue[quizIndex].notes[0].notes}/></motion.div> : (quizQueue[quizIndex].notes[0].notes.content && quizQueue[quizIndex].notes[0].notes.content.trim()) ? <div className="text-center"><p>{parse(quizQueue[quizIndex].notes[0].notes.content)}</p><i onClick={()=> {setEditNote(true); setEditMean(false)}}className="pencil-button bi bi-pencil-fill"></i></div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}}className="pencil-button bi bi-pencil-fill"></i></div>
                             
                             : 
                             
                             editNote ? 
                             <motion.div initial={{height: 0}} animate={{height: "auto"}}>
                             <CreateNotes handleNote={handleNote} noteLoad={noteLoad} handleNoteLoad={handleNoteLoad} updateState={updateState} item={quizQueue[quizIndex].string_ID}/></motion.div> : <div className="text-center"><p className="text-muted">Add notes...</p><i onClick={()=> {setEditNote(true); setEditMean(false)}} className="pencil-button bi bi-pencil-fill"></i></div>
                             
                             }                                
                             </Col></Row>                              
                         
                         {quizQueue[quizIndex].type === "Hanzi" ? 
            
                                 <Row>
                                 {quizQueue[quizIndex].prerequisite.length > 0 &&
                                    
                                     <Col  sm={12} lg={6} className="text-left mb-4">
                                         <h6 className="text-muted small">Prerequisite</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[quizIndex].prerequisite.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>

                                     </Col>}
                                     
                                 {quizQueue[quizIndex].usedIn.length > 0 &&   
                                     <Col  sm={12} lg={6} className="text-left mb-4">
                                         <h6 className="text-muted small">Used in</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[quizIndex].usedIn.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>
                                     </Col>}
                                 </Row> 
                                 
                                 :
                                 
                                 <Row>
                                 {quizQueue[quizIndex].prerequisite.length > 0 &&
                                     <Col  className="text-left mb-4">
                                         <h6 className="text-muted small">Prerequisite</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[quizIndex].prerequisite.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>
                                     </Col>}

                                 {quizQueue[quizIndex].usedIn.length > 0 && 
                                     <Col  className="text-left mb-4">
                                         <h6 className="text-muted small">Used in</h6>
                                         <hr/>
                                         <div className="progress-items-col">
                                         {quizQueue[quizIndex].usedIn.map((item, index) => (                
                                         <Block itemInfo={item} key={index} />
                                             ))} 
                                         </div>
                                     </Col>}
                                 </Row> 
                             }
                             
                     </React.Fragment>}   
                             
                 </React.Fragment> : setQuizIndex(quizIndex + 1)
                         

                     : <div className="text-center pt-5"><i className="bi-large-1 bi bi-check-circle"></i><p>You have finished the quiz</p><Button className="mr-sm-3 m-3" onClick={goHome} variant="success">Back to dashboard</Button><Button onClick={refresh} variant="secondary">Continue learning</Button></div>
                 
                 }                    
                         
             </Container>
         </div>}

        
         
     </div>}</div>
         
         : <LoadingScreen/>
        
       

     
        
       
    );
}

export default LearningQueue;