import React, {useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import {Nav, NavDropdown, Form,FormControl, Container } from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import { useHistory } from 'react-router-dom';
import AlertVerifyEmail from "./AlertVerifyEmail";
import AlertPause from "./AlertPause";

function Navibar(props) {    
    const history = useHistory();
    const [search,setSearch] = useState("");
    const [show, setShow] = useState(true);
    const [showPause, setShowPause] = useState(true);

    function linkOut(event){
        setSearch("");
        event.preventDefault();
        history.push('/search/'+ search);
    }

    function handleSearch(event){
        setSearch(event.target.value)
    }

    function handleKeyDown (event) {
        const enter = 13;
        if(event.keyCode === enter) {
          linkOut(event);
        }
    }

    function handleShow (value) {
        setShow(value);
    }

    function handleShowPause (value) {
        setShowPause(value);
    }

    function handleSideBar (){
        props.handleToggleSidebar(true);
        props.handleCollapsedChange(false);
    }

    return(       
        
        <header>
        {(props.isLoggedIn && props.userInfo) && <React.Fragment>
            {(show && !props.userInfo.data.verified) && <AlertVerifyEmail overlay={props.overlay} handleShow={handleShow} user={props.userInfo.data}/>}
        {(showPause && props.userInfo.data.pause) && <AlertPause overlay={props.overlay} updateUserInfo={props.updateUserInfo} handleShowPause={handleShowPause} user={props.userInfo.data}/>}

        <Navbar style={{paddingTop: "0.75em"}} expand="sm" variant="light">
        <Container fluid className="container-custom">
        <i  onClick={handleSideBar} className="d-block d-lg-none bi bi-list search-icon hamburger-icon"/>
        <Nav className="mr-auto">
            {/* <LinkContainer to="/learn"><Nav.Link>Learn</Nav.Link></LinkContainer> */}

            <NavDropdown title={<div className="d-none d-sm-block"><div className="d-flex justify-content-center align-items-center"><div className="navbar-profile-text"> Level </div> <div className="navbar-profile-icon">{props.userInfo.data.level}</div> </div></div>} id="collasible-nav-dropdown">
                <LinkContainer to="/my-account"><NavDropdown.Item>Account</NavDropdown.Item></LinkContainer>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => props.handleLogout()}>Logout</NavDropdown.Item>
            </NavDropdown>
        </Nav>
        <Nav> 
            <Form inline className="pt-2">
                <FormControl id="navbar-form" type="text" placeholder="Search" value={search} onKeyDown={handleKeyDown} onChange={handleSearch}  autoComplete="off" />
                <i onClick={linkOut} className="search-icon bi bi-search"/> 
            </Form>                     
        </Nav>
        </Container>
        </Navbar>
        </React.Fragment> }
     
        
        </header>
    );
}

export default Navibar;