import React, {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import instance from "../axiosConfig";
import LoadingScreen from "./LoadingScreen";
import {Container, Row, Col, Alert, Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap"
import {motion, AnimatePresence} from "framer-motion"

const { DateTime } = require("luxon");


function MyAccount(props){
    const history = useHistory();
    const [emailResponse, setEmailResponse] = useState(null);
    const [passwordResponse, setPasswordResponse] = useState(null);
    const [learnQResponse, setLearnQResponse] = useState(null);
    const [levelResponse, setLevelResponse] = useState(null);
    const [deleteResponse, setDeleteResponse] = useState(null);
    const [srsResponse, setSrsResponse] = useState(null);
    const [pauseResponse, setPauseResponse] = useState(null);
    const [vocabResponse, setVocabResponse] = useState(null);
    const [threshResponse, setThreshResponse] = useState(null);
    
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [isLoading, setIsLoading] =  useState(true);


    // useEffect(() => {

    //     axios.get("/api/auth/check")
    //     .then(async res => {if (!res.data){
    //         history.push('/login')
    //       } else{
    //         setIsLoading(false); 
    //         props.handleNavBar(false);
    //         props.updateUserInfo();
    //         }})
    //     .catch((err) => {console.log(err);});

    // },[]);
    
  useEffect(() => {
    if(props.userInfo && props.isLoggedIn){
        props.handleNavBar(false);
        setIsLoading(false);
    } else if (props.isLoggedIn === false){
        history.push('/login');
    }
  }, [props.userInfo, props.isLoggedIn]);
    
    
    const [learnQInput, setLearnQInput] = useState({
        value: 5
    });

    const [emailInput, setEmailInput] = useState({
        email: "",
        password: ""
    }); 
    const [passwordInput, setPasswordInput] = useState({
        password: "",
        newPassword: "",
        repeatPassword: ""
    });
    const [levelInput, setLevelInput] = useState({
        level: "",
        password: ""
    });
    const [deleteInput, setDeleteInput] = useState({
        confirm: "",
        password: ""
    });

    const [srsInput, setSrsInput] = useState({
        interval: 0.5
    });

    const [vocabInput, setVocabInput] = useState({
        value: false
    });

    const [threshInput, setThreshInput] = useState({
        value: 5
    });

    function handleSuccessAlert(){
        props.overlay(false);
        setUpdateSuccess(true);
        setTimeout(() => {
            setUpdateSuccess(false);
          }, 2500);
    }

    function handleLearnQChange(event){
        const {name, value} = event.target;
        setLearnQInput((prevValue =>{
            return {
                ...prevValue,
                [name]: value
            };
        }));
    }

    function handleEmailChange(event){
        const {name, value} = event.target;
        setEmailInput((prevValue =>{
            return {
                ...prevValue,
                [name]: value
            };
        }));
    }

    function handlePasswordChange(event){
        const {name, value} = event.target;
        setPasswordInput((prevValue =>{
            return {
                ...prevValue,
                [name]: value
            };
        }));
    }

    function handleLevelChange(event){
        const {name, value} = event.target;
        setLevelInput((prevValue =>{
            return {
                ...prevValue,
                [name]: value
            };
        }));
    }

    function handleDeleteChange(event){
        const {name, value} = event.target;
        setDeleteInput((prevValue =>{
            return {
                ...prevValue,
                [name]: value
            };
        }));
    }

    function handleSrsChange(event){
        const {name, value} = event.target;
        setSrsInput((prevValue =>{
            return {
                ...prevValue,
                [name]: value
            }
        }));
    }

    function handleVocabChange(event){
        const {name, value} = event.target;
        setVocabInput((prevValue =>{
            return {
                ...prevValue,
                [name]: value
            }
        }));
    }

    
    function handleThreshChange(event){
        const {name, value} = event.target;
        setThreshInput((prevValue =>{
            return {
                ...prevValue,
                [name]: value
            }
        }));
    }

    async function changeLearnQ(event){
        event.preventDefault();
        props.overlay(true);
        const {value} = learnQInput;

        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updateLearnQ: "true",
               value: value
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
                setTimeout(() => {
                    handleSuccessAlert();
                    props.updateUserInfo();

                  }, 1000);         
            })
            } catch(err) {
                setTimeout(() => {
                    if(err.response.data === "Unauthorized"){
                        window.location.reload();
                    } else {
                    setLearnQResponse(err.response.data);
                    props.overlay(false);}
                }, 1000); 
                
            }
    }

    async function changeSrs(event){
        event.preventDefault();
        props.overlay(true);
        const {value} = srsInput;

        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updateSrs: "true",
               value: value
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
                setTimeout(() => {
                    handleSuccessAlert();
                    props.updateUserInfo();
                  }, 1000); 
            })
            } catch(err) {
                setTimeout(() => {
                    if(err.response.data === "Unauthorized"){
                        window.location.reload();
                    } else {
                        setSrsResponse(err.response.data);
                        props.overlay(false);}
                }, 1000);              
                
            }
    }

    async function changeVocab(event){
        event.preventDefault();
        props.overlay(true);
        const {value} = vocabInput;

        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updateVocab: "true",
               value: value
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
                setTimeout(() => {
                    handleSuccessAlert();
                    props.updateUserInfo();
                  }, 1000); 
            })
        } catch(err) {
            setTimeout(() => {
                if(err.response.data === "Unauthorized"){
                    window.location.reload();
                } else {
                setVocabResponse(err.response.data);
                props.overlay(false);}
            }, 1000);   
        }
    }

    async function changeThresh(event){
        event.preventDefault();
        props.overlay(true);
        const {value} = threshInput;

        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updateThresh: "true",
               value: value
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
                setTimeout(() => {
                    handleSuccessAlert();
                    props.updateUserInfo();
                  }, 1000); 
            })
        } catch(err) {
            setTimeout(() => {
                if(err.response.data === "Unauthorized"){
                    window.location.reload();
                } else {
                setThreshResponse(err.response.data);
                props.overlay(false);}
            }, 1000);   
        }
    }



    async function changeEmail(event){
        event.preventDefault();
        props.overlay(true);
        const {email, password} = emailInput;

        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updateEmail: "true",
               email: email,
               password: password
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {

                setTimeout(() => {
                    handleSuccessAlert();
                    props.updateUserInfo();
                    setEmailInput({        
                        email: "",
                        password: ""});
                        setEmailResponse(null);
                  }, 1000);         
            })
            } catch(err) {
                setTimeout(() => {
                    if(err.response.data === "Unauthorized"){
                        window.location.reload();
                    } else {
                    setEmailResponse(err.response.data);
                    props.overlay(false);}
                }, 1000);   
               
            }
    }

    async function changePassword(event){
        event.preventDefault();
        props.overlay(true);
        const {password, newPassword, repeatPassword} = passwordInput;

        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updatePassword: "true",
               password: password,
               newPassword: newPassword,
               repeatPassword: repeatPassword
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
                setTimeout(() => {
                    handleSuccessAlert();
                    setPasswordInput({        
                        password: "",
                        newPassword: "",
                        repeatPassword: ""});
                    setPasswordResponse(null);
                    props.updateUserInfo();
                  }, 1000); 
            })
            } catch(err) {
                setTimeout(() => {
                    if(err.response.data === "Unauthorized"){
                        window.location.reload();
                    } else {
                    setPasswordResponse(err.response.data);
                    props.overlay(false);}
                }, 1000);  
            }
    }

    async function changeLevel(event){
        event.preventDefault();
        props.overlay(true);
        const {level, password} = levelInput;

        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updateLevel: "true",
               level: level,
               password: password
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
                setTimeout(() => {
                    handleSuccessAlert();
                    setLevelInput({        
                        level: "",
                        password: ""});
                    setLevelResponse(null);
                    props.updateUserInfo();
                  }, 1000); 
            })
            } catch(err) {
                setTimeout(() => {
                    if(err.response.data === "Unauthorized"){
                        window.location.reload();
                    } else {
                    setLevelResponse(err.response.data);
                    props.overlay(false);}
                  }, 1000); 
                 
            }
    }

    async function deleteAccount(event){
        event.preventDefault();
        props.overlay(true);
        const {password, confirm} = deleteInput;

        try {
            await instance ({
            method: "delete",
            url: "/api/users/user",
            data: {               
               password: password,
               confirm: confirm             
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {
                window.location.reload();
            })
            } catch(err) {
                setTimeout(() => {
                    if(err.response.data === "Unauthorized"){
                        window.location.reload();
                    } else {
                    setDeleteResponse(err.response.data);
                    props.overlay(false);}
                }, 1000);  
            }
    }

    async function changePause(event){
        event.preventDefault();
        props.overlay(true);
       
        try {
            await instance ({
            method: "PUT",
            url: "/api/users/user",
            data: {
               updatePause: "true",
               value: !props.userInfo.data.pause
            },
            headers: { 'content-type': 'application/json' }
            }).then(res => {

                setTimeout(() => {
                    handleSuccessAlert();
                    props.updateUserInfo();
                  }, 1000); 
            })
            } catch(err) {
                setTimeout(() => {
                    if(err.response.data === "Unauthorized"){
                        window.location.reload();
                    } else {
                    setPauseResponse(err.response.data);
                    props.overlay(false);}
                }, 1000);  
                setSrsResponse(err.response.data);
            }
    }

    return(
        !isLoading && props.userInfo ?
        <section id="my-account">
        <Container fluid className="py-lg-5 py-3 container-custom fadeIn"> 
        <motion.div animate={{ x: [-100, 0  ] }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
        
            <Row className="bg-white rounded shadow-sm p-md-5 pt-4 py-1">
   <AnimatePresence>       
{     updateSuccess && <motion.div key="update" initial={{y:-40}} animate={{y:0}} exit={{y:-40}} className="fixed-top success-alert"><i className="bi bi-check"/>Update successful </motion.div>}     </AnimatePresence> 
               <Col>
                <Row>
                <Col>
                    <h3 className="fc-primary">My Account</h3>
                    <div className="pb-3 pt-4">
                    <p>{props.userInfo.data.username}</p>
                    <p>Level {props.userInfo.data.level}</p>
                    <p>Email: {props.userInfo.data.email}</p>
                    <p>Date joined: {DateTime.fromISO(props.userInfo.data.dateJoined).toLocaleString(DateTime.DATETIME_SHORT)}</p>
                    <p className="d-flex align-items-center">
                    {props.userInfo.data.pause ? 
                    <button onClick={changePause} className="btn btn-success d-flex align-items-center" type="button"><i className="bi bi-play-circle-fill"/> <span className="pl-2"> Resume Queue</span></button> :                     
                    <button onClick={changePause} className="btn btn-secondary d-flex align-items-center" type="button"><i className="bi bi-pause-circle-fill"/> <span className="pl-2"> Pause Queue</span></button>
                    
                    }<OverlayTrigger overlay={<Tooltip>Going away for a while? You can pause and resume your review queue using the button below</Tooltip>}>
                    <i className="pl-1 bi bi-question-circle-fill"/>
                    </OverlayTrigger> </p>
                    {pauseResponse && pauseResponse.errors.message} 
                    </div>         
                    <hr/>
                </Col>
            </Row>
            <div className="pb-3 pt-4">
            <h4>Preferences</h4>
            <br/>
            <Row>
                <Col sm={12} lg={6}>                
                    <p>Learn queue items per session: <span className="font-weight-bold fc-primary">{props.userInfo.data.learnQueue}</span></p>
                    {learnQResponse && learnQResponse.errors.message}
                    <div className="input-group">
                        <select className="custom-select" name="value" onChange={handleLearnQChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                        </select>
                        <div className="input-group-append">
                            <button onClick={changeLearnQ} className="btn btn-success" type="button">Confirm</button>
                        </div>      
                    </div>  
                    <br/>
                </Col>
                <Col sm={12} lg={6}>                
                    <p>SRS Interval (multiplier): <span className="font-weight-bold fc-primary">{props.userInfo.data.srsInterval === 1 ? "Normal (1x)" : props.userInfo.data.srsInterval === 0.5 ? "Fast (0.5x)" : "Slow (1.5x)"}</span> 
                    <OverlayTrigger overlay={<Tooltip>This adds the selected multiplier to the standard interval. Changes does not apply to current items in the review queue</Tooltip>}>
                    <i className="pl-1 bi bi-question-circle-fill"/>
                    </OverlayTrigger></p>
                    
                    {srsResponse && srsResponse.errors.message}
                    <div className="input-group">
                        <select className="custom-select" name="value" onChange={handleSrsChange}>
                            <option value="0.5">Fast (0.5x)</option>
                            <option value="1">Normal (1.0x)</option>
                            <option value="1.5">Slow (1.5x)</option>
                        </select>
                        <div className="input-group-append">
                            <button onClick={changeSrs} className="btn btn-success" type="button">Confirm</button>
                        </div>      
                    </div>  
                    <br/>
                </Col>
                </Row>
                <Row>
                <Col sm={12} lg={6}>                
                    <p>Ignore Vocab: <span className="font-weight-bold fc-primary">{props.userInfo.data.ignoreVocab ? "Yes" : "No"}</span>  
                    <OverlayTrigger overlay={<Tooltip>This removes vocabulary items from the learn queue. Changes does not apply to current items in the review queue</Tooltip>}>
                    <i className="pl-1 bi bi-question-circle-fill"/>
                    </OverlayTrigger></p>
                    
                    {vocabResponse && vocabResponse.errors.message}
                    <div className="input-group">
                        <select className="custom-select" name="value" onChange={handleVocabChange}>
                            <option value={false}>No (Recommended)</option>
                            <option value={true}>Yes</option>
                        </select>
                        <div className="input-group-append">
                            <button onClick={changeVocab} className="btn btn-success" type="button">Confirm</button>
                        </div>      
                    </div>  
                    <br/>
                </Col>
                <Col sm={12} lg={6}>                
                    <p>Level Up threshold: <span className="font-weight-bold fc-primary">{props.userInfo.data.levelThresh}0%</span>  
                    <OverlayTrigger overlay={<Tooltip>This determines (in percentage) how many Radicals and Hanzi you need to review at least once in order to level up. Changes will apply during your next review</Tooltip>}>
                    <i className="pl-1 bi bi-question-circle-fill"/>
                    </OverlayTrigger></p>
                    
                    {threshResponse && threshResponse.errors.message}
                    <div className="input-group">
                        <select className="custom-select" name="value" onChange={handleThreshChange}>
                            <option value={5}>50%</option>
                            <option value={6}>60%</option>
                            <option value={7}>70%</option>
                            <option value={8}>80% (Recommended)</option>
                            <option value={9}>90%</option>
                            <option value={10}>100%</option>
                        </select>
                        <div className="input-group-append">
                            <button onClick={changeThresh} className="btn btn-success" type="button">Confirm</button>
                        </div>      
                    </div>  
                    <br/>
                </Col>

                </Row>
                
                </div>   
                <Row>
                    <Col sm={12} lg={6} >
                    <hr/>
                        <div className="pb-3 pt-4">
                            <h4>Change email</h4>
                            <br/>
                            <Form.Group >
                                <Form.Label>New email</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" onChange={handleEmailChange} name="email" value={emailInput.email} />
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter password" onChange={handleEmailChange} name="password" value={emailInput.password}/>
                            </Form.Group>
                            <br/>
                            
                            <div style={{paddingBottom: "14px"}} className="d-none d-lg-block">  
                            <br/><br/><br/>
                            </div>  
                            <Button block variant="success" type="submit" onClick={changeEmail}>
                                Change email
                            </Button>
                            <br/>
                            {emailResponse && <Alert variant="danger">{emailResponse.errors.message}</Alert>}
                            
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                    <hr/>
                    <div className="pb-3 pt-4">
                        <h4>Change password</h4>
                        <br/>
                        <Form.Group >
                            <Form.Label>New password</Form.Label>
                            <Form.Control type="password" placeholder="Enter new password" onChange={handlePasswordChange} name="newPassword" value={passwordInput.newPassword} />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Retype new password</Form.Label>
                            <Form.Control type="password" placeholder="Retype new password" onChange={handlePasswordChange} name="repeatPassword" value={passwordInput.repeatPassword} />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Current password</Form.Label>
                            <Form.Control type="password" placeholder="Enter current password" onChange={handlePasswordChange} name="password" value={passwordInput.password}  />
                        </Form.Group>
                        <br/>
                        <Button block variant="success" type="submit" onClick={changePassword}>
                            Change password
                        </Button>
                        <br/>
                        {passwordResponse && <Alert variant="danger">{passwordResponse.errors.message}</Alert>}

                    </div>  
                    </Col> 
                </Row>
                <Row>
                    <Col sm={12} lg={6}>                 
                    <hr/>
                    <div className="pb-3 pt-4">
                        <h4>Reset level</h4>
                        <br/>
                        <p>Resets your level and progress to a lower level.<br/>Warning: Reset is permanent, it is impossible to retrieve previous data once changed.</p>
                        <br/>
                        <Form.Group >
                            <Form.Label>Level</Form.Label>
                            <Form.Control type="text" placeholder="Enter level" onChange={handleLevelChange} name="level" value={levelInput.level} />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter password" onChange={handleLevelChange} name="password" value={levelInput.password} />
                        </Form.Group>
                        <br/>
                        <Button block variant="success" type="submit" onClick={changeLevel}>
                            Change level
                        </Button>
                        <br/>
                        {levelResponse && <Alert variant="danger">{levelResponse.errors.message}</Alert>}

                    </div>
                    </Col>
                    <Col sm={12} lg={6}>
                    <hr/>
                    <div className="pb-3 pt-4">
                        <h4>Delete account</h4>
                        <br/>
                        <p>Delete your account and all your data.<br/>Warning: Deletion is permanent, it is impossible to retrieve previous data once deleted.</p>
                        <br/>
                        <Form.Group >
                            <Form.Label>Confirm deletion by typing <strong>DELETE</strong> below (case sensitive) </Form.Label>
                            <Form.Control type="text" placeholder="Type DELETE" onChange={handleDeleteChange} name="confirm" value={deleteInput.confirm} />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter password" onChange={handleDeleteChange} name="password" value={deleteInput.password} />
                        </Form.Group>
                        <br/>
                        <Button block variant="danger" type="submit" onClick={deleteAccount}>
                            Delete account
                        </Button>
                        <br/>
                       
                        {deleteResponse && <Alert variant="danger">{deleteResponse.errors.message}</Alert>}

                    </div>


                </Col>
            </Row>

                </Col>
            </Row>      
            

            </motion.div>
            </Container>
  </section> : <LoadingScreen/>)
}

export default MyAccount;