import React from "react";
import BlockLoading from "./BlockLoading";
import { motion } from "framer-motion";
import {LinkContainer} from "react-router-bootstrap";
import {Nav} from "react-bootstrap"

function BlockMinus(props){
    const itemInfo = props.itemInfo;

    return (
        props.itemInfo?
        <LinkContainer to={`/item/${itemInfo._id}`}>
                <Nav.Link> 
        <motion.div whileHover={{ scale: 1.05 }}>
            
                    <div style={itemInfo.type === "Radical" ? {borderColor: "#46C793"} : itemInfo.type === "Vocab" ? {borderColor: "#BA2969"} : {borderColor: "#20A2BA"}} className="block-dashboard">                    
                    <span className="block-dashboard-text">{itemInfo.character} <span className="small pr-1">{itemInfo.type !== "Radical" && `[${itemInfo.pronun[0]}]`  }</span></span> 
                    <span className="h6">{itemInfo.name[0]}</span>
                    
                    </div>
                
        </motion.div>
        </Nav.Link>
            </LinkContainer>
         : <BlockLoading/>

    )
}

export default BlockMinus;