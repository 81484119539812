import React from "react";
import {Accordion, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import Block from "./Block"

function ItemAccordion(props){
    
    const level = props.level;
    const userInfo = props.userInfo.data;
    const items = props.items;
    const type = props.type;
    let learntCount = 0;
    let memorizedCount = 0;
    let totalCount = items.length;

    for (var i = 0; i < items.length; i++){        
        if (items[i].userInfo.length > 0){
            learntCount++ ;
            if (items[i].userInfo[0].memorized === true){
                memorizedCount++
            }
        }
    }
    
    
    return (
        <Accordion className="pb-3" defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle  style={{backgroundColor: userInfo.level >= level ?  type === "Radical" ? "#46C793" : type === "Hanzi" ? "#20A2BA" : "#BA2969" :"#c8c8c8" }} className="accordion-header d-flex justify-content-between" as={Card.Header} eventKey="0">
                        <span>Level {level}</span>
                        {userInfo.level >= level && <div>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                    Memorized
                                    </Tooltip>
                                }
                                >
                                <span className="pr-3"><i className="bi bi-bookmark-check-fill"/> {Math.round((memorizedCount/totalCount)* 100)} %</span>    
                                </OverlayTrigger>

                                <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                    Learnt
                                    </Tooltip>
                                }
                                >
                                 <span> <i className="bi bi-bookmark-fill"/> {Math.round((learntCount/totalCount)* 100)} %</span>     
                                </OverlayTrigger>
                           
                        </div>}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body className="item-accordion">                       
                        
                        {items.map((item, index) => (                
                            <Block itemInfo={item} key={index} />
                        ))}                            
                        
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>                    
                </Accordion>
    )
}


export default ItemAccordion;

