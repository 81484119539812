import React from "react";
import LoadingGif from "../images/Loading.svg"

function LoadingScreen(){

    return( 
    
        
        <img src={LoadingGif} className="loading-img" alt="Loading"/>
        
    )

}

export default LoadingScreen;