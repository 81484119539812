import React, { useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import {Container, Col, Row, Badge, Breadcrumb, Table, ProgressBar, OverlayTrigger, Tooltip, Button} from "react-bootstrap";
import { motion} from "framer-motion"
import LoadingScreen from "./LoadingScreen";

function FAQ (props) {
    
    const [isLoading, setIsLoading] =  useState(true);
    const history = useHistory();

    useEffect(() => {
        if (props.isLoggedIn){
            setIsLoading(false);
        } else if (props.isLoggedIn === false) {
            history.push('/login')
        }
    },[props.isLoggedIn])


    return (
        
        !isLoading ? 
        <div id="getting-started">
            <Container fluid className="py-lg-5 py-3 container-custom ">
                <motion.div animate={{ x: [-100, 0  ] }} transition={{ ease: "easeOut", duration: 0.3 }}>
                    <Row className="bg-white rounded shadow-sm p-md-5 pt-4 py-1">
                        <Col>
                            <h3 className="fc-primary">Frequently Asked Questions</h3>
                            <div  className="pt-4">
                               Accordion list probably
                               - Are the radicals official?
                               - Can I reset my progress?
                               - Can I change the SRS intervals
                               - Why aren't my reviews showing up?
                               - Can I add custom meanings
                               - Can I ignore vocabulary?
                               - How do I type pin yin?
                               - Can I pause my Learning
                               - What is level up threshold
                               - How many levels are there
                               - Can I add my own words?                               
                            
                            </div>

                            <div className="pt-4">
                                Can't find an answer? Contact us!
                            </div>
                        </Col>
                    </Row>
                </motion.div>
            </Container>
        </div> :
        <LoadingScreen/>

    )
}

export default FAQ;