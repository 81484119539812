import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import LoadingScreen from "./LoadingScreen";
import {Container, Row, Col, Button, Form, Alert, Nav} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap";
import { motion } from "framer-motion";
import instance from "../axiosConfig";


function Register(props){
    let history = useHistory();
    const [isLoading, setIsLoading] =  useState(true);

    // useEffect(() => {
    //     axios.get("/api/auth/check")
    //   .then(async res => {if (res.data){
    //       history.push('/dashboard')
    //     } else{props.handleNavBar(true);
    //       setIsLoading(false);}})
    //   .catch((err) => {console.log(err);});
    // },[history, props])

    useEffect(() => {
      if(props.isLoggedIn){
        history.push('/dashboard')
      } else {
        setIsLoading(false); 
        props.handleNavBar(true);
      }       
    }, [props.isLoggedIn]);

    const [values, setValues] = useState({
        email: '',
        username: '',
        password: '',
        passwordConfirm: ''
     } || {});
    const [error, setError] = useState(null);

        
    function handleKeyDown (event) {
        const enter = 13;
        if(event.keyCode === enter) {
          handleSubmit(event);
        }
    }
    
    function handleSubmit(event){
        event.preventDefault();
        submitData({ values });        
    }

    function handleChange(event){
        const value = event.target.value;
        const name = event.target.name;
        setValues({
          ...values,
          [name]: value
        });
    };    

    async function submitData (formValues) {
        const dataObject = formValues.values;
        const { username, email, password, passwordConfirm, accountKey } = dataObject;
   try {
   await axios ({
   method: "POST",
   url: "/api/auth/add",
   data: {
      username: username,
      email: email,
      password: password,
      passwordConfirm: passwordConfirm,
      accountKey
   },
   headers: { 'content-type': 'application/json' }
   }).then(res => {
     localStorage.setItem("jwt",JSON.stringify(res.data));
        props.handleLogin();
        history.push('/dashboard');
   })
   } catch(err) {
        setError(err.response.data);
      }
   };

    return(isLoading? <LoadingScreen/> :
        <section id="register">
        <Container  fluid className="py-lg-5 py-3 container-custom">
        <motion.div animate={{ x: [-100, 0  ] }}
        transition={{ ease: "easeOut", duration: 0.3 }}>
         <Row className="d-flex justify-content-center"><span className="text-brand font-weight-bold py-3" onClick={()=> history.push("/")}>Hanter</span></Row>
          <Row className="justify-content-md-center">
            <Col className="bg-white rounded shadow-sm  m-md-5 p-md-5 m-2 p-3">
              <h2 className="fc-primary">Sign up</h2>
              <br/>
              <Form>
                
                <Form.Group >
                  <Form.Label>Username</Form.Label>
                  <Form.Control type="text" placeholder="Enter username" name="username" value={values.username} onChange={handleChange} onKeyDown={handleKeyDown} />
                  <Form.Text className="text-muted">
                    Username must be 8 - 16 characters long 
                  </Form.Text>
                </Form.Group>
                
                <Form.Group >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" name="email" value={values.email} onChange={handleChange} onKeyDown={handleKeyDown} />
                </Form.Group>
               
                <Form.Group >
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange} onKeyDown={handleKeyDown} />
                  <Form.Text className="text-muted">
                  Use 8 or more characters with a mix of letters, numbers & symbols
                  </Form.Text>
                </Form.Group>

                <Form.Group >
                  <Form.Label>Repeat password</Form.Label>
                  <Form.Control type="password" placeholder="Retype password" name="passwordConfirm" value={values.passwordConfirm} onChange={handleChange} onKeyDown={handleKeyDown} />
                </Form.Group>

                <Form.Group >
                  <Form.Label>Account Key</Form.Label>
                  <Form.Control type="password" placeholder="Account Key" name="accountKey" value={values.accountKey} onChange={handleChange} onKeyDown={handleKeyDown} />
                </Form.Group>
                

                <br/>
                <Form.Text className="text-muted text-center">
                    By clicking Sign Up, you agree to our <LinkContainer to="/terms-of-use"><Nav.Link className="d-inline"><u>Terms of Use</u></Nav.Link></LinkContainer> and <LinkContainer to="/privacy-policy"><Nav.Link className="d-inline"><u>Privacy Policy</u></Nav.Link></LinkContainer>. 
                </Form.Text>
                <br/>
                <Button block variant="success" type="submit" onClick={handleSubmit}>
                  Sign up
                </Button>
                <br/>
                             
                {error && <Alert variant="danger">{error.messages}</Alert>}
                <div className="d-flex flex-row justify-content-center">  
                  <span className="mr-2">Have an account?</span>
              
                  <LinkContainer to="/login"><Nav.Link>Log in</Nav.Link></LinkContainer>
                </div>
              </Form>      
            </Col>
          </Row>
          </motion.div>
        </Container>   
        </section>
    )        
}

export default Register;